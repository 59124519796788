import React, { Component, createRef, useState, forwardRef, useRef, useImperativeHandle } from 'react';
import { Form, Col, Row, Button, FormGroup, Label, Input, InputGroupText, InputGroup, CardGroup } from 'reactstrap';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import { Card, CardHeader, CardBody, CardTitle, CardFooter } from 'reactstrap'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'; 
import { TabContent, TabPane, Nav, NavItem, NavLink, FormFeedback, Alert } from 'reactstrap'; 
import '../../../src/custom.css' 
import { apiURL,apiHeader, apiPOSTHeader, apiPUTHeader, apiDELETEHeader } from '../../resources/apiURL'; 
import ConfirmModal from '../ConfirmModal';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Plus from '../../../src/resources/plus.svg'
import Refresh from '../../../src/resources/refresh.svg'
import TrashBin from '../../../src/resources/trash.svg'
import Excel from '../../../src/resources/excel.svg'
import RevisionPng from '../../../src/resources/revise.png'
import Select from 'react-select';
import { getUserADAccount, getRandomInt, NumFormat } from '../../resources/utility'
import classnames from 'classnames'; 
import NumberFormat from 'react-number-format'; 
import { ColoredLine, FormatDate, getFirstDayofMonth, USDCurrencyFormat, IsDate } from '../../resources/utility';
import { ThisMonthExpression, ThisYearExpressionDescription } from 'igniteui-react-core'; 
//import { IgrGrid, IgrColumn } from 'igniteui-react-grids';
import { IgrDataGrid,IgrTemplateColumn , IgrTextColumn,IgrNumericColumn } from 'igniteui-react-grids';
//import 'igniteui-webcomponents-grids/grids/themes/light/bootstrap.css';
//import 'igniteui-react-grids/grids/themes/light/bootstrap.css';
//import 'igniteui-react-grids/grids/themes/light/bootstrap.css';
import { ArrowRight,ArrowLeft } from 'react-bootstrap-icons';


var DatePicker = require("reactstrap-date-picker");
  
export default class ScheduledMaintenanceAssets extends Component {
  static displayName =  'Alex testing' //AFEDetail.name

  constructor(props) {
      super(props)
      this.onGridRowSelectedAsset = this.onGridRowSelectedAsset.bind(this);
      this.onGridRowSelectedScheduleAssets = this.onGridRowSelectedScheduleAssets.bind(this);
      this.removeAssets = this.removeAssets.bind(this);
      this.clearGrids = this.clearGrids.bind(this);
      this.clearRemoveAssets = this.clearRemoveAssets.bind(this);
      this.onGridRefLeft = this.onGridRefLeft.bind(this);
      this.onGridRefRight = this.onGridRefRight.bind(this);
      this.reSetGrids = this.reSetGrids.bind(this);
      this.getAssets = this.getAssets.bind(this);
      this.onGridRowDragEnd = this.onGridRowDragEnd.bind(this);
      this.onStatusCellUpdating = this.onStatusCellUpdating.bind(this);

      this.state = { 
          recordKey: props.recordKey,
          pmScheduleKey: props.pmScheduleKey,
          itemTypeKey: props.itemTypeKey,
          locationKey: props.locationKey,
          facilityKey: props.facilityKey,
          statusKey: props.statusKey,
          isLoaded: false,
          activeTab:      1,
          leftGridData:   [],
          rightGridData:  [],
          addedAsset:     [],
          removeAssets:   [],
          isSecurityAdminLogin: props.isSecurityAdminLogin, 
      } 
      

     // Bind event handler methods
     
  }

  toggleTab = tab => {
      const { activeTab } = this.state

      if (activeTab !== tab) {
          this.setState({ activeTab: tab })
      }
  }
  
  async componentDidUpdate(prevProps) {
    
    if (prevProps.pmScheduleKey !== this.state.pmScheduleKey || 
        prevProps.itemTypeKey !== this.state.itemTypeKey || 
        prevProps.locationKey !== this.state.locationKey || 
        prevProps.facilityKey !== this.state.facilityKey ){
      this.setState({isLoaded: false
                    ,pmScheduleKey: prevProps.pmScheduleKey
                    ,itemTypeKey: prevProps.itemTypeKey
                    ,locationKey: prevProps.locationKey
                    ,facilityKey: prevProps.facilityKey })
      await this.getAssets(prevProps.itemTypeKey,prevProps.locationKey,prevProps.facilityKey);
      await this.getAssets();
      this.setState({isLoaded: true});
    }
    else if(prevProps.statusKey !== this.state.statusKey){
      this.setState({isLoaded: false , statusKey: prevProps.statusKey})
      this.setState({isLoaded: true});
    }

  }

  async componentDidMount() { 
     
    await this.getAssets();
    this.setState({ isLoaded: true });
  }
 
  async getAssets(paraItemTypeKey,paramLocationKey,paramFacilityKey){
    const {triggerModel,pmScheduleKey,itemTypeKey,locationKey,facilityKey } = this.state;

    let tempItemType = (paraItemTypeKey !== undefined && paraItemTypeKey !== null ? paraItemTypeKey : (itemTypeKey === null ? 0 : itemTypeKey));
    if (tempItemType <= 0){
      return;
    }
 
    let tempRightGrid = [];
    // let myURI = apiURL + 'ScheduleMaintenance/FacilityInventoryCollector/'
    let myURI = apiURL + 'ScheduleMaintenance/GetPMScheduleAssetInformation/'
                          +  pmScheduleKey + '/'
                          + (paraItemTypeKey !== undefined ? (paraItemTypeKey === null ? 0 : paraItemTypeKey) : (itemTypeKey === null ? 0 : itemTypeKey)) + '/'
                          + (paramLocationKey !== undefined ? (paramLocationKey === null ? 0 : paramLocationKey) : (locationKey === null ? 0 : locationKey)) + '/'
                          + (paramFacilityKey !== undefined ? (paramFacilityKey === null ? 0 : paramFacilityKey) : (facilityKey === null ? 0 : facilityKey))
        
    await fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
        .then(res => res.json())
        .then(json => {

        tempRightGrid = this.createList(json.scheduleAsset);

        this.setState({
            //isTriggerFieldLoaded: true,
            leftGridData: this.createList(json.availableAsset),
            rightGridData: tempRightGrid,
        }) 
    });
    
    this.sendDataToParent(tempRightGrid);
  }

  createList = (value) => {
    // var tempList = []
    // value.map(e => {
    //     e.value = e.fixxMainTypeKey
    //     e.label = e.fixxMainTypeDesc
    //     tempList.push(e)
    // })

    let selectedColumns = value.map(item => ({
      assetKey: item.assetKey,
      assetID: item.assetID,
      assetName: item.assetName,
      inService: item.inService,
      qrCodeValue: item.qrCodeValue
    }));

    return selectedColumns
} 


  onGridRefLeft(grid) {
    if (!grid) {
        return;
    }
    this.grid = grid; 
    this.grid.dataSource = this.state.leftGridData;
  }
  onGridRefRight(grid) {
    if (!grid) {
        return;
    }
    this.grid = grid; 
    this.grid.dataSource = this.state.rightGridData;
  }

  onGridRowDragEnd(grid, event) {
    const ghostElement = event.detail.dragDirective.ghostElement;

    if (ghostElement !== null) {
      const dragElementPos = ghostElement.getBoundingClientRect();
      const gridPosition = document.getElementById('rightGrid').getBoundingClientRect();

      // Check if the dragged element is within the bounds of the second grid
      const withinXBounds = dragElementPos.x >= gridPosition.x && dragElementPos.x <= gridPosition.x + gridPosition.width;
      const withinYBounds = dragElementPos.y >= gridPosition.y && dragElementPos.y <= gridPosition.y + gridPosition.height;

      if (withinXBounds && withinYBounds) {
        const draggedRowData = event.detail.dragData;

        // Remove the dragged row from the left grid
        const updatedLeftGridData = this.state.leftGridData.filter(row => row.assetKey !== draggedRowData.assetKey);

        // Add the dragged row to the right grid
        const updatedRightGridData = [...this.state.rightGridData, draggedRowData];

        // Update state with new data for both grids
        this.setState({
          leftGridData: updatedLeftGridData,
          rightGridData: updatedRightGridData
        });
      }
    }
  }
  
  onGridRowSelectedAsset(s, e) {   
    let tempList=[] 
    let clickedRows = e.currentKeys; 
    for (let i = 0; i < e.currentKeys.count; i++){
        tempList.push({ assetKey: e.currentKeys.item(i).value[0] }) ; 
    }

    this.setState({addedAsset: tempList}); 
  }

  onGridRowSelectedScheduleAssets(s, e) {   
    let tempList=[] 
    let clickedRows = e.currentKeys; 
    this.setState({removeAssets: []});

    for (let i = 0; i < e.currentKeys.count; i++){  
        tempList.push({ 
          assetKey: e.currentKeys.item(i).value[0] 
            }) ; 
    }

    this.setState({removeAssets: tempList}); 
  }

  clearRemoveAssets = () =>{
    this.setState({removeAssets: []}); 
    return;
   }

  addAsset = async () => {
    let {leftGridData, rightGridData,addedAsset} = this.state;
      
    let tempSchedules = []; 

    //Get current records on the schedule
    for (let i = 0; i < rightGridData.length; i++){
        tempSchedules.push({assetKey: rightGridData[i].assetKey,
                            assetID: rightGridData[i].assetID,
                            assetName: rightGridData[i].assetName,
                            inService: rightGridData[i].inService,
                            qrCodeValue: rightGridData[i].qrCodeValue
                          }) ; 
    }

    //Add to Schedule Assets.
    for (let i = 0; i < addedAsset.length; i++){ 
        let obj = tempSchedules.filter(x => x.assetKey == addedAsset[i].assetKey)
        if (obj == null || obj.length <=0){
            let tempObj = leftGridData.filter(x => x.assetKey == addedAsset[i].assetKey)
            tempSchedules.push(tempObj[0]);
        }
    }

    let tempLeftGridData = [];  
    for (let i = 0; i < leftGridData.length; i++){
      tempLeftGridData.push({assetKey: leftGridData[i].assetKey,
                              assetID:leftGridData[i].assetID,
                              assetName:leftGridData[i].assetName,
                              inService: leftGridData[i].inService,
                              qrCodeValue: leftGridData[i].qrCodeValue}) ; 
    }
    
    //Remove Route Asset Records
    for (let i = 0; i < tempLeftGridData.length; i++){
        for (let z = 0; z < tempSchedules.length; z++){
            if(tempLeftGridData[i].assetKey == tempSchedules[z].assetKey){
              tempLeftGridData.splice(i,1);
            }  
        }
    }    

    tempSchedules.sort((a,b) => (a.assetID > b.assetID) ? 1: -1);
    tempLeftGridData.sort((a,b) => (a.assetID > b.assetID) ? 1: -1);
    tempSchedules.sort((a,b) => (a.inService > b.inService) ? -1: 1);
    tempLeftGridData.sort((a,b) => (a.inService > b.inService) ? -1: 1); 
    
    this.setState({leftGridData: tempLeftGridData, rightGridData: tempSchedules,addedAsset: []});

    this.sendDataToParent(tempSchedules);
  }
 
  removeAssets = () =>{
      let {leftGridData,rightGridData,removeAssets} = this.state; 

      let tempLeftGridData = [];
       
      for (let i = 0; i < leftGridData.length; i++){
        tempLeftGridData.push({assetKey: leftGridData[i].assetKey,
                              assetID: leftGridData[i].assetID,
                              assetName: leftGridData[i].assetName,
                              inService: leftGridData[i].inService,
                              qrCodeValue: leftGridData[i].qrCodeValue
                          }) ; 
      }


      this.setState({isLoaded: false});

       

      for (let i = 0; i < removeAssets.length; i++){ 
          let obj = rightGridData.filter(x => x.assetKey == removeAssets[i].assetKey)
          if (obj !== null && obj.length >0){
            tempLeftGridData.push({assetKey: obj[i].assetKey,
                                  assetID: obj[i].assetID,
                                  assetName: obj[i].assetName,
                                  inService: obj[i].inService,
                                  qrCodeValue: obj[i].qrCodeValue
                                });
           
          }
      }

      let tempRightGridData = [];

      for (let i = 0; i < rightGridData.length; i++){
        tempRightGridData.push({assetKey: rightGridData[i].assetKey,
                            assetID: rightGridData[i].assetID,
                            assetName: rightGridData[i].assetName,
                            inService: rightGridData[i].inService,
                            qrCodeValue: rightGridData[i].qrCodeValue
                          }) ; 
      }

      for (let i = 0; i < removeAssets.length; i++){
        for (let z = 0; z < tempRightGridData.length; z ++){
          if(removeAssets[i].assetKey === tempRightGridData[z].assetKey){
            tempRightGridData.splice(z,1);
          }
        } 
      }
      this.clearGrids(); 
      tempLeftGridData.sort((a,b) => (a.assetID > b.assetID) ? 1: -1);
      tempRightGridData.sort((a,b) => (a.assetID > b.assetID) ? 1: -1);
      tempLeftGridData.sort((a,b) => (a.inService > b.inService) ? -1: 1);
      tempRightGridData.sort((a,b) => (a.inService > b.inService) ? -1: 1); 

      this.reSetGrids(tempLeftGridData,tempRightGridData);
        
      
  }

  isAssetInService =()=>{
    const{leftGridData,addedAsset} = this.state;

    if (addedAsset === undefined || addedAsset === null || addedAsset.length <= 0){
      return false;
    }

    let obj = leftGridData.filter(x => x.assetKey == addedAsset[0].assetKey);
    
    if (obj != undefined && obj !== null && obj.length > 0 && obj[0].inService == 1){
      return true;
    }

    return false;

  }

  clearGrids(){ 
    this.setState({isLoaded: false,leftGridData: [],rightGridData: []});
  }
  
  reSetGrids(leftGrid,rightGrid){
    this.setState({
                    leftGridData: (leftGrid.length > 0 ? leftGrid : [])
                    ,rightGridData: (rightGrid.length > 0 ? rightGrid : [])
                    ,removeAssets: []
                    ,isLoaded: true,
                  });

    this.sendDataToParent((rightGrid.length > 0 ? rightGrid : []));
  }

  handleRowSelectionChange(event){
    const selectedRows = event.newSelection;
    if (selectedRows.length > 0) {
        // Access the first selected row's data
        const selectedRowData = selectedRows[0].data;
        console.log('Selected Row Data:', selectedRowData);
        // You can use this data for further processing, e.g., updating state or displaying in a modal
    }
  };  

  sendDataToParent(tempSchedules){ 
    //const {rightGridData} = this.state; 
    this.props.sendDataToParentFromScheduledAssets(tempSchedules); 
  }

  onStatusCellUpdating(s, e) {
    const content = e.content;
    const info = e.cellInfo;
    const item = info.rowItem;
    // content.style.backgroundColor = "#4286f4";

    // console.log("item.statusKey")
    // console.log(item.statusKey + item.statusDesc+item.safetyObservationNo )
    let link;
    if (content.childElementCount === 0) {
        link = document.createElement("label");
        let cell = document.createElement("div");

        content.style.fontFamily = "Verdana";
        content.style.fontSize = "13px";

        content.style.margin = "0px -20px -10px -12px";
        content.style.padding = "0px 10px 10px 10px";
        //content.style.width = "100%";

        content.appendChild(link);
    }
    else {
        link = content.children[0];

        // if (item.statusKey === 3 || item.closeoutStatusKey === 3) {
        //     content.style.background = "#f0f5b0";
        // } else if (item.statusKey === 1 || item.statusKey === 5 ||
        //     item.closeoutStatusKey === 1 || item.closeoutStatusKey === 10) {
        //     content.style.background = "#F3B5A2";
        // } else if (item.statusKey === 6) {
        //     content.style.background = "#b8f2b1";
        // }
        // else {
            content.style.background = "transparent";
        // }
 
    }

    switch (s.field) {
        case 'afeStatusDesc':
            //link.textContent = item.afeStatusDesc;
            link.textContent = "60%";
            break;
        case 'afeName':
            link.textContent = item.afeName;
            break;
        case 'afeSignedDate':
            link.textContent = ""
            if (item.afeSignedDate !== null) {
                var date = new Date(item.afeSignedDate);
                link.textContent = date.toLocaleDateString();

            }
            content.style.background = "transparent";
            break;
        case 'afeNo':
            link.textContent = item.afeNo;
            break;

        case 'afeApprovedDate':
            // console.log("afeApprovedDate")
            // console.log(item.afeApprovedDate)
            link.textContent = ""

            if (item.afeApprovedDate !== null) {
                var date = new Date(item.afeApprovedDate);
                link.textContent = date.toLocaleDateString();
                //content.style.background = "transparent";

            }
            content.style.background = "transparent";
            break;
        case 'createdDatetime':
            link.textContent = ""
            if (item.createdDatetime !== null) {
                var date = new Date(item.createdDatetime);
                link.textContent = date.toLocaleDateString();
                //content.style.background = "transparent";

            }
            content.style.background = "transparent";
            break;
        case 'estStartDate':
            link.textContent = ""
            if (item.estStartDate !== null) {
                var date = new Date(item.estStartDate);
                link.textContent = date.toLocaleDateString();
                //content.style.background = "transparent";

            }
            content.style.background = "transparent";
            break;
        case 'expectedCompletionDate':
            link.textContent = ""
            if (item.expectedCompletionDate !== null) {
                var date = new Date(item.expectedCompletionDate);
                link.textContent = date.toLocaleDateString(); 

            }
            content.style.background = "transparent";
            break;
        case 'dateOfLastReport':
            link.textContent = ""
            if (item.dateOfLastReport !== null) {
                var date = new Date(item.dateOfLastReport);
                link.textContent = date.toLocaleDateString(); 
            }
            content.style.background = "transparent";
            break;
        case 'createdDate':
            link.textContent = ""
            if (item.createdDate !== null) {
                var date = new Date(item.createdDate);
                link.textContent = date.toLocaleDateString(); 
            }
            content.style.background = "transparent";
            break;
        case 'lastModifiedDate':
            link.textContent = ""
            if (item.lastModifiedDate !== null) {
                var date = new Date(item.lastModifiedDate);
                link.textContent = date.toLocaleDateString(); 
            }
            content.style.background = "transparent";
            break;
        case 'createdByName':
            link.textContent = item.createdByName;
            break;
        case 'managerName':
            link.textContent = item.managerName;
            break;
        case 'locationName':
            link.textContent = item.locationName;
            break;
        case 'facilityName':
            link.textContent = item.facilityName;
            break;
        case 'actualvsBudget':
            link.textContent = (item.actualvsBudget >= 1 ? Math.round(item.actualvsBudget) : item.actualvsBudget) + '%';
            link.setAttribute("class", "text-md-right form-control selectBorderLess");

            break;
        case 'projectedvsBudget':
            link.textContent = (item.projectedvsBudget >= 1 ? Math.round(item.projectedvsBudget) : item.projectedvsBudget) + '%';
            link.setAttribute("class", "text-md-right form-control selectBorderLess");

            break;
        case 'budgetID':
            link.textContent = item.budgetID === undefined || item.budgetID === null ? '' : this.getSelBudget(item.budgetID);
            content.style.background = "transparent";
            break;
        case 'inService':
            link.textContent = item.inService ;
            content.style.background = (item.inService <= 0 ? "red" :  "#transparent"); 

            // if (item.statusKey === 0 || item.statusKey === 3){
            //     content.style.background = "transparent";
            // }
            // else if (item.totalCompletion >= 100){
            //     content.style.background = "#b8f2b1"; 
            // }
            // else if (item.totalCompletion >= 64 && item.totalCompletion < 97){
            //     content.style.background = "#28A745";  
            //     content.style.background = 'linear-gradient(to right, #28A745 ' + item.totalCompletion + '%, white ' + item.totalCompletion + '%)';
            // }
            // else if (item.totalCompletion >= 50 && item.totalCompletion <= 63){
            //     content.style.background = "#28A745";  
            //     content.style.background = 'linear-gradient(to right, #ffaf7a ' + item.totalCompletion + '%, white ' + item.totalCompletion + '%)';
            // }
            // else if (item.totalCompletion >= 0 && item.totalCompletion <= 49){
            //     content.style.background = "#28A745";  
            //     //content.style.background = 'linear-gradient(to right, red 50%, white 50%)';
            //     if (item.totalCompletion <= 0){
            //         content.style.background = 'linear-gradient(to right, red 5%, white 5%)';  
            //     }
            //     else {
            //         content.style.background = 'linear-gradient(to right, red '+ + item.totalCompletion + '%, white ' + item.totalCompletion + '%)';
            //     }

            // }
            // else if (item.totalCompletion > 30 && item.totalCompletion < 100){
            //     content.style.background = "#ffaf7a";
            // }
            // else if (item.totalCompletion <= 30){
            //     //content.style.background = "#f8858b";
            //     content.style.background = 'linear-gradient(to right, red 50%, white 50%)';
            // }
            
            break;

        default:

    }

  }

  render() {
       const { isLoaded,leftGridData,rightGridData,isSecurityAdminLogin
            ,locationKey,facilityKey,statusKey} = this.state

      if (!isLoaded) {
          return (<div>Loading Assets .....</div>)
      } 
      else{ 
          return (
            <div> 
              <Form style={{marginLeft:"90px" }}>
                  <Row Form style={{marginTop:"20px" }}>
                    <Col md={6}>
                          <IgrDataGrid
                              id="leftGrid"
                              ref={this.onGridRefLeft}
                              dataSource={leftGridData}
                              width="100%"
                              height="400px"
                              primaryKey="assetKey" 
                              selectionMode="SingleRow"
                              selectedKeysChanged={this.onGridRowSelectedAsset}
                              editMode={0}
                              >
                              <IgrTextColumn field="assetKey" headerText="AssetKey" width="100px" isHidden={true}></IgrTextColumn>
                              <IgrTextColumn field="assetID" headerText="AssetID" width="100px"></IgrTextColumn>
                              <IgrTextColumn field="assetName" headerText="Asset Name" width="100px"></IgrTextColumn>
                              <IgrTextColumn field="qrCodeValue" headerText="QRCode" width="100px"></IgrTextColumn>
                              <IgrTemplateColumn field="inService" headerText="Inservice" width="100px" cellUpdating={this.onStatusCellUpdating}/>

                              {/* <IgrTemplateColumn
                                    field="totalCompletion" 
                                    headerText="% Complete"
                                    width="*>150"
                                    cellUpdating={this.onStatusCellUpdating}
                                /> */}
                          </IgrDataGrid>    
                    </Col>
                    <Col md={1} style={{marginRight:"-43px" }}> 
                          <Row Form style={{marginTop:"50px" }}>
                              <Button outline color="secondary"
                                      className="btn-no-border"
                                      disabled={ leftGridData.length <= 0  || !isSecurityAdminLogin || statusKey <= 0 || !this.isAssetInService() }  
                                      onClick={this.addAsset}> 
                                      <ArrowRight color="green" size={40} /> 
                                  
                              </Button>
                          </Row>
                          <Row Form>
                              <Button outline color="secondary"
                                  className="btn-no-border"
                                  disabled={ rightGridData.length <= 0 || !isSecurityAdminLogin || statusKey <= 0} 
                                  onClick={this.removeAssets} 
                                  >
                                  <ArrowLeft color="green" size={40} verticalAlign={"bottom"} />
                              </Button>
                          </Row>
                    </Col>
                    <Col md={5}> 
                          <IgrDataGrid
                              id="rightGrid"
                              ref={this.onGridRefRight}
                              dataSource={rightGridData}
                              width="100%"
                              height="400px"
                              primaryKey="assetKey"
                              emptyGridMessage="Drag a row from the left grid to this grid"
                              selectionMode="SingleRow"
                              selectedKeysChanged={this.onGridRowSelectedScheduleAssets} 
                              >
                              <IgrNumericColumn field="assetKey" headerText="AssetKey" width="100px" isHidden={true}></IgrNumericColumn>
                              <IgrTextColumn field="assetID" headerText="AssetID" width="100px"></IgrTextColumn>
                              <IgrTextColumn field="assetName" headerText="Asset Name" width="100px"></IgrTextColumn>
                              <IgrTextColumn field="qrCodeValue" headerText="QRCode" width="100px"></IgrTextColumn>
                              <IgrNumericColumn field="inService" headerText="Inservice" width="100px"></IgrNumericColumn> 
                          </IgrDataGrid>    
                    </Col>
                  </Row>
              </Form>
            </div>

             
         
      
          )
      }
          
  }

}

 