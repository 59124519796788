import React,{Component} from 'react';
import {FormGroup, TabContent, TabPane,Nav, NavItem, NavLink, } from 'reactstrap';
import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiDELETEHeader } from '../../resources/apiURL';
import classnames from 'classnames';
import '../../custom.css';
import WorkOrder from './Workorder'
import WOClosed from './WOClosed'
import spade from '../../resources/spade.png'
import WOListView from './WOListView';
export default class WOHome extends Component {

    constructor(props){
        super(props);

        this.state ={
            activeTab:'1',
            // basinList: [],
            // isBasinLoaded: false,
            // assetList: [],
            // isAssetListLoaded: false,
            // statusList: [],
            // isStatusLoaded: false,
            // mainTypeList: [],
            // isMainTypeListLoaded: false,
            // empList: [],
            // isEmpListLoaded: false,
            // archivedStatusList: [],
            // isArchivedStatusLoaded: false
            pmScheduleKey:props.pmScheduleKey
        }

        
    }

    toggle = tab => {
        if (this.state.activeTab !== tab) {
            this.setState(() => ({
                activeTab: tab
            }))
        }
    }

    // getBasin = () => {
    //     let myURI = apiURL + 'Fiix/GetBasin'


    //     fetch(myURI, { modes: 'no-cors' })
    //         .then(res => res.json())
    //         .then(json => {
    //             this.setState({
    //                 basinList: this.createBasinList(json),
    //                 isBasinLoaded: true
    //             })
    //         });
    // }


    // createBasinList = (value) => {
    //     var tempList = []
    //     value.map(e => {
    //         e.value = e.locationKey
    //         e.label = e.strName
    //         tempList.push(e)
    //     })

    //     return tempList
    // }

    // getAssetList = () => {

    //     let myURI = apiURL + 'ItemMaster/FacilityInventoryCollector2'
    //     fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
    //         .then(res => res.json())
    //         .then(json => {

    //             this.setState({
    //                 isAssetListLoaded: true,
    //                 assetList: this.createAssetList(json)
    //             })

    //         });
    // }
    // createAssetList = (value) => {
    //     var tempList = []
    //     value.map(e => {
    //         e.value = e.facilityInvCollectorKey
    //         e.label = e.qrCodeValue
    //         tempList.push(e)
    //     })

    //     return tempList
    // }

    // getStatus = () => {
    //     let myURI = apiURL + 'Fiix/Status'

    //     fetch(myURI, { modes: 'no-cors' })
    //         .then(res => res.json())
    //         .then(json => {
    //             this.setState({
    //                 statusList: this.createStatusList(json),
    //                 isStatusLoaded: true
    //             })
    //             // console.log(json)
    //         });
    // }

    // getArchivedStatus = () => {
    //     let myURI = apiURL + 'Fiix/ArchivedStatus'

    //     fetch(myURI, { modes: 'no-cors' })
    //         .then(res => res.json())
    //         .then(json => {
    //             this.setState({
    //                 archivedStatusList: this.createStatusList(json),
    //                 isArchivedStatusLoaded: true
    //             })
    //             // console.log(json)
    //         });
    // }

    // createStatusList = (value) => {
    //     var tempList = []
    //     value.map(e => {
    //         e.value = e.fiixWOStatus
    //         e.label = e.fiixWOStatus
    //         tempList.push(e)
    //     })

    //     return tempList
    // }


    // getMainType = () => {

    //     let myURI = apiURL + 'Fiix/MainType'
    //     fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
    //       .then(res => res.json())
    //       .then(json => {
    
    //         this.setState({
    //           isMainTypeListLoaded: true,
    //           mainTypeList: this.createMainTypeList(json)
    //         })
    //         // console.log(json)
    //       });
    
    //   }

    //   createMainTypeList = (value) => {
    //     var tempList = []
    //     value.map(e => {
    //       e.value = e.fixxMainTypeKey
    //       e.label = e.fixxMainTypeDesc
    //       tempList.push(e)
    //     })
    
    //     return tempList
    //   }


    //   getEmpList = () => {
    //     let myURI = apiURL + "EmployeeDetails";


    //     fetch(myURI, { modes: "no-cors" })
    //         .then((res) => res.json())
    //         .then((json) => {
    //             this.setState({
    //                 empList: this.createEmpList(json),
    //                 isEmpListLoaded: true,
    //             });
    //         });
    // };

    // createEmpList = (value) => {
    //     var tempList = [];
    //     value.map((e) => {
    //         e.value = e.employeeKey;
    //         e.label = e.empName;
    //         tempList.push(e);
    //     });

    //     return tempList;
    // };



    componentDidMount=()=>{
        // this.getBasin()
        // this.getAssetList()
        // this.getStatus()
        // this.getMainType()
        // this.getEmpList()
        // this.getArchivedStatus()
    }
    render(){
        const {activeTab, isBasinLoaded, basinList, assetList, isAssetListLoaded, isStatusLoaded, statusList, isMainTypeListLoaded, mainTypeList, empList, isEmpListLoaded, isArchivedStatusLoaded, archivedStatusList,pmScheduleKey} = this.state;
        // if (!isBasinLoaded || !isAssetListLoaded || !isStatusLoaded || !isMainTypeListLoaded || !isEmpListLoaded ||!isArchivedStatusLoaded) {
        //     return (<div>Loading WO data.....</div>)
        // } else
        return (
<div>
                    <div className="header-tabs-container">
                        <FormGroup className="tabs-container">
                            <Nav tabs>
                                <NavItem>
                                    <NavLink className={classnames({ active: activeTab === '1' })}
                                        onClick={() => { this.toggle('1') }}>
                                        <h5>Active</h5>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: activeTab === '2' })}
                                        onClick={() => { this.toggle('2') }}>
                                        <h5>Closed</h5>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: activeTab === '3' })}
                                        onClick={() => { this.toggle('3') }}>
                                        <h5>Archive</h5>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </FormGroup>
                        <div className="header-container">
                            <img src={spade} alt="spade" className="header-icon" />
                            <h4>ACE Work Orders</h4>
                        </div>
                        <div className="spacer"></div>
                    </div>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <WorkOrder user={this.props.user}
                                        pmScheduleKey={pmScheduleKey}
                                // basinList={basinList}
                                // assetList={assetList}
                                // statusList={statusList}
                                // mainTypeList={mainTypeList}
                                // empList={empList}
                            />
                        </TabPane>
                        <TabPane tabId="2">
                            <WOClosed user={this.props.user}
                                     pmScheduleKey={pmScheduleKey}
                                // empList={empList}
                                // basinList={basinList}
                                // statusList={archivedStatusList}
                                // mainTypeList={mainTypeList}
                            />
                        </TabPane>
                        <TabPane tabId="3">
                            <WOListView 
                                user={this.props.user}
                                pmScheduleKey={pmScheduleKey}
                            />
                        </TabPane>
                    </TabContent>
                </div>
        );
    }
}