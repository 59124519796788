import React, { Component} from 'react';
import {Col, Row, Button, FormGroup, Label} from 'reactstrap';
import {apiURL, apiHeader} from '../../resources/apiURL';

import '../../custom.css';

import RefreshIcon from '../../resources/refresh.svg';
import ExcelIcon from '../../resources/excel.svg';

import { IgrDataGrid } from 'igniteui-react-grids';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn,IgrNumericColumn } from 'igniteui-react-grids';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn } from 'igniteui-react-grids';
import { ExcelUtility } from '../ExcelUtility';
import { ColoredLine, getFirstDayofMonth} from '../../resources/utility';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { getUserADAccount, getRandomInt } from '../../resources/utility'
import Select from 'react-select';
import WORequest from './WORequest'

import clearfilter from '../../components/img/clearfilter.png'

IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
IgrDatePickerModule.register();

var DatePicker = require("reactstrap-date-picker");
export default class woArchiveData extends Component{
    constructor (props){
        super(props)

        this.onGridRef = this.onGridRef.bind(this);
        this.saveWorkbook = this.saveWorkbook.bind(this);
        this.onStatusCellUpdating = this.onStatusCellUpdating.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
        this.onGridRowSelected2 = this.onGridRowSelected2.bind(this);
        this.updateGridHeight = this.updateGridHeight.bind(this);
        this.searchInput = React.createRef();  
        this.state = {
                woArchiveData: [],
                WOData:[],
                isLoaded: false,
                windowHeight:window.innerHeight,
                windowWidth:((typeof(props.WindowWidth) ==='undefined' || props.WindowWidth===null) ? window.innerWidth : props.WindowWidth),
                startDate: getFirstDayofMonth().toISOString(),
                endDate: new Date().toISOString(),
                gridHeight: window.innerHeight * 0.3,
                modalWODetails: false,
                isTyping: false,
                empList: [],
                basinList: [],
                isBasinLoaded: false,
                archivedStatusList: [],
                isArchivedStatusLoaded: false,
                mainTypeList: [],
                isMainTypeListLoaded: false,
                isEmpListLoaded: false,
                switchBool: null,
                qrValue: null,
                workOrderKey: -1,
                woNumber: null,
                maintenanceTypeID: null,
                facilityID: null,
                fiixWOId: null,
                fiixWOStatus: "",
                locationKey: null,
                selBasin: null,
                selStatus: null,
                selMainType: null,
                searchText: "",
                selArchivedWOQuery: [],
                security:[],
                isSecurityLoaded: false,
                isSecurityAdminLogin: false,
                isDataLoaded: false,
                pmScheduleKey: props.pmScheduleKey,
                archivedDataQueryList: [{
                    "label": "My Work Orders",
                    "value": 1
                }, {
                    "label": "All Work Orders",
                    "value": 2
                },
                {
                  "label": "Unassigned Work Orders",
                  "value": 3
              }
              ],
                
        }
    }

    onGridRef(grid){
        if (!grid){
            return;
        }
        this.grid = grid;
        this.grid.dataSource=this.state.WOData;
    }
 
    // getListView= () =>{

    //     let myURI = apiURL + 'Fiix/WorkOrderListView/StartDate/' +
    //     this.state.startDate.toString().substring(0, 10) +
    //     '/enddate/' + this.state.endDate.toString().substring(0, 10) 

    //     fetch(myURI, apiHeader('GET'), {modes: 'no-cors'})
    //     .then(res => res.json())
    //     .then(json=>{
    //         this.setState({
    //             isLoaded: true,
    //             woArchiveData: json
    //         })
    //     })
    // }

    getArchiveWOdata = () => {
        let myURI = apiURL + 'Fiix/ArchiveWorkOrder'

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isLoaded: true,
                    woArchiveData: json,
                    searchText: "",
                    isTyping: false,
                    isDataLoaded: true
                },()=>{
                    console.log(json)
                })
                this.getDefaultArchivedWOList(json)
            });
 
    }




    handleChange(value) {
        this.setState({
            startDate: value,
        });

    }

    handleEndDateChange(value) {
        this.setState({
            endDate: value,
        });

    }

    
    // handleResize = (e) => {
   
    //     if (document.getElementById('mainDiv') !== null){
    //         this.setState({ windowHeight: window.innerHeight,
    //             windowWidth: document.getElementById('mainDiv').offsetWidth });

    //     }
    // };

    getEmpList = () => {
      let myURI = apiURL + "EmployeeDetails";


      fetch(myURI, { modes: "no-cors" })
          .then((res) => res.json())
          .then((json) => {
              this.setState({
                  empList: this.createEmpList(json),
                  isEmpListLoaded: true,
              });
          });
  };

  createEmpList = (value) => {
      var tempList = [];
      value.map((e) => {
          e.value = e.employeeKey;
          e.label = e.empName;
          tempList.push(e);
      });

      return tempList;
  };

  getBasin = () => {
    let myURI = apiURL + 'Fiix/GetBasin'


    fetch(myURI, { modes: 'no-cors' })
        .then(res => res.json())
        .then(json => {
            this.setState({
                basinList: this.createBasinList(json),
                isBasinLoaded: true
            })
        });
}


createBasinList = (value) => {
    var tempList = []
    value.map(e => {
        e.value = e.locationKey
        e.label = e.strName
        tempList.push(e)
    })

    return tempList
}

getArchivedStatus = () => {
  let myURI = apiURL + 'Fiix/ArchivedStatus'

  fetch(myURI, { modes: 'no-cors' })
      .then(res => res.json())
      .then(json => {
          this.setState({
              archivedStatusList: this.createArchivedStatusList(json),
              isArchivedStatusLoaded: true
          })
          // console.log(json)
      });
}
createArchivedStatusList = (value) => {
  var tempList = []
  value.map(e => {
      e.value = e.fiixWOStatus
      e.label = e.fiixWOStatus
      tempList.push(e)
  })

  return tempList
}

getMainType = () => {

  let myURI = apiURL + 'Fiix/MainType'
  fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
    .then(res => res.json())
    .then(json => {

      this.setState({
        isMainTypeListLoaded: true,
        mainTypeList: this.createMainTypeList(json)
      })
      // console.log(json)
    });

}

createMainTypeList = (value) => {
  var tempList = []
  value.map(e => {
    e.value = e.fixxMainTypeKey
    e.label = e.fixxMainTypeDesc
    tempList.push(e)
  })

  return tempList
}

getMainType = () => {

  let myURI = apiURL + 'Fiix/MainType'
  fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
    .then(res => res.json())
    .then(json => {

      this.setState({
        isMainTypeListLoaded: true,
        mainTypeList: this.createMainTypeList(json)
      })
      // console.log(json)
    });

}

createMainTypeList = (value) => {
  var tempList = []
  value.map(e => {
    e.value = e.fixxMainTypeKey
    e.label = e.fixxMainTypeDesc
    tempList.push(e)
  })

  return tempList
}

    componentDidMount(){
        // window.addEventListener("resize", this.handleResize);
        this.getMainType()
        this.getArchivedStatus()
        this.getBasin()
        this.getEmpList()
        this.getSecurity()
        window.addEventListener('resize', this.updateGridHeight);
        this.updateGridHeight()

        if (!this.state.isDataLoaded) {
            this.setDefaultArchivedWOQuery();
        }
    }


    componentWillUnmount() {
        // window.addEventListener("resize", this.handleResize);
        window.removeEventListener('resize', this.updateGridHeight);
    } 

    updateGridHeight = () => {
        const availableHeight = window.innerHeight * 0.65; // Calculate 60% of the window height
        this.setState({ gridHeight: availableHeight });
    }

    onGridRowSelected(s, e) {
        const { fiixWOID } = this.state
        const content = e.content;
        if (e.addedKeys.count > 0) {
            let clickedRow = e.addedKeys.item(0)
            let dataItem = clickedRow.value[0]
            this.setState({
                workOrderKey: dataItem.workOrderKey,
                woNumber: dataItem.woNumber,
                bigFormat: true,
                fiixWOId: dataItem.fiixWOId,
                maintenanceTypeID: dataItem.maintenanceTypeID,
                fiixWOStatus: dataItem.fiixWOStatus,
                isTyping: false
            })

            // console.log(dataItem)
        }
    }

    onGridRowSelected2(s,e) {
        if (e.isDoubleClick){
            this.setState({ modalWODetails: !this.state.modalWODetails  });
        }
      }

      toggleEdit = () => {
        this.setState({
            modalWODetails: !this.state.modalWODetails,
            isTyping: false
        })

    }

    toggleWODetails = () => {
        this.setState({
            modalWODetails: !this.state.modalWODetails
        })
    }

    
    closeWODetailsForm = () => {

        this.setState({
            modalWODetails: !this.state.modalWODetails,
            // workOrderKey: -1, //Removed by BN 6/17/24. Fixes issue where form would not load contents if closed and reopened.
            openWODetails: false,
        })
    }


    resetFilter = async () => {
        const { WOData, selArchivedWOQuery } = this.state;
      
        // Clear filters on the grid
        for (var i = 0; i < this.grid.actualColumns.count; i++) {
          this.grid.actualColumns.item(i).filter = null;
        }
        this.grid.selectedItems.clear();
        this.grid.filterExpressions.clear();
      
        if (this.searchInput.current) {
          this.searchInput.current.value = '';
        }
      
        // Update the searchText state and clear basin and status filters
        this.setState({ searchText: '', selBasin: [], selStatus: [], selMainType: [] });
      
        // Reapply "My Work Orders"/"All Work Orders" filter
        let filteredData = await this.getFilterWOArchiveData(WOData, selArchivedWOQuery, [], []);
      
        // Update the state with the filtered data
        this.setState({ WOData: filteredData });
      };

      onTextChange = (fieldName, fieldValue, e) => {
        const { woArchiveData } = this.state;
    
        switch (fieldName) {
            case "selArchivedWOQuery":
                this.setState({ selArchivedWOQuery: e }, async () => {
                    let filteredData = await this.getFilteredData(woArchiveData);
                    this.setState({ WOData: filteredData });
                });
                break;
    
            case "basin":
                this.setState({ selBasin: e }, async () => {
                    let filteredData = await this.getFilteredData(woArchiveData);
                    this.setState({ WOData: filteredData });
                });
                break;
    
            case "status":
                this.setState({ selStatus: e }, async () => {
                    let filteredData = await this.getFilteredData(woArchiveData);
                    this.setState({ WOData: filteredData });
                });
                break;
            case "maintenance":
                this.setState({ selMainType: e }, async () => {
                    let filteredData = await this.getFilteredData(woArchiveData);
                    this.setState({ WOData: filteredData });
                });
                break;            
    
            default:
                break;
        }
    };

      onTextChange2 = async (fieldName, fieldValue, e) => {
        const { woArchiveData } = this.state;
    
        if (fieldName === "searchText") {
            let searchText = fieldValue.toLowerCase();
    
            if (searchText !== this.state.searchText) {
                this.setState({ searchText: searchText });
            }
    
            // Apply dropdown filters first
            let filteredData = await this.getFilteredData(woArchiveData);
    
            // If the search text is not empty, apply the search filter on top of the filtered data
            if (searchText !== '') {
                filteredData = filteredData.filter(item => {
                    return this.isItemMatchingSearchText(item, searchText);
                });
            }
    
            this.setState({ WOData: filteredData, searchText, isTyping: true });
        }
    };

    isItemMatchingSearchText = (item, searchText) => {
        const columnsToFilter = [
          "woNumber", "fiixWOStatus", "assetName", "qrCodeValue", "facilityName", 
          "woPriority", "maintenanceType", "fiixIssueType", "workOrderDesc", 
          "assetID", "createdByName", "reqestDateTime", "assignedToUserName", 
          "locationID", "locationName", "workdaySiteID", "estimateCost"
        ];
      
        return columnsToFilter.some(column => {
          if (item[column] && item[column].toString().toLowerCase().includes(searchText)) {
            return true;
          }
          return false;
        });
      };

    getFilteredData = async (data) => {
        const { selArchivedWOQuery, selBasin, selStatus, selMainType } = this.state;
    
        let filteredData = data;
    
        if (selArchivedWOQuery || (selBasin && selBasin.length > 0) || (selStatus && selStatus.length > 0) || ((selMainType && selMainType.length > 0))) {
            filteredData = await this.getFilterWOArchiveData(filteredData, selArchivedWOQuery, selBasin, selStatus, selMainType);
        }
    
        return filteredData;
    };

    getFilterWOArchiveData = async (data, m, b, s, z) => {
        const { user } = this.props;
        // console.log(z)
        let tempMtlRequest = data;
    
        const basins = b ? b.map(e => e.locationName).join(",") : "";
        const status = s ? s.map(e => e.fiixWOStatus).join(",") : "";
        const maintenance = z ? z.map(e => e.fixxMainTypeDesc).join(",") : "";
    

        // Filter by material request query
        if (m !== null) {
            const userName = getUserADAccount(user.account.userName).toLowerCase();
    
            if (m.value === 1) {
                tempMtlRequest = tempMtlRequest.filter(e => 
                    (e.createdBy || "").toLowerCase() === userName ||
                    (e.approvers || "").toLowerCase().includes(userName) ||
                    (e.assignedToADAccount || "").toLowerCase().includes(userName)
                );
            }else if (m.value === 3) { // Filter for Unassigned Work Orders
              tempMtlRequest = tempMtlRequest.filter(e => 
                  (e.fiixAssignedToUser || "").toLowerCase() === "" ||
                  e.fiixAssignedToUser === null
              );
          }
        }
        // Filter by maintenance

        if (maintenance.length > 0) {
          tempMtlRequest = tempMtlRequest.filter(e => maintenance.includes(e.maintenanceType));
      }
    
        // Filter by status
        if (status.length > 0) {
            tempMtlRequest = tempMtlRequest.filter(e => status.includes(e.fiixWOStatus));
        }
    
        // Filter by basin
        if (basins.length > 0) {
            tempMtlRequest = tempMtlRequest.filter(e => basins.includes(e.locationName));
        }
    
        return tempMtlRequest;
    };

    setDefaultArchivedWOQuery = () => {
        const { isSecurityAdminLogin } = this.state
        if (isSecurityAdminLogin === false) {
            this.setState({ selArchivedWOQuery: this.state.archivedDataQueryList[1] }, () => {
                this.getArchiveWOdata()
            })
        }
        else {
            this.setState({ selArchivedWOQuery: this.state.archivedDataQueryList[0] }, () => {
                this.getArchiveWOdata()
            })
        }

    }

    getSecurity = () => {
        let myURI = apiURL + 'EmployeeDetails/Checkpoint/' + this.props.user.account.userName
        const { security, appSecurityNo, meterVal } = this.state
        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                // console.log(this.props.user.account.userName)
                this.setState({

                    isSecurityLoaded: true,
                    security: json,
                    isSecurityAdminLogin: (json.filter((list) => list.appSecurityNo === 115).length > 0 ? false : true),
                    // isSecurityMeasurementTech: (json.filter((list)=> list.appSecurityNo === 15).length > 0 ? false: true),
                    // isOtherUser: (json.filter((list)=> list.appSecurityNo === 15 || list.appSecurityNo === 14 ).length == 0 ? true:false)
                }
                    , () => {
                        if (!this.state.isDataLoaded) {
                            this.setDefaultArchivedWOQuery();
                        }

                    }
                )

            })

    }


    getDefaultArchivedWOList = async (data) => {
        const filteredData = await this.getFilterWOArchiveData(data, this.state.selArchivedWOQuery, this.state.selBasin, this.state.selStatus, this.state.selMainType);
        // console.log('Filtered data:', filteredData);
        this.setState({
            WOData: filteredData // Set the filtered data to the WOData
        });
    };

    render(){
        const {isLoaded, woArchiveData, windowHeight, gridHeight,  workOrderKey, woNumber, qrValue, facilityKey, bigFormat,
             locationKey, facilityID, switchBool, fiixWOId,  maintenanceTypeID,  empList , fiixWOStatus, basinList, selBasin, 
             selStatus, archivedStatusList, isArchivedStatusLoaded, selMainType, mainTypeList , isSecurityLoaded, archivedDataQueryList, selArchivedWOQuery, isEmpListLoaded, isBasinLoaded, isMainTypeListLoaded,pmScheduleKey} = this.state
        // const gridHeight=(windowHeight * 0.65) + "px"
         const searchText = window.$searchText.toLowerCase()
        if(!isLoaded || !isSecurityLoaded ||!isEmpListLoaded ||!isBasinLoaded ||!isArchivedStatusLoaded ||!isMainTypeListLoaded){
          return (<h3>Loading Work Order List...... </h3>)
        }
        else{

          let { WOData} = this.state;

          if (WOData !== undefined && WOData !== null && WOData.length > 0 && pmScheduleKey !== undefined && pmScheduleKey !== null && pmScheduleKey > 0){
            WOData = WOData.filter(x => x.pmScheduleKey == pmScheduleKey);
          }

          return (
            <div>
              <FormGroup>
                <Row form>
                  {/* <h2> Work Order List View |</h2> */}
                  <Col sm={12}>
                  <Button
                    outline
                    color="secondary"
                    className="btn-no-border"
                    onClick={this.getArchiveWOdata}
                  >
                    <img
                      src={RefreshIcon}
                      alt="Refresh"
                      style={{ width: "30px", margin: "0 5px 0 0" }}
                    />
                    Refresh
                  </Button>
                  <Button
                          outline
                          color="secondary"
                          className="btn-no-border"
                          onClick={this.resetFilter}
                        >
                          <img
                            src={clearfilter}
                            alt="Clear Filter"
                            style={{ width: "30px" }}
                          />
                          Clear Filter
                        </Button>
                  <Button
                    outline
                    color="secondary"
                    className="btn-no-border"
                    onClick={this.saveWorkbook}
                  >
                    <img
                      src={ExcelIcon}
                      alt="excel"
                      style={{ width: "50px", margin: "0 0 0 0" }}
                    />
                    Export To Excel
                  </Button>{" "}
                  </Col>
                </Row>
                <ColoredLine color="grey" />
              </FormGroup>
              {/* <FormGroup>
                      <Row >
                          <Col md={3}>
                              <Label style={{ margin: "0 0 0 0", padding: "0" }}>
                                  Start Date
                              </Label>
                              <DatePicker value={this.state.startDate}
                                  onChange={(v, f) => this.handleChange(v, f)} />
                          </Col>
                          <Col md={3}>
                              <Label style={{ margin: "0 0 0 0", padding: "0" }} >
                                  End Date
                              </Label>
                              <DatePicker value={this.state.endDate}
                                  onChange={(v, f) => this.handleEndDateChange(v, f)} />
  
                          </Col>
  
                      </Row>
                  </FormGroup> */}
  
              <FormGroup>
                  <Row form>
                  <Col md={2}>
                    <Row form>
                      <Col md={12}>
                        <Label>Filter</Label>
  
                        <div>
                          <Select
                            className="alway-in-between-more"
                            options={archivedDataQueryList}
                            value={selArchivedWOQuery}
                            defaultValue={selArchivedWOQuery}
                            onChange={(e) =>
                              this.onTextChange("selArchivedWOQuery", e, e)
                            }
                          ></Select>
                        </div>
                      </Col>
                    </Row>
                  </Col>
  
                  <Col md={2}>
                    <Row form>
                      <Col md={12}>
                        <Label for="typeKey">Basin</Label>
                        <Select
                          options={basinList}
                          value={selBasin}
                          defaultValue={selBasin}
                          onChange={(e) => this.onTextChange("basin", e, e)}
                          isClearable={true}
                          isMulti
                        ></Select>
                      </Col>
                    </Row>
                  </Col>
  
                  <Col md={2}>
                    <Col md={12}>
                      <Label for="typeKey">Work Order Status</Label>
  
                      <Select
                        options={archivedStatusList}
                        value={selStatus}
                        defaultValue={selStatus}
                        onChange={(e) => this.onTextChange("status", e, e)}
                        isClearable={true}
                        isMulti
                      ></Select>
                    </Col>
                  </Col>
  
                   <Col md={2}>
                    <Col md={12}>
                      <Label for="typeKey">Maintenance Type</Label>
  
                      <Select
                        options={mainTypeList}
                        value={selMainType}
                        defaultValue={selMainType}
                        onChange={(e) => this.onTextChange("maintenance", e, e)}
                        isClearable={true}
                        isMulti
                      ></Select>
                    </Col>
                  </Col> 
  
                  <Col md={4}>
                        <Col md={12}>
                          <Label for="typeKey"> </Label>
                          <input
                            width="75%"
                            className="my-custom-reactstrap-input" /*Added by BN 6/14/24 Fixes issue where search bar is not focused */
                            placeholder="Type here to search......."
                            ref={this.searchInput}
                          //   onBlur={this.setFocusToSearchInput}
                            onChange={(e) =>
                              this.onTextChange2("searchText", e.target.value, e)
                            }
                          ></input>
                        </Col>
                      </Col>
  
  
                    </Row>
              </FormGroup>
  
              <div>
                <FormGroup>
                  <Row form style={{ zIndex: 0, position: "relative" }}>
                    <IgrDataGrid
                      ref={this.onGridRef}
                      // height={gridHeight}
                      height={`${gridHeight}px`} // Use dynamic grid height
                      width={"100%"}
                      isColumnOptionsEnabled="true"
                      cellTextStyle="14px Calibri"
                      headerTextStyle="14px Calibri"
                      rowHeight={"25"}
                      editMode={0}
                      dataSource={WOData}
                      autoGenerateColumns="false"
                      selectionMode="SingleRow"
                      cellClicked={this.onGridRowSelected2}
                      selectedKeysChanged={this.onGridRowSelected}
                    >
                      <IgrTextColumn
                        field="woNumber"
                        headerText="WO No"
                        width="*>120"
                        // cellUpdating={this.onStatusCellUpdating}
                      />
                      <IgrTextColumn
                        field="fiixWOStatus"
                        headerText="Status"
                        width="*>200"
                        // cellUpdating={this.onStatusCellUpdating}
                      />
  
                      <IgrTextColumn
                        field="assetName"
                        headerText="Asset"
                        width="*>200"
                        // cellUpdating={this.onStatusCellUpdating}
                      />
  
                      <IgrTextColumn
                        field="qrCodeValue"
                        headerText="QR Code"
                        width="*>170"
                        // cellUpdating={this.onStatusCellUpdating}
                      />
                      <IgrTextColumn
                        field="facilityName"
                        headerText="Facility"
                        width="*>170"
                        // cellUpdating={this.onStatusCellUpdating}
                      />
  
                      <IgrTextColumn
                        field="woPriority"
                        headerText="Priority"
                        width="*>200"
                        // cellUpdating={this.onStatusCellUpdating}
                      />
  
                      <IgrTemplateColumn
                        field="maintenanceType"
                        headerText="Maintenance Type"
                        width="*>250"
                        cellUpdating={this.onStatusCellUpdating}
                      />
  
                      <IgrTextColumn
                        field="fiixIssueType"
                        headerText="Issue Type"
                        width="*>250"
                        // cellUpdating={this.onStatusCellUpdating}
                      />
                      <IgrTextColumn
                        field="workOrderDesc"
                        headerText="Description"
                        width="*>400"
                        // cellUpdating={this.onStatusCellUpdating}
                      />
  
                      <IgrTextColumn
                        field="assetID"
                        headerText="Asset ID"
                        width="*>170"
                        // cellUpdating={this.onStatusCellUpdating}
                      />
  
                      <IgrTextColumn
                        field="createdByName"
                        headerText="Requested By"
                        width="*>150"
                        // cellUpdating={this.onStatusCellUpdating}
                      />
                      <IgrTemplateColumn
                        field="reqestDateTime"
                        headerText="Request Date"
                        width="*>200"
                        cellUpdating={this.onStatusCellUpdating}
                      />
                      <IgrTemplateColumn
                        field="modifiedBy"
                        headerText="Modified By"
                        width="*>150"
                        cellUpdating={this.onStatusCellUpdating}
                      />
                      <IgrTemplateColumn
                        field="modifiedDateTime"
                        headerText="Modified Date"
                        width="*>200"
                        cellUpdating={this.onStatusCellUpdating}
                      />
                      <IgrTextColumn
                        field="assignedToUserName"
                        headerText="Assigned To"
                        width="*>150"
                        // cellUpdating={this.onStatusCellUpdating}
                      />
                      <IgrTextColumn
                        field="locationID"
                        headerText="Location ID"
                        width="*>150"
                        // cellUpdating={this.onStatusCellUpdating}
                      />
  
                      <IgrTextColumn
                        field="locationName"
                        headerText="Location"
                        width="*>170"
                        // cellUpdating={this.onStatusCellUpdating}
                      />
                      <IgrTextColumn
                        field="workdaySiteID"
                        headerText="Workday Site ID"
                        width="*>150"
                        // cellUpdating={this.onStatusCellUpdating}
                      />
  
                      <IgrTextColumn
                        field="estimateCost"
                        headerText="Est Cost $"
                        width="*>170"
                        cellUpdating={this.onStatusCellUpdating}
                        positivePrefix="$"
                        isHidden
                      />
                      <IgrNumericColumn field="pmScheduleKey" isHidden/>
                      <IgrTextColumn field="pmScheduleID" headerText="PMScheduleID" width="*>150" />
                    </IgrDataGrid>
                  </Row>
                </FormGroup>
              </div>
  
              <div>
                <Modal
                  isOpen={this.state.modalWODetails}
                  toggle={() => this.toggleWODetails()}
                  // size={bigFormat ? "xl" : "med"}
                  className={bigFormat ? "custom-modal-workorder" : "med"}
                  backdrop={"static"}
                  // onClosed={() => this.getWOdata()}
                >
                  <ModalHeader
                    toggle={() => this.closeWODetailsForm()}
                    //className={this.getAFEHeaderColor(closeoutStatusDesc)}
                  >
                    {workOrderKey < 0 ? "New WO" : "WO No " + woNumber}
                  </ModalHeader>
                  <ModalBody>
                    <WORequest
                      toggle={this.closeWODetailsForm}
                      qrValue={qrValue}
                      user={this.props.user}
                      workOrderKey={workOrderKey}
                      facilityKey={facilityKey}
                      locationKey={locationKey}
                      facilityID={facilityID}
                      switchBool={switchBool}
                      fiixWOId={fiixWOId}
                      maintenanceTypeID={maintenanceTypeID}
                      refreshData={this.getArchiveWOdata}
                      empList={empList}
                      woNumber={woNumber}
                      fiixWOStatus={fiixWOStatus}
                    ></WORequest>
                  </ModalBody>
                </Modal>
              </div>
            </div>
          );
        }
        
    }

    // Function to calculate luminance of a color
 getLuminance = (color) => {
    const rgb = color.match(/\w\w/g).map((c) => parseInt(c, 16) / 255);
    const luminance = rgb.map((c) => {
        return c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4);
    }).reduce((a, b, i) => a + b * [0.2126, 0.7152, 0.0722][i], 0);
    return luminance;
  }
  
  // Define a function to get the color based on maintenance type
   getColorForMaintenanceType = (maintenanceType) => {
    switch (maintenanceType) {
        case 'Fleet (Truck)':
            return '#1B65C4'; // Blue
        case 'Other (Valves, Fittings etc.)':
            return '#CC4140'; // Red
        case 'Cameras':
            return '#A2E09D'; // Light Green
        case 'Safety (QHSE)':
            return '#FF9900'; // Orange
        case 'Pumps':
            return '#1E8A0E'; // Green
        case 'I&E':
            return '#D2CA4E'; // Yellow
        case 'Tower Comms':
            return '#967855'; // Brown
        case 'Pipeline':
            return '#053A78'; // Dark Blue
        case 'Measurement':
            return '#386161'; // Teal
        case 'SCADA':
            return '#D36E87'; // Pink
        case 'Materials Management':
            return '#363636'; // Dark Gray
        case 'Tanks':
            return '#B2F0F7'; // Light Blue
        case 'VFD':
            return '#FFFFFF'; // White
        case 'Lightning Inspection':
            return '#FFFBC2'; // Light Yellow
        case 'Valve':
            return '#8F3499'; // Purple
        case 'Heavy Equipment':
            return '#694A0D'; // Dark Brown
        default:
            return 'transparent'; // Default color
    }
  }

  onStatusCellUpdating = (s, e) => {
    const content = e.content;
    const info = e.cellInfo;
    const item = info.rowItem;
  
    let link;
    if (content.childElementCount === 0) {
        link = document.createElement("label");
        content.style.fontFamily = "Verdana";
        content.style.fontSize = "13px";
        content.style.margin = "0px -20px -10px -12px";
        content.style.padding = "0px 10px 10px 10px";
        content.appendChild(link);
    } else {
        link = content.children[0];
        content.style.background = "transparent";
        if (s.field !== 'reqestDateTime' && s.field !== "modifiedBy" && s.field !=="modifiedDateTime") {
            const backgroundColor = this.getColorForMaintenanceType(item.maintenanceType);
            content.style.background = backgroundColor;
            const luminance = this.getLuminance(backgroundColor);
            if (luminance > 0.5) {
                link.style.color = 'black'; // Dark text on light background
            } else {
                link.style.color = 'white'; // Light text on dark background
            }
        }
    }
  
    switch (s.field) {
        case 'woNumber':
            link.textContent = item.woNumber;
            break;
        case 'fiixWOStatus':
            link.textContent = item.fiixWOStatus;
            break;
        case 'assetName':
            link.textContent = item.assetName;
            break;
        case 'qrCodeValue':
            link.textContent = item.qrCodeValue;
            break;
        case 'facilityName':
            link.textContent = item.facilityName;
            break;
        case 'woPriority':
            link.textContent = item.woPriority;
            break;
        case 'maintenanceType':
            link.textContent = item.maintenanceType;
            break;
        case 'fiixIssueType':
            link.textContent = item.fiixIssueType;
            break;
        case 'workOrderDesc':
            link.textContent = item.workOrderDesc;
            break;
        case 'assetID':
            link.textContent = item.assetID;
            break;
        case 'createdByName':
            link.textContent = item.createdByName;
            break;
        case 'modifiedBy':
          link.textContent = item.modifiedBy
          ? item.modifiedBy.replace(".", " ")
          : "";
            break;    
        case 'modifiedDateTime':
      if (item.modifiedDateTime) {
          var date = new Date(item.modifiedDateTime);
          link.textContent = `${date.toLocaleString("en-US", {
              timeZone: "America/Chicago",
          })}`;
      } else {
          link.textContent = "";
      }
      break;       
      case 'reqestDateTime':
        if (item.reqestDateTime) {
            var date = new Date(item.reqestDateTime);
            link.textContent = `${date.toLocaleString("en-US", {
                timeZone: "America/Chicago",
            })}`;
        } else {
            link.textContent = "";
        } 
        break;
        case 'locationID':
            link.textContent = item.locationID;
            break;
        case 'assignedToUserName':
            link.textContent = item.assignedToUserName;
            break;
        case 'locationName':
            link.textContent = item.locationName;
            break;
        case 'workdaySiteID':
            link.textContent = item.workdaySiteID;
            break;
        case 'supplierID':
            link.textContent = item.supplierID;
            break;
        case 'estimateCost':
            link.textContent = item.estimateCost;
            break;
        default:
    }
  }

    // saveWorkbook() {
    //     const columns = this.grid.actualColumns;
      
    //     let visibleColumns = [];
    //     for (let i = 0; i < columns.count; i++) {
    //       let col = columns.item(i);
    //       if (!col.isHidden) {
    //         visibleColumns.push(col);
    //       }
    //     }
      
    //     const wb = new Workbook(WorkbookFormat.Excel2007);
    //     const ws = wb.worksheets().add("Sheet1");
      
    //     let excelColumnIndex = 0;
    //     visibleColumns.forEach(col => {
    //       ws.rows(0).cells(excelColumnIndex).value = col.headerText || col.field;
    //       excelColumnIndex++;
    //     });
      
    //     // Access the filtered data from the grid's data view
    //     const filteredData = this.grid.data.toArray(); // Ensure this retrieves the filtered data correctly
      
    //     if (!filteredData || filteredData.length === 0) {
    //       console.error("No filtered data available.");
    //       return;
    //     }
      
    //     filteredData.forEach((row, rowIndex) => {
    //       let excelColumnIndex = 0;
    //       visibleColumns.forEach(col => {
    //         ws.rows(rowIndex + 1).cells(excelColumnIndex).value = row[col.field];
    //         excelColumnIndex++;
    //       });
    //     });
      
    //     ExcelUtility.save(wb, "Inventory.xlsx");
    //   }


    saveWorkbook() {

      var actCols = this.grid.actualColumns
      let gridDts = this.grid.dataSource

      const headers = Object.keys(gridDts[0]);
      headers.pop();
      const wb = new Workbook(WorkbookFormat.Excel2007);
      const ws = wb.worksheets().add("Sheet1");

      for (let i = 0; i < headers.length; i++) {
          for (let j = 0; j < actCols.count; j++) {
              if (headers[i] == actCols.item(j).field) {
                  ws.rows(0).cells(j).value = actCols.item(j).headerText
                  if ((actCols.item(j).headerText || "") === "") {
                      ws.rows(0).cells(j).value = actCols.item(j).field
                  }
              }
          }

      }

      for (let i = 0; i < gridDts.length; i++) {
          const dataRow = gridDts[i];
          const xlRow = ws.rows(i + 1);
          for (let j = 0; j < headers.length; j++) {
              for (let k = 0; k < actCols.count; k++) {

                  if (headers[j] == actCols.item(k).field) {
                      xlRow.setCellValue(k, dataRow[headers[j]]);
                  }

              }

          }
      }

      ExcelUtility.save(wb, "WorkOrder");
  } 


}