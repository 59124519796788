import React, { Component, createRef, useState, forwardRef, useRef, useImperativeHandle } from 'react';
import { Form, Col, Row, Button, FormGroup, Label, Input, InputGroupText, InputGroup, CardGroup } from 'reactstrap';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import { Card, CardHeader, CardBody, CardTitle, CardFooter } from 'reactstrap'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'; 
import { TabContent, TabPane, Nav, NavItem, NavLink, FormFeedback, Alert } from 'reactstrap'; 
import '../../../src/custom.css' 
import { apiURL, apiHeader } from '../../resources/apiURL'; 
import ConfirmModal from '../ConfirmModal';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Plus from '../../../src/resources/plus.svg'
import Refresh from '../../../src/resources/refresh.svg'
import TrashBin from '../../../src/resources/trash.svg'
import Excel from '../../../src/resources/excel.svg'
import RevisionPng from '../../../src/resources/revise.png'
import Select from 'react-select';
import { getUserADAccount, getRandomInt, NumFormat } from '../../resources/utility'
import classnames from 'classnames'; 
import NumberFormat from 'react-number-format'; 
import { ColoredLine, FormatDate, getFirstDayofMonth, USDCurrencyFormat, IsDate } from '../../resources/utility';
import { CodeGeneratingComponentRendererDataEmitter, ThisYearExpressionDescription } from 'igniteui-react-core'; 
import { prop } from 'ramda';

var DatePicker = require("reactstrap-date-picker");
 
class ScheduledMaintenanceDetails extends React.Component {
    static displayName =  'Alex testing' //AFEDetail.name

    constructor(props) {
        super(props)

        this.onTextChange = this.onTextChange.bind(this);
        this.sendDataToParent = this.sendDataToParent.bind(this);

        this.state = {
            user: props.user, 
            isLoaded: false,
            activeTab: 1, 
            pmScheduleKey: props.pmScheduleKey,
            mainTypeList: props.mainTypeList,
            scheduleID: props.scheduleID,
            statusKey: props.statusKey,
            createdBy: props.createdBy,
            createdDate: props.createdDate,
            lastModifiedBy: props.lastModifiedBy,
            lastModifiedDate: props.lastModifiedDate,
            scheduleName: props.scheduleName,
            scheduleDescription: props.scheduleDescription,
            itemTypeKey: props.itemTypeKey,
            maintenanceTypeKey: props.maintenanceTypeKey,
            issueTypeList: props.issueTypeList,
            issueTypeKey: props.issueTypeKey,
            basinList: props.basinList,
            locationKey: props.locationKey,
            facilityList: props.facilityList,
            facilityKey: props.facilityKey,
            startDate: props.startDate,
            endDate: props.endDate,
            priorityList: props.priorityList,
            priority: props.priority,
            itemTypeList: props.itemTypeList,
            instructions:props.instructions,
            detailsModel: null,
            statusList: props.statusList,
            validateDataItem: props.validateDataItem,
            isSecurityAdminLogin: props.isSecurityAdminLogin,
            scheduleHasWorkOrders: false,
            assetMaintenanceIssueTypeMapping: props.assetMaintenanceIssueTypeMapping, 
        }   
    }

    toggleTab = tab => {
        const { activeTab } = this.state

        if (activeTab !== tab) {
            this.setState({ activeTab: tab })
        }
    }
    
    async componentDidMount() { 
        const {pmScheduleKey,scheduleID,statusKey,createdBy,createdDate,lastModifiedBy,lastModifiedDate,scheduleName,itemTypeKey,scheduleDescription,maintenanceTypeKey,issueTypeKey
            ,locationKey,facilityKey,startDate,endDate,priority,instructions } = this.state;
        let detailsModel=  {
                pmScheduleKey: pmScheduleKey,
                scheduleID: scheduleID,
                createdBy: createdBy,
                createdDate: createdDate,
                lastModifiedBy: lastModifiedBy,
                lastModifiedDate: lastModifiedDate,
                scheduleName:  scheduleName,
                scheduleDescription:  scheduleDescription,
                maintenanceTypeKey:  maintenanceTypeKey,
                itemTypeKey: itemTypeKey,
                issueTypeKey:  issueTypeKey,
                locationKey:  locationKey,
                facilityKey:  facilityKey,
                startDate:  startDate,
                endDate:  endDate,
                priority:  priority,
                instructions:  instructions,
                statusKey: statusKey,

           }
        await this.getPMScheduleWorkOrders();   

        this.setState({detailsModel: detailsModel,  isLoaded: true });
    }
   
    async getPMScheduleWorkOrders(){
        const {pmScheduleKey} = this.state;
    
        if (pmScheduleKey === undefined || pmScheduleKey === null || pmScheduleKey <= 0){
          return;
        }
     
        let myURI = apiURL + 'ScheduleMaintenance/PMScheduleAsset/' + pmScheduleKey + '/WorkOrders';
        
        await fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
     
            this.setState({ 
                scheduleHasWorkOrders: (json != null && json.length > 0 ? true : false )
            }) 
        });  
      }

    onTextChange = (fieldName,fieldValue,e)=>{ 
        let {detailsModel,empDetails,invalidDataItem,daysOfWeek,validateDataItem}=this.state; 
    
        switch(fieldName){
            case "scheduleName": 
                detailsModel.scheduleName = e.target.value;
                validateDataItem.scheduleName = true;
                break;
            case "scheduleDescription": 
                 detailsModel.scheduleDescription = e.target.value;
                break;
            case "maintenanceTypeKey":  
                 detailsModel.maintenanceTypeKey = e === undefined || e === null ? null : e.value;
                 validateDataItem.maintenanceTypeKey = true;
                break;
            case "issueTypeKey": 
                 detailsModel.issueTypeKey = e.value;
                break;     
            case "locationKey": 
                 detailsModel.locationKey = (e === undefined || e === null ? null : e.locationKey);
                 detailsModel.facilityKey = null;
                 validateDataItem.locationKey = true;
                break;
            case "facilityKey": 
                 detailsModel.facilityKey = (e === undefined || e === null ? null : e.facilityKey);
                break;
            case "startDate": 
                 detailsModel.startDate = fieldValue;
                 validateDataItem.startDate = true;
                break;
            case "endDate": 
                detailsModel.endDate = fieldValue;
                validateDataItem.endDate = true;
                break;
            case "priority": 
                 detailsModel.priority = (e === undefined || e === null || e.value === undefined || e.value === null ? 0 : e.value);
                 validateDataItem.priority = true;
                break;
            case "itemTypeKey":
                detailsModel.itemTypeKey = (e === undefined || e === null || e.value === undefined || e.value === null ? 0 : e.value);
                detailsModel.maintenanceTypeKey = 0;
                detailsModel.issueTypeKey = 0;
                validateDataItem.itemTypeKey = true;
                break;
            case "instructions":
                //this.setState({instructions: e.target.value});
                detailsModel.instructions = e.target.value
                break;
            case "statusKey":
                detailsModel.statusKey = (e === undefined || e === null || e.value === undefined || e.value === null ? 0 : e.value);
                break;
            default:
        }
        this.setState({detailsModel: detailsModel,validateDataItem: validateDataItem});

        this.sendDataToParent(detailsModel,validateDataItem)
    }

    sendDataToParent(detailsModel,validateDataItem){  
        this.props.sendDataToParentFromDetails(detailsModel,validateDataItem); 
    }

    getSelMainType = (value) => {
        const { mainTypeList } = this.state
    
        if (value !== null && value !== "") {
          return mainTypeList.filter(e => e.value === value)
        }
        return ""
    }

    getSelIssueType = (value) => {
        const { issueTypeList } = this.state
         
        if (value !== null && value !== "") { 
          return issueTypeList.filter(e => e.value === value)
        }
        else{
          return ""
        }
    
    }

    getSelBasin = (value) => {
        const { basinList } = this.state
    
        if (value !== null && value !== "") {
          return basinList.filter(e => e.locationKey === value)
        }
        return ""
    }

    getSelFacility = (value) => {
        const { facilityList } = this.state
        // console.log(value)
        if (value !== null && value !== "") {
          // console.log("facilityListFilter hit")
          return facilityList.filter(e => e.facilityKey === value)
        }
        return ""
    }

    getSelPriority = (value) => {
        const { priorityList } = this.state
    
        if (value !== null && value !== "") {
          return priorityList.filter(e => e.value === value)
        }
        return ""
    }

    getItemType = (value) => {
        const { itemTypeList } = this.state
    
        if (value !== null && value !== "") {
          return itemTypeList.filter(e => e.itemTypeKey === value)
        }
        return ""
    }


    getStatus = (value) => {
        const { statusList } = this.state
    
        if (value !== null && value !== "") {
          return statusList.filter(e => e.value === value)
        }
        return ""
    }

    render() {
         const { isLoaded,detailsModel,scheduleHasWorkOrders,mainTypeList,scheduleName,scheduleDescription,maintenanceTypeKey,issueTypeList
            ,isSecurityAdminLogin,basinList,locationKey,facilityList,facilityKey,startDate,priorityList
            ,priority,instructions,itemTypeList,statusList,validateDataItem,assetMaintenanceIssueTypeMapping} = this.state
 
        if (!isLoaded) {
            return (<div>Loading Details .....</div>)
        } 
        else{
            
            let filterdFacilityList = [];
            if (detailsModel !== undefined && detailsModel != null && detailsModel.locationKey !== undefined &&  detailsModel.locationKey !== null && detailsModel.locationKey > 0){
                filterdFacilityList = facilityList.filter(x => x.locationKey == detailsModel.locationKey);
            }
            
            let filteredMainTypeList = [];
            let filteredIssueTypeList = [];

            if (detailsModel.itemTypeKey != null && detailsModel.itemTypeKey > 0){
                
                let obj = assetMaintenanceIssueTypeMapping.filter(x => x.itemTypeKey == detailsModel.itemTypeKey);

                filteredMainTypeList = mainTypeList.filter(x => x.value == obj[0].maintenanceTypeKey);
                filteredIssueTypeList = issueTypeList.filter(x => x.value == obj[0].issueTypeKey)

                
            }


            return (
                <div> 
                    <Form > 
 
                        <Row form style={{ marginLeft: "5px", marginTop: "10px", marginBottom: "5px" }}>
                            <Col md={6}>
                                <Row form hidden = {detailsModel.pmScheduleKey == undefined || detailsModel.pmScheduleKey == null || detailsModel.pmScheduleKey <= 0}>
                                    <Col md={3}>
                                        <Label for="afeNo">Work Order Scedule ID</Label>
                                    </Col>
                                    <Col md={9}>
                                        <Input 
                                            disabled 
                                            type="text" 
                                            name="constructionReportID"
                                            id="constructionReportID" placeholder=""
                                            value={detailsModel == undefined || detailsModel == null  ? null : detailsModel.scheduleID} //this.getProjectName(constructionReportDTO.afeHeaderKey)}
                                        /> 
                                    </Col> 
                                </Row>
                            </Col>
                            <Col md={6}>
                                <Row form hidden = {detailsModel.pmScheduleKey == undefined || detailsModel.pmScheduleKey == null || detailsModel.pmScheduleKey <= 0}>
                                    <Col md={3}>
                                        <Label for="afeNo">Status</Label>
                                    </Col>
                                    <Col md={9}>
                                        <Select 
                                            options={statusList} 
                                            //isDisabled={this.isFormReadOnly() || statusKey === 4}
                                            //className={(!validateDataItem.afeSponsor ? "form-error" : "")}
                                            //classsname={""}
                                            value={this.getStatus(detailsModel.statusKey)}
                                            isDisabled={!isSecurityAdminLogin}
                                            onChange={(e) => this.onTextChange("statusKey", e, e)}
                                            isClearable={true} >
                                        </Select> 
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row form style={{ marginLeft: "5px", marginTop: "10px", marginBottom: "5px" }}
                            hidden = {detailsModel.pmScheduleKey == undefined || detailsModel.pmScheduleKey == null || detailsModel.pmScheduleKey <= 0}>
                            <Col md={6}>
                                <Row form>
                                    <Col md={3}>
                                        <Label for="afeNo">Created By</Label>
                                    </Col>
                                    <Col md={9}>
                                        <Input 
                                            disabled 
                                            type="text" 
                                            name="constructionReportID"
                                            id="constructionReportID" placeholder=""
                                            value={detailsModel == undefined || detailsModel == null  ? null : detailsModel.createdBy} //this.getProjectName(constructionReportDTO.afeHeaderKey)}
                                        /> 
                                    </Col> 
                                </Row>
                            </Col>
                            <Col md={6}>
                                <Row form hidden = {detailsModel.pmScheduleKey == undefined || detailsModel.pmScheduleKey == null || detailsModel.pmScheduleKey <= 0}>
                                    <Col md={3}>
                                        <Label for="afeNo">Created Date</Label>
                                    </Col>
                                    <Col md={9}>
                                        <Input 
                                            disabled 
                                            type="text" 
                                            name="constructionReportID"
                                            id="constructionReportID" placeholder=""
                                            value={detailsModel == undefined || detailsModel == null  ? null : FormatDate(detailsModel.createdDate)} //this.getProjectName(constructionReportDTO.afeHeaderKey)}
                                        /> 
                                    </Col> 
                                </Row>
                            </Col>
                        </Row>
                        <Row form style={{ marginLeft: "5px", marginTop: "10px", marginBottom: "5px" }}
                            hidden = {detailsModel.pmScheduleKey == undefined || detailsModel.pmScheduleKey == null || detailsModel.pmScheduleKey <= 0}>
                            <Col md={6}>
                                <Row form>
                                    <Col md={3}>
                                        <Label for="afeNo">Modified By</Label>
                                    </Col>
                                    <Col md={9}>
                                        <Input 
                                            disabled 
                                            type="text" 
                                            name="constructionReportID"
                                            id="constructionReportID" placeholder=""
                                            value={detailsModel == undefined || detailsModel == null  ? null : detailsModel.lastModifiedBy} //this.getProjectName(constructionReportDTO.afeHeaderKey)}
                                        /> 
                                    </Col> 
                                </Row>
                            </Col>
                            <Col md={6}>
                                <Row form>
                                    <Col md={3}>
                                        <Label for="afeNo">Modified Date</Label>
                                    </Col>
                                    <Col md={9}>
                                        <Input 
                                            disabled 
                                            type="text" 
                                            name="constructionReportID"
                                            id="constructionReportID" placeholder=""
                                            value={detailsModel == undefined || detailsModel == null  ? null : FormatDate(detailsModel.lastModifiedDate)}
                                        /> 
                                    </Col> 
                                </Row>
                            </Col>
                        </Row>
                        <Row form style={{ marginLeft: "5px", marginTop: "10px", marginBottom: "5px" }}>
                            <Col md={6}>
                                <Row form>
                                    <Col md={3}>
                                        <Label for="afeNo">Schedule Name</Label>
                                    </Col>
                                    <Col md={9}>
                                        <Input 
                                            type="text"
                                            name="scheduleName"
                                            //rows={1}
                                            className={(!validateDataItem.scheduleName ? "form-error" : "")}
                                            //className={""}
                                            disabled={!isSecurityAdminLogin || detailsModel.statusKey <= 0}
                                            id="scheduleName" placeholder=""
                                            value={detailsModel.scheduleName == undefined || detailsModel.scheduleName == null ? '' : detailsModel.scheduleName}
                                            //defaultValue={afeData[0].afeDescription}
                                            onChange={(e) => this.onTextChange("scheduleName", e.target.value, e)}
                                        />  
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={6}>
                                <Row form>
                                    <Col md={3}>
                                        <Label for="afeNo">Description</Label>
                                    </Col>
                                    <Col md={9}>
                                        <Input 
                                            type="text"
                                            name="scheduleDescription"
                                            //rows={1}
                                            // className={(!validateDataItem.workSummary ? "form-error" : "")}
                                            className={""}
                                            disabled={!isSecurityAdminLogin || detailsModel.statusKey <= 0}
                                            id="scheduleDescription" placeholder=""
                                            value={detailsModel.scheduleDescription == undefined || detailsModel.scheduleDescription == null ? '' : detailsModel.scheduleDescription}
                                            //defaultValue={afeData[0].afeDescription}
                                            onChange={(e) => this.onTextChange("scheduleDescription", e.target.value, e)}
                                        />  
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row form style={{ marginLeft: "5px", marginTop: "10px", marginBottom: "5px" }}>
                            <Col md={6}>
                                <Row form>
                                    <Col md={3}>
                                        <Label for="afeNo">Asset Type</Label>
                                    </Col>
                                    <Col md={9}>
                                        <Select 
                                            options={itemTypeList} 
                                            isDisabled={!isSecurityAdminLogin || scheduleHasWorkOrders}
                                            className={(!validateDataItem.itemTypeKey ? "form-error" : "")}
                                            //classsname={""}
                                            value={this.getItemType(detailsModel.itemTypeKey)}
                                            //defaultValue={this.getSelEmp(afeData[0].afeSponsor)}
                                            onChange={(e) => this.onTextChange("itemTypeKey", e, e)}
                                            isClearable={true} >
                                        </Select> 
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={6}>
                                <Row form>
                                    <Col md={3}>
                                        <Label for="afeNo">Priority</Label>  
                                    </Col>
                                    <Col md={9}>
                                        <Select
                                            options={priorityList}
                                            className={!validateDataItem.priority ? "form-error" : ""}
                                            value={this.getSelPriority(detailsModel.priority)}
                                            isDisabled={!isSecurityAdminLogin || scheduleHasWorkOrders}
                                            onChange={(e) => this.onTextChange("priority", e, e)}
                                            isClearable={true}
                                            >
                                        </Select>
                                    </Col> 
                                </Row>
                            </Col>
                        </Row>
                        <Row form style={{ marginLeft: "5px", marginTop: "10px", marginBottom: "5px" }}>
                            <Col md={6}>
                                <Row form>
                                    <Col md={3}>
                                        <Label for="afeNo">Maintenance Type</Label>
                                    </Col>
                                    <Col md={9}>
                                        <Select 
                                            //options={mainTypeList} 
                                            options={filteredMainTypeList}
                                            isDisabled={!isSecurityAdminLogin || scheduleHasWorkOrders}
                                            className={(!validateDataItem.maintenanceTypeKey ? "form-error" : "")} 
                                            value={this.getSelMainType(detailsModel.maintenanceTypeKey)}
                                            //defaultValue={this.getSelEmp(afeData[0].afeSponsor)}
                                            onChange={(e) => this.onTextChange("maintenanceTypeKey", e, e)}
                                            isClearable={true} >
                                        </Select> 
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={6}>
                                <Row form>
                                    <Col md={3}>
                                        <Label for="afeNo">Issue Type</Label>
                                    </Col>
                                    <Col md={9}>
                                        <Select
                                            //options={issueTypeList}
                                            options={filteredIssueTypeList}
                                            isDisabled={!isSecurityAdminLogin || scheduleHasWorkOrders}
                                            className={(!validateDataItem.issueTypeKey ? "form-error" : "")} 
                                            value={this.getSelIssueType(detailsModel.issueTypeKey)}
                                            placeholder={""}
                                            onChange={(e) => this.onTextChange("issueTypeKey", e, e)}
                                            isClearable={true}
                                        ></Select> 
                                    </Col> 
                                </Row>
                            </Col>
                        </Row>
                        <Row form style={{ marginLeft: "5px", marginTop: "10px", marginBottom: "5px" }}>
                            <Col md={6}>
                                <Row form>
                                    <Col md={3}>
                                        <Label for="afeNo">Basin</Label>
                                    </Col>
                                    <Col md={9}>
                                        <Select
                                            options={basinList}
                                            isDisabled={!isSecurityAdminLogin || scheduleHasWorkOrders}
                                            className={(!validateDataItem.locationKey ? "form-error" : "")} 
                                            value={this.getSelBasin(detailsModel.locationKey)}
                                            onChange={(e) => this.onTextChange("locationKey", e, e)}
                                            placeholder={""}
                                            isClearable={true}
                                            >
                                        </Select> 
                                    </Col> 
                                </Row>
                            </Col>
                            <Col md={6}>
                                <Row form>
                                    <Col md={3}>
                                        <Label for="afeNo">Facility</Label>
                                    </Col>
                                    <Col md={9}>
                                        <Select 
                                            options={filterdFacilityList}
                                            isDisabled={!isSecurityAdminLogin || scheduleHasWorkOrders} 
                                            // className={
                                            //     !validDataItem.facilityID
                                            //     ? "alway-on-top-more form-error"
                                            //     : ""
                                            // }          
                                            value={this.getSelFacility(detailsModel.facilityKey)}
                                            onChange={(e) => this.onTextChange("facilityKey", e, e)}
                                            placeholder={""}
                                            isClearable={true}
                                            >
                                        </Select>
                                    </Col> 
                                </Row>
                            </Col>
                        </Row>
                        <Row form style={{ marginLeft: "5px", marginTop: "10px", marginBottom: "5px" }}>
                            <Col md={6}>
                                <Row form>
                                    <Col md={3}>
                                        <Label for="afeNo">Start Date</Label>
                                    </Col>
                                    <Col md={9}>
                                        <DatePicker 
                                            value={detailsModel.startDate}
                                            className={(!validateDataItem.startDate ? "form-error" : "")} 
                                            onChange={(v, f) => this.onTextChange("startDate", v, f)}
                                            disabled={!isSecurityAdminLogin || detailsModel.statusKey <= 0}
                                        /> 
                                    </Col> 
                                </Row>
                            </Col>
                            <Col md={6}>
                                <Row form>
                                    <Col md={3}>
                                        <Label for="afeNo">End Date</Label>
                                    </Col>
                                    <Col md={9}>
                                        <DatePicker 
                                            value={detailsModel.endDate}
                                            className={(!validateDataItem.endDate ? "form-error" : "")}  
                                            onChange={(v, f) => this.onTextChange("endDate", v, f)}
                                            disabled={!isSecurityAdminLogin || detailsModel.statusKey <= 0}
                                        /> 
                                    </Col> 
                                </Row>
                            </Col>
                        </Row> 
                        <Row form style={{ marginLeft: "5px", marginTop: "10px", marginBottom: "5px" }}>
                            <Col md={12}>
                                <Row form>
                                    <Col md={2}>
                                        <Label for="afeNo">Instructions</Label>
                                    </Col> 
                                </Row>
                                <Row form>
                                    <Col md={12}>
                                        <Input 
                                            type="textarea" 
                                            name="afeDescription"
                                            rows={5}
                                            // className={(!validateDataItem.workSummary ? "form-error" : "")}
                                            className={""}
                                            disabled={!isSecurityAdminLogin || detailsModel.statusKey <= 0}
                                            id="summary" placeholder=""
                                            value={detailsModel.instructions == undefined || detailsModel.instructions == null ? '' : detailsModel.instructions}
                                            //defaultValue={afeData[0].afeDescription}
                                            onChange={(e) => this.onTextChange("instructions", e.target.value, e)}
                                        /> 
                                    </Col>
                                </Row>
                            </Col> 
                        </Row>
                    </Form>   
                </div>
            )
        }
            
    }

}

export default ScheduledMaintenanceDetails
