import React, { Component } from 'react';
import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiDELETEHeader } from '../../resources/apiURL';
import MenuIcon from '../../resources/menu.png';
import Plus from '../../resources/plus.svg'
import Refresh from '../../resources/refresh.svg'
import Revise from '../../resources/revise.png'
import Excel from '../../resources/excel.svg'
import spade from '../../resources/spade.png'
import '../../custom.css';
import { ExcelUtility } from '../../operation/ExcelUtility';
import Select from 'react-select';
import { getUserADAccount, getRandomInt } from '../../resources/utility'

import { Col, Row, Button, FormGroup, Label } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter ,Alert} from 'reactstrap';
import { Input, CustomInput, InputGroupText } from 'reactstrap';

import { IgrDataGrid, IgrTextColumn  } from 'igniteui-react-grids';
import { IgrRowExpansionTemplateContext } from 'igniteui-react-grids';
import { FilterFactory } from 'igniteui-react-core';

import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';

import { IgrTemplateColumn } from 'igniteui-react-grids';
import { ColoredLine, getFirstDayofMonth } from '../../resources/utility';
import WORequest from './WORequest'
import ScheduledMaintenanceMetaData from './ScheduledMaintenanceMetaData';

import { QrReader } from 'react-qr-reader';

import PowerBIReportViewer from '../../resources/PowerBIReportViewer'

import clearfilter from '../../components/img/clearfilter.png'

import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  DetailRow,
  Inject,
  Sort,
  Filter,
  RowSelectEventArgs,
  Selection,
  ExcelExport
} from '@syncfusion/ej2-react-grids';
 
IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();



export default class ScheduledMaintenance extends Component {
    static displayName = ScheduledMaintenance.name;

    constructor(props) {
        super(props);
        document.title = "WaterBridge Portal - Workorder Request";
        this.saveWorkbook = this.saveWorkbook.bind(this);
        this.searchInput = React.createRef();  /*Added by BN 6/14/24 Fixes issue where search bar is not focused */
        this.updateGridHeight = this.updateGridHeight.bind(this);
        this.rowExpansionTemplate = this.rowExpansionTemplate.bind(this);
        this.gridRef = React.createRef();
        this.rowSelected = this.rowSelected.bind(this);
        this.handleGridCreated = this.handleGridCreated.bind(this);
        this.created = this.created.bind(this);
        this.getItemTypeRecord = this.getItemTypeRecord.bind(this);
        this.refreshGrid = this.refreshGrid.bind(this);

        const urlPara = this.getURLParameters(props.location)
        // console.log(urlPara)
        this.state = {
            workOrderKey: urlPara.workOrderKey === null ? -1 : urlPara.workOrderKey,
            openWODetails: !(urlPara.workOrderKey === null),
            woNumber: '',
            WOData: [],
            isDataLoaded: false,
            qrValue: "",
            startDate: getFirstDayofMonth().toISOString(),
            endDate: new Date().toISOString(),
            windowHeight: window.innerHeight, 
            modalScheduleDetail: false,
            expandedRowIndex: null,
            isAssetListLoaded: false,
            assetList: [],
            facilityKey: null,
            locationKey: null,
            facilityID: null,
            isSecurityLoaded: false,
            security: [],
            isSecurityAdminLogin: false,
            isBasinLoaded: false,
            basinList: [],
            selBasin: [],
            isStatusLoaded: false,
            statusList: [],
            selStatus: [],
            mainTypeList: [],
            isMainTypeListLoaded: false,
            selMainType: [],
            fullMaterialRequestData: [],
            searchText: "",
            fiixWOId: null,
            switchBool: null,
            fiixWOStatus: "",
            modalReport: false,
            isMainTypeSelected: true,
            maintenanceTypeID: null,
            isEmpListLoaded: false,
            empList: [],
            mtlRequestQueryList: [
                {
                  "label": "All Schedules",
                  "value": 1
                }, 
                {
                  "label": "My Schedules",
                  "value": 2
                }
          ],
            selMtlRequestQuery: [],
            bigFormat: false,
            lastSelectedKey: null,
            isTyping:false,
            isFetchingWOData: false, // Add this flag
            gridHeight: window.innerHeight * 0.65,
            user:props.user,
            pmScheduleKey: 0,
            itemTypeList: [],
            itemTypeKey: 0,
            isItemTypeListLoaded: false,
            loadingGrid: false,
            filterKey: 1,
            fixxMainTypeKey: null,
            gridSearch: '',
            newScheduleCreated: false,
            showRunSchedule: false,
        }
        
    }

    getURLParameters = (inputPara) => {

        var paraMeters = {
            workOrderKey: null
        }
        var query
        if (inputPara !== undefined) {
            query = new URLSearchParams(inputPara.search);

            paraMeters = {
                workOrderKey: query.get('key')
            }

        }
        
        return paraMeters 
    }

    toggleQRCodeScanner = () => {
        this.setState({ modalQRCodeScanner: !this.state.modalQRCodeScanner })
    }

    setQRCodeValue = (qrValue) => {
        const { assetList } = this.state
        var test = assetList.filter(a => a.qrCodeValue == qrValue)
        var test2 = test[0].facilityKey
        var test3 = test[0].locationKey
        var test4 = test[0].facilityID
        this.setState({
            qrValue: qrValue,
            facilityKey: test2,
            locationKey: test3,
            bigFormat: false,
            facilityID: test4,
            switchBool: -1,
            modalQRCodeScanner: !this.state.modalQRCodeScanner,
            modalWODetails: !this.state.modalWODetails,
            isTyping: false
        })
    }
    getAssetList = () => {

        let myURI = apiURL + 'ItemMaster/FacilityInventoryCollector2'
        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                this.setState({
                    isAssetListLoaded: true,
                    assetList: this.createAssetList(json)
                })

            });
    }

    createAssetList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.facilityInvCollectorKey
            e.label = e.qrCodeValue
            tempList.push(e)
        })

        return tempList
    }
 
    getWOdata = () => {

        if (this.state.isFetchingWOData) return;  // Prevent multiple calls
        this.setState({ isFetchingWOData: true });  // Set the flag
        let myURI = apiURL + 'Fiix/WorkOrder/' + 'AzureAD/' + this.props.user.account.userName;

        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    isDataLoaded: true,
                    fullMaterialRequestData: json,
                    searchText: "",
                    isTyping: false,
                })
                // console.log(json)
                this.getDefaultMtlRequestList(json)
                this.openWODetailsFromKey(json)
            });


    }
  
    openWODetailsFromKey = (woList) => {
        const { openWODetails, workOrderKey } = this.state;
         
        if (openWODetails) { 
            const selMOC = woList.filter(a => parseInt(a.workOrderKey) === parseInt(workOrderKey)); 
            if (selMOC.length > 0) {

                this.setState({
                    woNumber: selMOC[0].woNumber, 
                    bigFormat: true
                });


            }
            else {
                this.setState({
                    bigFormat: true
                })
            }


            this.toggleWODetails();



        }


    }

    getWODataByStatus = (woStatus) => {
      let myURI = apiURL + 'Fiix/WorkOrder/startDate/' +
          this.state.startDate.toString().substring(0, 10) +
          '/enddate/' + this.state.endDate.toString().substring(0, 10)
          + '/AzureAD/' + this.props.user.account.userName
          + '/FiixStatus/' + woStatus;
  
      return fetch(myURI, { modes: 'no-cors' })
          .then(res => res.json())
          .then(json => { 
              return json;
          });
  };


    getSecurity = () => {
        let myURI = apiURL + 'ScheduleMaintenance/ScheduleMaintenanceUserPermission/' + this.props.user.account.userName
        const { security, appSecurityNo, meterVal } = this.state
        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => { 
                this.setState({

                    isSecurityLoaded: true, 
                    isSecurityAdminLogin: (json !== undefined && json !== null && json.isAdmin ? true : false), 
                })

            })

    }
    setFocusToSearchInput = () => {  /*Added by BN 6/14/24 Fixes issue where search bar is not focused */
        if (this.state.isTyping) {
          if (this.searchInput.current) {
            this.searchInput.current.focus();
          }
        }
      };
      
    onTextChange2 = async (fieldName, fieldValue, e) => {
        const { fullMaterialRequestData } = this.state;
    
        if (fieldName === "searchText") {
            let searchText = fieldValue.toLowerCase();
    
            if (searchText !== this.state.searchText) {
                this.setState({ searchText: searchText });
            }
    
            // Apply dropdown filters first
            let filteredData = await this.getFilteredData(fullMaterialRequestData);
    
            // If the search text is not empty, apply the search filter on top of the filtered data
            if (searchText !== '') {
                filteredData = filteredData.filter(item => {
                    return this.isItemMatchingSearchText(item, searchText);
                });
            }
    
            this.setState({ WOData: filteredData, searchText, isTyping: true });
        }
    };
      
      isItemMatchingSearchText = (item, searchText) => {
        const columnsToFilter = [
          "woNumber", "fiixWOStatus", "assetName", "qrCodeValue", "facilityName", 
          "woPriority", "maintenanceType", "fiixIssueType", "workOrderDesc", 
          "assetID", "createdByName", "reqestDateTime", "assignedToUserName", 
          "locationID", "locationName", "workdaySiteID", "estimateCost"
        ];
      
        return columnsToFilter.some(column => {
          if (item[column] && item[column].toString().toLowerCase().includes(searchText)) {
            return true;
          }
          return false;
        });
      };

      
    getFilteredData = async (data) => {
        const { selMtlRequestQuery, selBasin, selStatus, selMainType } = this.state;
    
        let filteredData = data;
    
        if (selMtlRequestQuery || (selBasin && selBasin.length > 0) || (selStatus && selStatus.length > 0) || ((selMainType && selMainType.length > 0))) {
            filteredData = await this.getFilterMtlRequest(filteredData, selMtlRequestQuery, selBasin, selStatus, selMainType);
        }
    
        return filteredData;
    };


    onTextChange = (fieldName, fieldValue, e) => {
        const { fullMaterialRequestData } = this.state;
    
        switch (fieldName) {
            case "itemTypeKey":
                if (e !== null){ 
                  this.setState({itemTypeKey:e.itemTypeKey});
                }
                else{
                  this.setState({itemTypeKey:null});
                }
                break;
            case "filterKey":
              if (e !== null){ 
                this.setState({filterKey:e.value});
              }
              else{
                this.setState({filterKey:null});
              }
              break;
            case "locationKey":  
                if (e !== null){ 
                  this.setState({locationKey:e.locationKey});
                }
                else{
                  this.setState({locationKey:null});
                } 
                break;
            case "status":
              this.setState({ selStatus: e }, async () => {
                  let filteredData = await this.getFilteredData(fullMaterialRequestData);
                  this.setState({ WOData: filteredData });
              });
              break;
            case "fixxMainTypeKey": 
              if (e !== null){ 
                this.setState({fixxMainTypeKey: e.value})
              }
              else{
                this.setState({fixxMainTypeKey:null});
              }
              break;
            case "gridSearch":
              this.setState({gridSearch: fieldValue})
              break;
            default:
                break;
        }
    };

//Modified by BN 6/17/24. Fixed issue where My Work Order/All work order, Basin, and Status drop down was not filtered with search bar
    getFilterMtlRequest = async (data, m, b, s, z) => {
        const { user } = this.props;
        // console.log(z)
        let tempMtlRequest = data;
    
        const basins = b ? b.map(e => e.locationName).join(",") : "";
        const status = s ? s.map(e => e.fiixWOStatus).join(",") : "";
        const maintenance = z ? z.map(e => e.fixxMainTypeDesc).join(",") : "";
    
        // Fetch additional data for specific statuses
        if (status.length > 0) {
            const specificStatuses = ["Canceled", "Closed", "Deleted", "Completed"];
            const statusArray = status.split(",");
            
            for (let e of statusArray) {
                if (specificStatuses.includes(e)) {
                    const additionalData = await this.getWODataByStatus(e);
                    // console.log("finished")
                    tempMtlRequest = [...tempMtlRequest, ...additionalData];
                }
            }
        }
    
        // Filter by material request query
        if (m !== null) {
            const userName = getUserADAccount(user.account.userName).toLowerCase();
    
            if (m.value === 1) {
                tempMtlRequest = tempMtlRequest.filter(e => 
                    (e.createdBy || "").toLowerCase() === userName ||
                    (e.approvers || "").toLowerCase().includes(userName) ||
                    (e.assignedToADAccount || "").toLowerCase().includes(userName)
                );
            }else if (m.value === 3) { // Filter for Unassigned Work Orders
              tempMtlRequest = tempMtlRequest.filter(e => 
                  (e.fiixAssignedToUser || "").toLowerCase() === "" ||
                  e.fiixAssignedToUser === null
              );
          }
        }
        // Filter by maintenance

        if (maintenance.length > 0) {
          tempMtlRequest = tempMtlRequest.filter(e => maintenance.includes(e.maintenanceType));
      }
    
        // Filter by status
        if (status.length > 0) {
            tempMtlRequest = tempMtlRequest.filter(e => status.includes(e.fiixWOStatus));
        }
    
        // Filter by basin
        if (basins.length > 0) {
            tempMtlRequest = tempMtlRequest.filter(e => basins.includes(e.locationName));
        }
    
        return tempMtlRequest;
    };



    getDefaultMtlRequestList = async (data) => {
        const filteredData = await this.getFilterMtlRequest(data, this.state.selMtlRequestQuery, this.state.selBasin, this.state.selStatus, this.state.selMainType);
        // console.log('Filtered data:', filteredData);
        this.setState({
            WOData: filteredData // Set the filtered data to the WOData
        });
    };


    // setDefaultMtlRequestQuery = () => {
    //     const { isSecurityAdminLogin } = this.state;
    //     if (isSecurityAdminLogin === false) {
    //         this.setState({ selMtlRequestQuery: this.state.mtlRequestQueryList[1] }, async () => {
    //             await this.getWOdata();
    //             let filteredData = await this.getFilteredData(this.state.fullMaterialRequestData);
    //             this.setState({ WOData: filteredData }, () => {
    //                 this.openWODetailsFromKey(filteredData);
    //             });
    //         });
    //     } else {
    //         this.setState({ selMtlRequestQuery: this.state.mtlRequestQueryList[0] }, async () => {
    //             await this.getWOdata();
    //             let filteredData = await this.getFilteredData(this.state.fullMaterialRequestData);
    //             this.setState({ WOData: filteredData }, () => {
    //                 this.openWODetailsFromKey(filteredData);
    //             });
    //         });
    //     }
    // }
    setDefaultMtlRequestQuery = () => {
        const { isSecurityAdminLogin } = this.state
        if (isSecurityAdminLogin === false) {
            this.setState({ selMtlRequestQuery: this.state.mtlRequestQueryList[1] }, () => {
                this.getWOdata()
            })
        }
        else {
            this.setState({ selMtlRequestQuery: this.state.mtlRequestQueryList[0] }, () => {
                this.getWOdata()
            })
        }

    }

    handleChange(value, formattedValue) {
        this.setState({
            startDate: value,
        });
    }

    handleEndDateChange(value, formattedValue) {
        this.setState({
            endDate: value,
        });

    }

    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.WOData;
        
    }
   
    toggleEdit = () => {
        this.setState({
            modalWODetails: !this.state.modalWODetails,
            isTyping: false
        })

    }

     

    componentDidMount = async() => {
        //this.getAssetList();
        this.getSecurity();
        await this.getBasin();
        await this.getItemType();
        // this.getStatus();
        // this.getEmpList();
        await this.getMainType();

        await this.getSchedules(); 
        this.setState({isDataLoaded:true,loadingGrid: false});
    }
    
    componentWillUnmount() {
      window.removeEventListener('resize', this.updateGridHeight);
        document.removeEventListener('click', this.handleClickOutside);
    }

    getSchedules = async() => {

      let myURI = apiURL + 'ScheduleMaintenance/PMSchedules'
      await fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
        .then(res => res.json())
        .then(json => { 
          this.setState({WOData: json});
      });
  
      this.setState({loadingGrid: false});
    }

    async refreshGrid(){
      this.setState({loadingGrid: true});
      await this.getSchedules();
    }

    handleClickOutside = (event) => {  /*Added by BN 6/14/24 Fixes issue where search bar is not focused */
        // Ensure that this.searchInput.current is not null
        if (this.searchInput.current && !this.searchInput.current.contains(event.target)) {
          // Clicked outside the search input, so set isTyping to false
          this.setState({ isTyping: false });
        }
    }; 

    getEmpList = () => {
        let myURI = apiURL + "EmployeeDetails";


        fetch(myURI, { modes: "no-cors" })
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    empList: this.createEmpList(json),
                    isEmpListLoaded: true,
                });
            });
    };

    createEmpList = (value) => {
        var tempList = [];
        value.map((e) => {
            e.value = e.employeeKey;
            e.label = e.empName;
            tempList.push(e);
        });

        return tempList;
    };


    getBasin = () => {
        let myURI = apiURL + 'Fiix/GetBasin'


        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    basinList: this.createBasinList(json),
                    isBasinLoaded: true
                })
            });
    }

    getItemType = async() => {

      let myURI = apiURL + 'ScheduleMaintenance/ItemType'
      await fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
          .then(res => res.json())
          .then(json => {

          this.setState({
              isItemTypeListLoaded: true,
              itemTypeList: this.createItemTypeList(json)
              //itemTypeList: json,
          }) 
      }); 
    }

    getItemTypeRecord = (value) => {
      const { itemTypeList } = this.state
  
      if (value !== null && value !== "") {
        return itemTypeList.filter(e => e.itemTypeKey === value)
      }
      return ""
    }

    getStatus = () => {
        let myURI = apiURL + 'Fiix/Status'

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    statusList: this.createStatusList(json),
                    isStatusLoaded: true
                })
                // console.log(json)
            });
    }
    getMainType = async () => {

      let myURI = apiURL + 'ScheduleMaintenance/MainType'
      await fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
        .then(res => res.json())
        .then(json => {
  
          this.setState({
            isMainTypeListLoaded: true,
            mainTypeList: this.createMainTypeList(json)
          })
          // console.log(json)
        });
  
    }
    createBasinList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.locationKey
            e.label = e.strName
            tempList.push(e)
        })

        return tempList
    }

    createItemTypeList = (value) => {
      var tempList = []
      value.map(e => {
        e.value = e.itemTypeKey
        e.label = e.itemTypeDesc
        tempList.push(e)
      })
  
      return tempList
    }

    createStatusList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.fiixWOStatus
            e.label = e.fiixWOStatus
            tempList.push(e)
        })

        return tempList
    }

    createMainTypeList = (value) => {
      var tempList = []
      value.map(e => {
        e.value = e.fixxMainTypeKey
        e.label = e.fixxMainTypeDesc
        tempList.push(e)
      })
  
      return tempList
    }


    newWO = () => {
        this.setState({
            workOrderKey: -1,
            qrValue: "",
            switchBool: null,
            locationKey: null,
            facilityKey: null,
            bigFormat: false,
            isTyping: false
        })
        this.toggleWODetails()
    }

    toggleWODetails = () => {
        this.setState({
            modalWODetails: !this.state.modalWODetails
        })
    }

    newSchedule =() => {
      this.gridRef.detailCollapseAll();
      this.toggleNewSchedule();
    }
    toggleNewSchedule =()=>{
      this.setState({modalScheduleDetail: !this.state.modalScheduleDetail 
              ,pmScheduleKey: 0
            });
    }

    closeNewScheduleDetail= async ()=>{
      this.setState({modalScheduleDetail: !this.state.modalScheduleDetail});

      if (this.state.newScheduleCreated){
        await this.getSchedules();
        this.setState({newScheduleCreated: false});
      }
    }

    closeScheduleDetails = async ()=>{
      this.setState({modalScheduleDetail: !this.state.modalScheduleDetail});
      if (this.state.newScheduleCreated !== undefined && this.state.newScheduleCreated !== null && this.state.newScheduleCreated){
        this.setState({loadingGrid: true,newScheduleCreated: false});
        await this.getSchedules();
      }
    }

    dataSentFromMetaChild = (newScheduleCreated)=>{
      this.setState({newScheduleCreated:newScheduleCreated});
    }

    closeWODetailsForm = () => {

        this.setState({
            modalWODetails: !this.state.modalWODetails,
            // workOrderKey: -1, //Removed by BN 6/17/24. Fixes issue where form would not load contents if closed and reopened.
            openWODetails: false,
        })
    }

    detetePMSchedule = async ()=>{
      this.setState({loadingGrid: true,newScheduleCreated: false});
        await this.getSchedules();
    }
    toggleReport = () => {
        this.setState({ modalReport: !this.state.modalReport, isTyping: false })
    }

    createReportParamter = () => {
        const { WOData, workOrderKey } = this.state
        let reportPara = []

        if (WOData && WOData.length > 0) {
            reportPara = [{
                "name": "WorkOrderKey",
                "value": workOrderKey
            }]
        }

        return reportPara
    }

    /*Modified by BN 6/21/24. Clears Basin, Status, and grid filters */
    resetFilter = async () => {
      const { fullMaterialRequestData, selMtlRequestQuery } = this.state;
    
    
      // Update the searchText state and clear basin and status filters
      this.setState({ gridSearch: '', filterKey: null,locationKey: null, itemTypeKey: null, fixxMainTypeKey: null });
    
    };
    updateGridHeight = () => {
      const availableHeight = window.innerHeight * 0.65; // Calculate 60% of the window height
      this.setState({ gridHeight: availableHeight });
  }

 

 rowExpansionTemplate=(args)=> {
    //const {WOData ,pmScheduleKey} = this.state; 
    this.state.pmScheduleKey = args.pmScheduleKey;
    this.setState({ pmScheduleKey: args.pmScheduleKey});
  
    return (
        
        <div> 
            <ScheduledMaintenanceMetaData 
              isSecurityAdminLogin={this.state.isSecurityAdminLogin}
              pmScheduleKey={this.state.pmScheduleKey}
              user={this.props.user}
              newSchedule={false}
              dataSentFromMeta={this.dataSentFromMetaChild}
              detetePMSchedule={this.detetePMSchedule}
              WOData={this.state.WOData} 
            >
          </ScheduledMaintenanceMetaData>
        </div>



    );
  }
  
  rowSelected(args){
    const { expandedRowIndex } = this.state;
    
    this.gridRef.detailCollapseAll();

    // If there is an expanded row, collapse it
    if (this.gridRef.current && expandedRowIndex !== null && expandedRowIndex !== args.rowIndex) {
     
    }

    // Set the currently expanded row index in the state
    this.setState({ expandedRowIndex: args.rowIndex ,pmScheduleKey: args.data.pmScheduleKey });
  }

   

  detailDataBound = (args) => {
    this.gridRef.detailCollapseAll();
  };

  created = () => {
    this.gridRef.on('detail-state-change', (args) => {
      if (args.isExpanded) {
        this.gridRef.detailCollapseAll();
      }
    });
  };

  handleGridCreated() {
    console.log('Grid has been created!');
    
    // You can now interact with the grid (this.gridRef.current)
    // Example: Call any Grid method, like refresh()
    this.gridRef.current.refresh();
  }

  getSelBasin = (value) => {
    const { basinList } = this.state

    if (value !== null && value !== "") {
      return basinList.filter(e => e.locationKey === value)
    }
    return ""
  }

  getSelFilter = (value) => {
    const { mtlRequestQueryList } = this.state

    if (value !== null && value !== "") {
      return mtlRequestQueryList.filter(e => e.value === value)
    }
    return ""
  }

  getSelMaintenanceType = (value) => {
    const { mainTypeList } = this.state

    if (value !== null && value !== "") {
      return mainTypeList.filter(e => e.value === value)
    }
    return ""
  }

   exportToExcel = () => {
    if (this.gridRef) {
     this.gridRef.excelExport();
    }
  }

  async runSchedule(){
    const  {scheduleModel,scheduleAsset,user} = this.state;
     
                                
    let objFlag = false;
    let tempPMScheduleKey = 0;
    let myURI = apiURL + 'ScheduleMaintenance/RunScheduleAll';
    console.log('myURI: ' + myURI);
    fetch(myURI, apiPOSTHeader())
        .then(async response => {
            if (!response.ok) {
                const rspData = response.text();
                var error = (rspData && rspData.message) || response.status;

                rspData.then(t => {
                    this.setState({
                        msgBody: t,
                        msgHeader: "Error",
                        modalMsg: !this.state.modalMsg
                    })
                })

                return Promise.reject(error)
            } else{
                  
                objFlag = true;
                
                return response.json()
            }
                
        }).then(json => { 
            if (objFlag){ 
              this.setState({showRunSchedule: true,loadingGrid: true});
              this.refreshGrid();
              //this.setState({showRunSchedule: false});
              this.toggleRunSchedule();
            } 
        }).catch(error => {
            console.log("PUT error: " + error)
        }) 
  }
 
  toggleRunSchedule = () => {
    this.setState(
      {
        showRunSchedule: true  },
        () => 
          { window.setTimeout(() => { this.setState({ showRunSchedule: false }) }, 3000) 
      })
  }

  render() {
      const {isItemTypeListLoaded, WOData, isDataLoaded, isMainTypeListLoaded, workOrderKey, fixxMainTypeKey,pmScheduleKey, 
          mtlRequestQueryList, user, loadingGrid,
          basinList, selBasin, filterKey, locationKey,itemTypeKey,  
          mainTypeList, selMainType, itemTypeList,gridSearch,isSecurityLoaded,
          isSecurityAdminLogin,showRunSchedule} = this.state
   
      var gridInstance;
 
      if (!isDataLoaded|| !isItemTypeListLoaded || !isMainTypeListLoaded || !isSecurityLoaded) {
        return (<div><h3>Loading Schedule Data.....</h3></div>)
      }
      else if (!isSecurityAdminLogin){
        return (<div><h3>You do not have permission to view Scheduled Maintenance.</h3></div>);
      }
      else if(loadingGrid){
        return (<div>Loading Schedules Data.....</div>)
      } 
      else{ 
        let filterWOData = WOData;

        if (locationKey !== null && locationKey > 0 ){
          filterWOData = WOData.filter(x => x.locationKey == locationKey);
        }

        if (itemTypeKey != null && itemTypeKey > 0){
          filterWOData = filterWOData.filter(x => x.itemTypeKey == itemTypeKey);
        }

        if (filterKey !== null && filterKey === 2){
          filterWOData = filterWOData.filter(x => x.createdByAzureAD == user.account.userName);
        }

        if (fixxMainTypeKey !== null && fixxMainTypeKey > 0){
          filterWOData = filterWOData.filter(x => x.maintenanceTypeKey == fixxMainTypeKey);
        }

        if (gridSearch !== null && gridSearch !== ''){
          filterWOData = filterWOData.filter(x => (
                                                    (x.scheduleID || '').toLowerCase().includes(gridSearch.toLowerCase()) ||
                                                    (x.scheduleName || '').toLowerCase().includes(gridSearch.toLowerCase()) ||
                                                    (x.maintenanceTypeDesc || '').toLowerCase().includes(gridSearch.toLowerCase()) ||
                                                    (x.locationName || '').toLowerCase().includes(gridSearch.toLowerCase()) ||
                                                    (x.issueTypeDesc || '').toLowerCase().includes(gridSearch.toLocaleLowerCase())
                                                  ));
        }

        

        return (
          <div>
            {/* {"pmScheduleKey: " + pmScheduleKey} */}
            <FormGroup>
              <Row form>
                <Col sm={12}> 
                  <Button
                    disabled={!isSecurityAdminLogin}
                    outline
                    color="secondary"
                    className="btn-no-border"
                    onClick={this.newSchedule}
                  >
                    <img
                      src={Plus}
                      alt="new"
                      style={{ width: "25px", margin: "0 5px 0 0" }}
                    />
                    New Schedule
                  </Button>{" "}
                  <Button
                    outline
                    color="secondary"
                    className="btn-no-border"
                    onClick={() => this.refreshGrid()}
                  >
                    <img
                      src={Refresh}
                      alt="Refresh"
                      style={{ width: "30px", margin: "0 5px 0 0" }}
                    />
                    Refresh
                  </Button>
                  <Button
                    outline
                    color="secondary"
                    className="btn-no-border"
                    onClick={this.resetFilter}
                  >
                    <img
                      src={clearfilter}
                      alt="Clear Filter"
                      style={{ width: "30px" }}
                    />
                    Clear Filter
                  </Button> 
                  <Button
                    outline
                    color="secondary"
                    className="btn-no-border"
                    onClick={this.exportToExcel}
                  >
                    <img
                      src={Excel}
                      alt="excel"
                      style={{ width: "50px", margin: "0 0 0 0" }}
                    />
                    Export To Excel
                  </Button>{" "}
                  <Button 
                        color="success" 
                        className="float-right"
                        style={{ marginLeft: "5px" }}
                        //ref={ref}
                        //hidden={(pmScheduleKey == undefined || pmScheduleKey == null || pmScheduleKey < 0 || pmScheduleKey === 0)}
                        disabled={!isSecurityAdminLogin}
                        onClick={() => this.runSchedule()}>Run Schedule
                    </Button> 

                </Col>
              </Row>
              <Row form style={{marginBottom: "5px"}}>
                <Alert 
                    color="success" 
                    // isOpen={showSaveAlert} 
                    isOpen={showRunSchedule} 
                    style={{ margin: "0px", paddingTop: "8px", height: "40px" }}>
                    {"Schedules has been run Successfuly!"}
                </Alert>
              </Row>

              <ColoredLine color="grey" />
            </FormGroup>

            <FormGroup>
              <Row form>
                <Col md={2}>
                  <Row form>
                    <Col md={12}>
                      <Label>Filter</Label>

                      <div>
                        <Select
                          //className="alway-in-between-more"
                          options={mtlRequestQueryList}
                          value={this.getSelFilter(filterKey)}
                          // defaultValue={selMtlRequestQuery}
                          onChange={(e) => this.onTextChange("filterKey", e, e) }
                          isClearable={true}
                        ></Select>
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col md={2}>
                  <Row form>
                    <Col md={12}>
                      <Label for="typeKey">Basin</Label>
                      <Select
                        options={basinList}
                        //value={selBasin}
                        value={this.getSelBasin(locationKey)}
                        defaultValue={selBasin}
                        onChange={(e) => this.onTextChange("locationKey", e, e)}
                        isClearable={true}
                        // width="200px"
                        // isMulti
                      ></Select>
                    </Col>
                  </Row>
                </Col>

                <Col md={2}>
                  <Col md={12}>
                    <Label for="typeKey">Asset Type</Label>

                    <Select
                      options={itemTypeList}
                      value={this.getItemTypeRecord(itemTypeKey)}
                      //defaultValue={selStatus}
                      onChange={(e) => this.onTextChange("itemTypeKey", e, e)}
                      isClearable={true}
                      // isMulti
                    ></Select>
                  </Col>
                </Col>

                
                <Col md={2}>
                  <Col md={12}>
                    <Label for="typeKey">Maintenance Type</Label>

                    <Select
                      options={mainTypeList}
                      value={this.getSelMaintenanceType(fixxMainTypeKey)}
                      defaultValue={selMainType}
                      onChange={(e) => this.onTextChange("fixxMainTypeKey", e, e)}
                      isClearable={true}
                       
                    ></Select>
                  </Col>
                </Col>

                <Col md={4}>
                  <Col md={12}>
                    <Label for="typeKey"> </Label> 
                    <Input 
                      width="75%" 
                      className='floatRight'
                      placeholder="Type here to search......."
                      onChange={(e) => this.onTextChange("gridSearch", e.target.value, e)}
                      value={gridSearch}
                      >
                    </Input>
                  </Col>
                </Col>
              </Row>
            </FormGroup>

              {/* //alex  */}
              <div className="control-pane">
                <div className="control-section">
                  {!loadingGrid && 
                    ( 
                      <GridComponent 
                    ref={(grid) => (this.gridRef = grid)} 
                    dataSource={filterWOData}
                    // height="600"
                    height="100%" 
                    detailTemplate={this.rowExpansionTemplate}
                    width="auto"
                    allowSorting={true}  
                    rowSelected={this.rowSelected} // Attach the rowSelected event handler 
                    selectionSettings={{ type: 'Single' }} // Enable row selection
                    created={this.created}
                    detailDataBound={this.detailDataBound}
                    allowExcelExport={true}  // Enable Excel export 
                  >
                    <ColumnsDirective>
                      <ColumnDirective
                          field="pmScheduleKey"
                          headerText="pmScheduleKey"
                          isPrimaryKey={true}
                          width={70}
                          visible={false}
                        />
                      <ColumnDirective
                        field="pmScheduleType"
                        headerText="pmScheduleType" 
                        width={70}
                        visible={false}
                      /> 
                      <ColumnDirective
                        field='scheduleID'
                        headerText="Schedule ID"
                        width={70}
                      />
                       
                      <ColumnDirective 
                        field="scheduleName" 
                        headerText="Schedule Name" 
                        width={70} 
                      />
                      <ColumnDirective
                        field="maintenanceTypeDesc"
                        headerText="Maintenance Type"
                        width={70}
                        //template={emailTemplate}
                      />
                      <ColumnDirective
                        field="issueTypeDesc"
                        headerText="Issue Type"
                        width={70}
                      />
                      <ColumnDirective
                        field="locationName"
                        headerText="Basin"
                        width={70}
                      />
                      {/* <ColumnDirective
                        field="assetName"
                        headerText="Asset Name"
                        width={70}
                      />  */}
                    </ColumnsDirective>
                    <Inject services={[DetailRow, Sort, Filter, Selection,ExcelExport]}  
                    />
                      </GridComponent>
                    )
                  }
                </div>
                      
              </div>
              {/* perez */}

            <div style={{
                  width: '80vw', // 80% of the viewport width
                  height: '90vh' // 90% of the viewport height
                }}>
              <Modal
                isOpen={this.state.modalScheduleDetail}
                toggle={() => this.toggleNewSchedule()}
                // size={bigFormat ? "xl" : "med"}
                //className={bigFormat ? "custom-modal-workorder" : "med"}
                //backdrop={"static"}
                // onClosed={() => this.getWOdata()}
                size="xl"
                backdrop={"static"}
                style={{maxWidth:"1233px"}}
              >
                <ModalHeader
                  toggle={() => this.closeNewScheduleDetail()} 
                >
                  {"New Schedule"}
                </ModalHeader>
                <ModalBody className="custom-modal-body">
                <ScheduledMaintenanceMetaData 
                    isSecurityAdminLogin={isSecurityAdminLogin}
                    user={this.props.user}
                    pmScheduleKey={pmScheduleKey}
                    newSchedule={true}
                    closeScheduleDetails={this.closeScheduleDetails}
                    dataSentFromMeta={this.dataSentFromMetaChild}
                    WOData={WOData}
                  >
                </ScheduledMaintenanceMetaData>
                </ModalBody>
              </Modal>
            </div>

              

              
          </div>
        );
      }

          




  }

    
    
// Function to calculate luminance of a color
 getLuminance = (color) => {
  const rgb = color.match(/\w\w/g).map((c) => parseInt(c, 16) / 255);
  const luminance = rgb.map((c) => {
      return c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4);
  }).reduce((a, b, i) => a + b * [0.2126, 0.7152, 0.0722][i], 0);
  return luminance;
}

// Define a function to get the color based on maintenance type
 getColorForMaintenanceType = (maintenanceType) => {
  switch (maintenanceType) {
      case 'Fleet (Truck)':
          return '#1B65C4'; // Blue
      case 'Other (Valves, Fittings etc.)':
          return '#CC4140'; // Red
      case 'Cameras':
          return '#A2E09D'; // Light Green
      case 'Safety (QHSE)':
          return '#FF9900'; // Orange
      case 'Pumps':
          return '#1E8A0E'; // Green
      case 'I&E':
          return '#D2CA4E'; // Yellow
      case 'Tower Comms':
          return '#967855'; // Brown
      case 'Pipeline':
          return '#053A78'; // Dark Blue
      case 'Measurement':
          return '#386161'; // Teal
      case 'SCADA':
          return '#D36E87'; // Pink
      case 'Materials Management':
          return '#363636'; // Dark Gray
      case 'Tanks':
          return '#B2F0F7'; // Light Blue
      case 'VFD':
          return '#FFFFFF'; // White
      case 'Lightning Inspection':
          return '#FFFBC2'; // Light Yellow
      case 'Valve':
          return '#8F3499'; // Purple
      case 'Heavy Equipment':
          return '#694A0D'; // Dark Brown
      default:
          return 'transparent'; // Default color
  }
}
 
  saveWorkbook2() {

      var actCols = this.grid.actualColumns
      let gridDts = this.grid.dataSource

      const headers = Object.keys(gridDts[0]);
      headers.pop();
      const wb = new Workbook(WorkbookFormat.Excel2007);
      const ws = wb.worksheets().add("Sheet1");

      for (let i = 0; i < headers.length; i++) {
          for (let j = 0; j < actCols.count; j++) {
              if (headers[i] == actCols.item(j).field) {
                  ws.rows(0).cells(j).value = actCols.item(j).headerText
                  if ((actCols.item(j).headerText || "") === "") {
                      ws.rows(0).cells(j).value = actCols.item(j).field
                  }
              }
          }

      }

      for (let i = 0; i < gridDts.length; i++) {
          const dataRow = gridDts[i];
          const xlRow = ws.rows(i + 1);
          for (let j = 0; j < headers.length; j++) {
              for (let k = 0; k < actCols.count; k++) {

                  if (headers[j] == actCols.item(k).field) {
                      xlRow.setCellValue(k, dataRow[headers[j]]);
                  }

              }

          }
      }

      ExcelUtility.save(wb, "WorkOrder");
  }

  saveWorkbook() {
    const columns = this.grid.actualColumns;
    
    let visibleColumns = [];
    for (let i = 0; i < columns.count; i++) {
      let col = columns.item(i);
      if (!col.isHidden) {
        visibleColumns.push(col);
      }
    }
    
    const wb = new Workbook(WorkbookFormat.Excel2007);
    const ws = wb.worksheets().add("Sheet1");
  
    let excelColumnIndex = 0;
    visibleColumns.forEach(col => {
      ws.rows(0).cells(excelColumnIndex).value = col.headerText || col.field;
      this.grid.dataSource.forEach((row, rowIndex) => {
        ws.rows(rowIndex + 1).cells(excelColumnIndex).value = row[col.field];
      });
      excelColumnIndex++;
    });
  
  
    ExcelUtility.save(wb, "Inventory");
    }
}


