

import React, { Component, useEffect, useState } from 'react';

import { Col, Row, Button, FormGroup, Label, Form } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Input, CustomInput, InputGroupText } from 'reactstrap';
import Select from 'react-select';
import { getUserADAccount, getRandomInt, ColoredLine } from '../../resources/utility'
import PlusIcon from '../../resources/plus.svg'
import {
    apiURL, apiHeader, apiPUTHeader,
    REACT_APP_MAPS_API_KEY, apiPOSTHeader, apiDELETEHeader
} from '../../resources/apiURL';


import { apiSyncFusionAzureFileManagerURL, } from '../../resources/apiURL';
import '../../resources/mapAssetShape.css'

import { Loader } from "@googlemaps/js-api-loader"
import FilePreviewer from 'react-file-previewer';
import { PropertyTableBase } from 'igniteui-react-excel';
import refresh from '../../resources/refresh.svg'

var map = ''
var dataLayer = ''
const additionalOptions = {};

const loader = new Loader({
    apiKey: REACT_APP_MAPS_API_KEY,
    version: "weekly",
    ...additionalOptions,
});

const FilePreviewSection = (props) => {
    const { assetKey, companyID } = props

    const [isFileURLLoaded, setIsFileURLLoaded] = useState(false)
    const [fileURL, setFileURL] = useState('')
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const getForecastImageURL = () => {


        let myURI = apiURL + 'Asset/' + companyID + '/Documents/' + assetKey

        // console.log("Forcatimageurl")
        // console.log(myURI)

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

                for (const i in json) {
                    // console.log("---------running loop--------------")
                    // console.log(i)
                    let e = json[i]
                    console.log(e)
                    if (e.docFileName !== undefined &&
                        e.docFileName !== null &&
                        e.docFileName.toLowerCase().includes("forecast")) {

                        setFileURL(e.shareAccessURL)
                        setIsFileURLLoaded(true)
                        return;

                    }
                }

                setIsFileURLLoaded(true)
                //forceUpdate()
                // console.log('--------------------finish')
            });


    }

    const refreshForecastImage = () => {
        getForecastImageURL()
        forceUpdate()
        //console.log("refreshForecastImage")
    }

    useEffect(() => {
        if (assetKey !== undefined && assetKey !== null && assetKey > 0) {
            getForecastImageURL()
        }
    }, [])

    if (!isFileURLLoaded) {
        return (<h3><div>
            Loading forecast images.....
        </div></h3>)
    }
    return (
        <div>
            <FormGroup style={{ marginTop: "10px", marginBottom: "5px" }}>
                <ColoredLine color="black" style={{ marginTop: "30px", marginBottom: "5px" }} />
                <Row form style={{ marginTop: "10px" }}>
                    <Col md={"auto"}>
                        <Button color="secondary"
                            outline
                            style={{ height: "25px", padding: "0px", fontSize: "12px" }}
                            onClick={() => refreshForecastImage()}>
                            <img
                                src={refresh}
                                alt="Refresh"
                                style={{ width: "18px", height: "18px", margin: "0 0 0 150" }} />
                            Reload Forecast
                        </Button>
                    </Col>
                    <Col>
                        <Label>{"Forecast section only load file name 'forecast.jpg' in Documents." + (fileURL === "" ? "No forecast found" : "")}</Label>
                    </Col>
                </Row>

                <Row style={{ marginTop: "15px" }}
                    hidden={(fileURL === '' || !isFileURLLoaded)}>
                    <Col>
                        <FilePreviewer
                            //id={Math.random()}
                            height='55vh'
                            width="100%"
                            file={{
                                url: (fileURL !== '' && isFileURLLoaded ? fileURL : null)  //"https://wbrstorageaccountprod.blob.core.windows.net/files/tasset/BSD/BSD90190/forecast.jpg"
                            }}
                        />


                    </Col>
                </Row>
            </FormGroup>
        </div>
    )

}



export default class AssetMapForecast extends Component {
    static displayName = AssetMapForecast.name;
    imgObj;
    blobUrl = '';

    constructor(props) {
        super(props)
        document.title = "WaterBridge Portal - BD - Map and Forecast"

        this.state = {
            assetCoor: [],
            isAssetCoorLoaded: false,
            basin: props.basin,
            assetTypeList: props.assetTypeList,
            subSystem: props.subSystem,
            companyID: props.companyID,
            assetCatID: props.assetCatID,
            selAssetType: props.selAssetType,
            selBasin: props.selBasin,
            selSubSystem: props.selSubSystem,
            assetTypeID: props.asset_type_id,
            assetKey: props.assetkey,
            asset_detail: props.asset_detail,
            controlid: 'WBP' + props.assetkey,
            parentAssetKey: props.parentAssetKey,
            mapzoom: (props.mapzoom === undefined ? 8 : 12),
            showFilterBar: true, //(props.showFilterBar === undefined ? true : props.showFilterBar),
            searchText: '',
            markerList: [],
            kmzFiles: props.kmzFiles,
            hostUrl: apiSyncFusionAzureFileManagerURL,
            folderPath: "tasset/" + (props.asset_type_id.substring(0, 3) === 'BSD' ? 'BSD' : props.asset_type_id) + "/" +
                ((props.asset_detail.asset_blob_id === null || props.asset_detail.asset_blob_id === '') ? props.asset_detail.asset_id : props.asset_detail.asset_blob_id),
            kml_URLs: [
                {
                    basin_name: 'EVX',
                    url: 'https://wbrstorageaccountprod.blob.core.windows.net/kml/WBREVXRoutes.kml'
                },
                // {
                //     basin_name: 'ARK',
                //     url: 'https://wbrstorageaccountprod.blob.core.windows.net/kml/WBRARKRoutes.kml'
                // },
                // {
                //     basin_name: 'NDB',
                //     url: 'https://wbrstorageaccountprod.blob.core.windows.net/kml/WBRNDBRoutes.kml'
                // },

                // {
                //     basin_name: 'SDB',
                //     url: 'https://wbrstorageaccountprod.blob.core.windows.net/kml/WBRSDBRoutes4.kml'
                // },
                // {
                //     basin_name: 'SDB1',
                //     url: 'https://wbrstorageaccountprod.blob.core.windows.net/kml/WBRSDBRoutes5.kml'
                // }
            ]
            ,

        }
    }


    getAssetCoordinate = () => {
        const { assetTypeID, assetKey, parentAssetKey,
            selAssetType, selBasin, selSubSystem } = this.state

        let myURI = apiURL + 'Asset/' + this.state.companyID + '/' + this.state.assetCatID + '/Coordinate'

        /*only load if map is not all assets. */
        if (this.state.assetTypeID !== "") {
            //myURI = myURI + '/ByAssetType/' + assetTypeID
            if (this.state.assetKey > 0) {
                myURI = myURI + '/ByAssetKey/' + this.state.assetKey
            } else if (this.state.assetTypeID !== "") {
                myURI = myURI + '/ByAssetType/' + this.state.assetTypeID
            }
        }

        if (parentAssetKey > 0) {
            myURI = myURI + '/ByParentKey/' + parentAssetKey
        }

        // console.log("myURI")
        // console.log(myURI)
        // console.log(this.state.assetCatID)

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                //console.log(json)
                this.setState({
                    isAssetCoorLoaded: true,
                    assetCoor: json,
                })

                if (assetTypeID !== "") {
                    this.initMap(json)
                }

            });

    }

    async initMap(data) {

        // The location of Uluru
        //const position = { lat: 29.749907, lng: -95.358421 };

        // Request needed libraries.
        //@ts-ignore
        loader.importLibrary('maps').then(async () => {

            const { Map, InfoWindow, Point, KmlLayer } = await window.google.maps.importLibrary("maps");
            const { AdvancedMarkerElement, PinElement, Marker } = await window.google.maps.importLibrary("marker");
            //const {KmlLayer } =  await window.google.maps.importLibrary("KmlLayer");

            // The map, centered at Uluru
            if (map.data === undefined || this.state.markerList[0] === undefined) {
                map = new Map(document.getElementById("map"), {
                    zoom: this.state.mapzoom,
                    center: {
                        lat: (data.length > 0 ? data[0].lat : 29.740700),
                        lng: (data.length > 0 ? data[0].lng : -95.463600)
                    },
                    mapTypeId: 'hybrid',
                    mapId: "WBR_Map",

                });

            } else {

                // console.log("this.state.markerList")
                // console.log(this.state.markerList[0])
                for (var i = 0; i < this.state.markerList.length; i++) {
                    this.state.markerList[i].map = null //= null
                    //console.log(this.state.markerList[i])
                }
                this.state.markerList.length = 0
            }

            map.data.addListener('mouseover', (event) => {
                map.data.revertStyle();
                // ADD A STYLE WHEN YOU HOVER OVER A SPECIFIC POLYGON
                map.data.overrideStyle(event.feature, { strokeWeight: 3, fillOpacity: 0.1 });
                // IN CONSOLE LOG, YOU CAN SEE ALL THE DATA YOU CAN RETURN
                console.log(event.feature)
            });
            map.data.addListener('mouseout', (event) => {
                // REVERT THE STYLE TO HOW IT WAS WHEN YOU HOVER OUT
                map.data.revertStyle();
            });

            const infoWindow = new InfoWindow({
                content: "",
                disableAutoPan: true,
            });

            /** load kml files */
            this.state.kml_URLs.map(e => {
                const kmzLayer = new KmlLayer(e.url, {
                    suppressInfoWindows: true,
                    preserveViewport: false,
                    map: map,
                })
                kmzLayer.setMap(map)
            })


            data.map((e, i) => {
                // console.log('---->e----kmzFiles')
                // console.log(e)
                /*Added by HN 05/28/2024
              loadd kmz or addtional file for selected asset */
                if (e.addon_file_type !== undefined &&
                    e.addon_file_type !== null &&
                    e.addon_file_type === 'kmz') {
                    let kmzFiles = JSON.parse(e.addon_file_urls)
                    // console.log('---->kmzFiles')
                    // console.log(kmzFiles)

                    /** load kml files */
                    if (kmzFiles !== undefined && kmzFiles !== null) {
                        // console.log("kmzFiles")
                        // console.log(kmzFiles)
                        try {
                            kmzFiles.map(e => {
                                console.log(e.url)
                                const kmlLayer = new KmlLayer(e.url, {
                                    //suppressInfoWindows: true,
                                    //preserveViewport: false,
                                    map: map,
                                })
                                kmlLayer.setMap(map)
                            })
                        } catch (err) {
                            console.log('Error in load map kmz files: ' + e.fileName)
                        }

                    }
                }
                /**end */




            });





        })

    }

    componentDidMount() {
        this.getAssetCoordinate()
    }



    render() {
        const { hostUrl, folderPath, isAssetCoorLoaded } = this.state

        const mapHeight = '55vh'
        // if (!isForcastFileLoaded){
        //     return (<h3><div>
        //         Loading Asset kmz and forecast .....
        //     </div></h3>) 
        // }
        return (
            <div>
                <FormGroup>
                    <Row form style={{ marginTop: "10px" }}>
                        <Col md={"auto"}>
                            <Button color="secondary"
                                outline
                                style={{ height: "25px", padding: "0px", fontSize: "12px" }}
                                onClick={() => this.getAssetCoordinate()}>
                                <img
                                    src={refresh}
                                    alt="Refresh"
                                    style={{ width: "18px", height: "18px", margin: "0 0 0 150" }} />
                                Reload Maps
                            </Button>
                        </Col>
                        <Col>
                            <Label>{"Map section will load all 'kmz' files in Documents."}</Label>
                        </Col>
                    </Row>
                    <Row>

                        <Col>
                            <div id='mapContainer'>
                                <div style={{
                                    width: '100%',
                                    height: mapHeight,
                                    padding: ("0px"),
                                    margin: "0px"
                                }} id='map' />
                            </div>
                        </Col>
                    </Row>



                    <FilePreviewSection
                        id={Math.random()}
                        key={Math.random()}
                        assetKey={this.state.assetKey}
                        companyID={this.state.companyID}>

                    </FilePreviewSection>
                </FormGroup>
            </div>
        )
    }

}