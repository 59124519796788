import React, { Component, useState } from 'react';
import { Form, Col, Row, Button, FormGroup, Label, Input, InputGroupText, InputGroup } from 'reactstrap';
//import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';


import { TabContent, TabPane, Nav, NavItem, NavLink, FormFeedback, Alert } from 'reactstrap';
import '../../custom.css';
import { apiURL, apiPOSTHeader, apiPUTHeader, apiDELETEHeader } from '../../resources/apiURL';
// import ConfirmModal from '../../operation/ConfirmModal'
// import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
// import Plus from '../../resources/plus.svg'
// import Refresh from '../../resources/refresh.svg'
// import TrashBin from '../../resources/trash.svg'
// import Select from 'react-select';
import { getUserADAccount, getRandomInt } from '../../resources/utility'
import classnames from 'classnames';
import DocumentBrowserDragDrop from '../../resources/DocumentBrowserDragDrop';

import FileManager from '../../resources/FileManager';
//import DocumentBrowser from '../../resources/DocumentBrowser';

// import NumberFormat from 'react-number-format';

// import { ColoredLine, getFirstDayofMonth } from '../../resources/utility';

export default class AssetWellboreDocuments extends Component {
    static displayName = AssetWellboreDocuments.name;
    constructor(props) {
        super(props)

        this.state = {
            activeTab: '1',
            assetkey: props.assetkey,
            user: props.user,
            isFormReadOnly: false,
            isDataLoaded: false,
            isAssetAdmin: props.isAssetAdmin,
            asset_type_id: props.asset_type_id,
            docCount: [],
            isLoaded: false,
            asset_detail: [],
            fileLoaded: false
        }

    }

    toggleTab = tab => {
        const { activeTab } = this.state

        if (activeTab !== tab) {
            this.setState({ activeTab: tab })
        }
    }


    getAssetDetails = () => {

        let myURI = apiURL + 'Asset/WBR' + '/Detail/ByAssetKey/' + this.state.assetkey
        console.log(myURI)

        fetch(myURI, { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                console.log("json")
                console.log(json)
                this.setState({
                    isLoaded: true,
                    asset_detail: json[0]
                })
            });
    }


    refreshForm = () => {
        console.log('Here here here')
        this.setState({ fileLoaded: !this.state.fileLoaded })
    }


    componentDidMount() {
        this.getAssetDetails()
    }
    render() {
        const { isLoaded, activeTab, assetkey, isFormReadOnly, asset_detail, fileLoaded,asset_type_id } = this.state
        
        // console.log("--------->asset_type_id.substring(1,3)")
        // console.log(asset_detail)
        if (!isLoaded) {
            return (<div>Loadding document details ......</div>)
        }
        /**Update by HN 01/10/2025 Rollup all BSD child document to top level
         * hard code "BSD" for now until find a permernant solutions
         * set form option 256 but not yet implemented
         */
        return (<div>
            <FormGroup>
                <Row form>
                    <Col md="12">
                        <FileManager
                            assetkey={this.state.assetkey}
                            controlid={'WBP' + this.state.assetkey}
                            // folderPath={"tasset." + asset_detail.asset_type_id + '.' + asset_detail.asset_id }
                            folderPath={"tasset/"+ (asset_type_id.substring(0,3) === 'BSD'?'BSD':asset_type_id) + "/" + 
                            ((asset_detail.asset_blob_id === null || asset_detail.asset_blob_id === '') ? asset_detail.asset_id : asset_detail.asset_blob_id)}
                            //refreshForm={this.refreshForm}
                            key={(fileLoaded ? 1 : 0)}
                        >
                        </FileManager>
                    </Col>
                </Row>
            </FormGroup>
        </div >)
    }
}

//     render() {
//         const { isLoaded, activeTab, assetkey, isFormReadOnly, asset_detail } = this.state

//         if (!isLoaded) {
//             return (<div>Loadding wellboare permit details ......</div>)
//         }
//         return (<div>
//             <FormGroup style={{ marginTop: "-15px", marginLeft: "-15px" }}>
//                 <div>
//                     <Nav tabs>
//                         <NavItem>
//                             <NavLink
//                                 className={classnames({ active: activeTab === '1' })}
//                                 onClick={() => { this.toggleTab('1'); }}
//                             >
//                                 {/* {"Economics (" + (docCount.length >0 ? docCount[0].ecoDocCount : 0) + ")"} */}
//                                 Regulatory Permitting

//                             </NavLink>
//                         </NavItem>

//                         <NavItem>
//                             <NavLink
//                                 className={classnames({ active: activeTab === '2' })}
//                                 onClick={() => { this.toggleTab('2'); }}
//                             >
//                                 {/* {"Flowback Plan/Hydraulics (" + (docCount.length >0 ? docCount[0].flowBackDocCount : 0) + ")"} */}
//                                 Drilling & Completion Information
//                             </NavLink>
//                         </NavItem>

//                         <NavItem>
//                             <NavLink
//                                 className={classnames({ active: activeTab === '3' })}
//                                 onClick={() => { this.toggleTab('3'); }}
//                             >
//                                 {/* {"Cost Justification/Quotes (" + (docCount.length >0 ? docCount[0].costDocCount : 0) + ")"} */}
//                                 AFEs
//                             </NavLink>
//                         </NavItem>

//                         <NavItem>
//                             <NavLink
//                                 className={classnames({ active: activeTab === '4' })}
//                                 onClick={() => { this.toggleTab('4'); }}
//                             >
//                                 {/* {"Maps (" + (docCount.length >0 ? docCount[0].mapDocCount : 0) + ")"} */}

//                                 Geology Data
//                             </NavLink>
//                         </NavItem>


//                         {/* <NavItem>
//                             <NavLink
//                                 className={classnames({ active: activeTab === '5' })}
//                                 onClick={() => { this.toggleTab('5'); }}
//                             >
//                                 {"Engineering Drawings (" + (docCount.length >0 ? docCount[0].engDocCount : 0) + ")"}

//                             </NavLink>
//                         </NavItem>

//                         <NavItem>
//                             <NavLink
//                                 className={classnames({ active: activeTab === '6' })}
//                                 onClick={() => { this.toggleTab('6'); }}
//                             >
//                                 {"Other (" + (docCount.length >0 ? docCount[0].otherDocCount : 0) + ")"}

//                             </NavLink>
//                         </NavItem> */}

//                     </Nav>

//                     <TabContent activeTab={activeTab}>
//                         <TabPane tabId="1">
//                             <div><Row>
//                                 <Col md={12}>
//                                     {/* <DocumentBrowserDragDrop buttonLabel="View Image"
//                                         disabled={isFormReadOnly}
//                                         buttonColor="primary"
//                                         formName="Asset Regulatory Permitting"
//                                         linkTableName={"tasset_RegPer"}
//                                         linkPrimaryKey={assetkey}
//                                         //allowDelete={isAFEManager || isAFEAdmin}
//                                         apiURL={apiURL + 'Asset/WBR/Documents/' + assetkey + '/Bucket/tasset_RegPer'} /> */}
//                                     <FileManager
//                                         controlid={'regPermit' + this.state.assetkey}
//                                         // folderPath={"tasset." + asset_detail.asset_type_id + '.' + asset_detail.asset_id }
//                                         folderPath={"tasset/WBP/" + asset_detail.asset_id + '/RegPermit'}
//                                     >

//                                     </FileManager>
//                                 </Col>
//                             </Row></div>
//                         </TabPane>

//                         <TabPane tabId="2">
//                             <div><Row>
//                                 {/* <Col md={12}>
//                                     <DocumentBrowserDragDrop buttonLabel="View Image"
//                                         disabled={isFormReadOnly}
//                                         buttonColor="primary"
//                                         formName="Asset Drilling & Completion Information"
//                                         linkTableName={"tasset_Drilling"}
//                                         linkPrimaryKey={assetkey}
//                                         apiURL={apiURL + 'Asset/WBR/Documents/' + assetkey + '/Bucket/tasset_Drilling'} />

//                                 </Col> */}
//                                 <Col md={12}>
//                                     <FileManager
//                                         controlid={'drilling' + this.state.assetkey}
//                                         // folderPath={"tasset." + asset_detail.asset_type_id + '.' + asset_detail.asset_id }
//                                         folderPath={"tasset/WBP/" + asset_detail.asset_id + '/DrillingComletion'}
//                                     >

//                                     </FileManager>
//                                 </Col>
//                             </Row></div>
//                         </TabPane>

//                         <TabPane tabId="3">
//                             <div><Row>
//                                 {/* <Col md={12}>
//                                     <DocumentBrowserDragDrop buttonLabel="View Image"
//                                         disabled={isFormReadOnly}
//                                         buttonColor="primary"
//                                         formName="AFEs"
//                                         linkTableName={"tasset_afe"}
//                                         linkPrimaryKey={assetkey}
//                                         apiURL={apiURL + 'Asset/WBR/Documents/' + assetkey + '/Bucket/tasset_afe'} />

//                                 </Col> */}
//                                 <Col md={12}>
//                                     <FileManager
//                                         controlid={'afe' + this.state.assetkey}
//                                         // folderPath={"tasset." + asset_detail.asset_type_id + '.' + asset_detail.asset_id }
//                                         folderPath={"tasset/WBP/" + asset_detail.asset_id + '/AFE'}
//                                     >

//                                     </FileManager>
//                                 </Col>
//                             </Row></div>
//                         </TabPane>
//                         <TabPane tabId="4">
//                             <div><Row>
//                                 {/* <Col md={12}>
//                                     <DocumentBrowserDragDrop buttonLabel="View Image"
//                                         disabled={isFormReadOnly}
//                                         buttonColor="primary"
//                                         formName="Geology Data"
//                                         linkTableName={"tasset_geo"}
//                                         linkPrimaryKey={assetkey}
//                                         apiURL={apiURL + 'Asset/WBR/Documents/' + assetkey + '/Bucket/tasset_geo'} />

//                                 </Col> */}
//                                 <Col md={12}>
//                                     <FileManager
//                                         controlid={'GeoData' + this.state.assetkey}
//                                         // folderPath={"tasset." + asset_detail.asset_type_id + '.' + asset_detail.asset_id }
//                                         folderPath={"tasset/WBP/" + asset_detail.asset_id + '/GeoData'}
//                                     >

//                                     </FileManager>
//                                 </Col>
//                             </Row></div>
//                         </TabPane>



//                     </TabContent>

//                 </div>
//             </FormGroup>
//         </div>)
//     }
// }