//Created By BN 12/20/21
//Modified by BN 1/24/24
//Modified by BN 1/25/24
import React,{Component}  from 'react';
import { withRouter } from 'react-router-dom'; // Import withRouter
import { Col, Row, Button, FormGroup, CustomInput, Label, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter, Input} from 'reactstrap';
import {apiURL,apiHeader, apiPOSTHeader} from '../resources/apiURL';
import MenuIcon from '../resources/menu.png';
import '../custom.css';
import moment, { defaultFormatUtc } from "moment";
import { DataGridPresenterManager, IgrDataGrid } from 'igniteui-react-grids';
import { IgrDataGridModule } from 'igniteui-react-grids';
import { IgrTextColumn} from 'igniteui-react-grids';
import { IgrNumericColumn } from 'igniteui-react-grids';
import { IgrDateTimeColumn } from 'igniteui-react-grids';
import { IgrColumnGroupDescription } from 'igniteui-react-grids';
import { IFastItemColumnPropertyName_$type, ListSortDirection } from 'igniteui-react-core';
import { DataGridPager } from './DataGridPager';
import { IgrGridColumnOptionsModule } from 'igniteui-react-grids';
import { IgrDatePickerModule  } from 'igniteui-react-inputs';
import { Workbook } from 'igniteui-react-excel';
import { WorkbookFormat } from 'igniteui-react-excel';
import { getUserADAccount } from '../resources/utility'
import RefreshIcon from '../resources/refresh.svg';
import PlusIcon from '../resources/plus.svg';
import TrashIcon from '../resources/trash.svg';
import ExcelIcon from '../resources/excel.svg';
import EditorIcon from "../resources/editor.svg"
import saveLayout from '../components/img/saveLayout.png'
import clearfilter from '../components/img/clearfilter.png'
import { IgrTemplateColumn } from 'igniteui-react-grids';
import { IgrTemplateCellUpdatingEventArgs } from 'igniteui-react-grids';
import { IgrTemplateCellInfo } from 'igniteui-react-grids';
import { IIgrCellTemplateProps } from 'igniteui-react-grids';
import InventoryItemType from "./InventoryItemType"
import { ExcelUtility } from './ExcelUtility';
import { ColoredLine, getFirstDayofMonth } from '../resources/utility';
import InventoryDetails from './InventoryDetails'
import InventoryItemTypeCreator from './InventoryItemTypeCreator'
import { IgrDataGridToolbarModule } from "igniteui-react-grids";
import { IgrDataGridToolbar } from "igniteui-react-grids";
import { FilterFactory, FilterExpressionCollection } from 'igniteui-react-core';
IgrDataGridModule.register();
IgrGridColumnOptionsModule.register();
 //IgrInputModule.register();
IgrDatePickerModule.register();
IgrDataGridToolbarModule.register();

var DatePicker = require("reactstrap-date-picker");

    class Inventory extends Component {
    static displayName=Inventory.name;

    constructor(props){
        super(props)
        this.onGridRef = this.onGridRef.bind(this);
        this.onGridRowSelected = this.onGridRowSelected.bind(this);
        this.saveWorkbook = this.saveWorkbook.bind(this);
        this.onFilter = this.onFilter.bind(this);
        this.addInventory = this.addInventory.bind(this);
        this.onToolbarRef = this.onToolbarRef.bind(this);
        this.searchInput = React.createRef();

        this.state = {
            facInvCollector:[],
            facInvCollectorDefault:[],
            data:[],
            currentView: "",
            test1: false,
            itemType:[],
            itemTypeTitle: "test",
            itemPropertyMap:[],
            filterType:"test",
            itemTypeKey:-1,
            filterColumns: -1,
            inventoryData:null,
            filterBool:true,
            isLoaded:false,
            isLoaded2: false,
            isItemMapLoaded:false,
            modal:false,
            facilityInvCollectorKey:-1,
            windowHeight:window.innerHeight, 
            modalItemTypeCreator: false,
            createdDatetime: new Date().toISOString().substring(0,10),
            isSecurityAdminLogin: false,
            isFleetSupervisors: false,
            isSecurityLoaded: false,
            facilityKey: null,
            transformedTrailProps: [],
            statusType: "Active",
            isGridLoaded: false,
            user: props.user,
            searchText: '',
            shouldReloadGrid: false,
            isTyping:false,
            detailedData: [],
            isDetailLoaded: false,
            
        }

    }


    toggle=(e)=>{

            this.setState({modal: !this.state.modal})

    }

    toggleItemTypeCreator=()=>{
        
        this.setState({modalItemTypeCreator: !this.state.modalItemTypeCreator, isTyping: false})
    }


    toggleYes = (e) =>{
        this.toggleItemType()
     
        if (e !== undefined && e !== null){
            if (e.target.value > 0){
          
            
                this.addInventory2(e.target.value)
                
            }
        }else{
            this.setState({itemTypeKey:-1})
        }
        
        
     
     
       
    }


    // onGridRowSelected(s, e) {

    //     if (e.addedKeys.count > 0) {
    //         let clickedRow = e.addedKeys.item(0) 
    //         let dataItem = clickedRow.value[0] 

    //         console.log(dataItem)

    //         this.setState({
    //                         facilityInvCollectorKey: dataItem.facilityInvCollectorKey,
    //                         itemTypeKey: dataItem.itemTypeKey,
    //                         inventoryData: dataItem,
    //                         facilityKey: dataItem.facilityKey,
    //                         isTyping: false
    //                         },()=>{
    //                             if (dataItem.facilityKey == 0 || dataItem.facilityKey == "")
    //                             {
    //                                 this.setState({
    //                                     facilityKey: null
    //                                 })
    //                             }
    //                         }) 
           
    //     } 
    // }


/*Added by BN 8/1/24. Data will be used from vimFacilityInvCollector to map props correctly.*/
  onGridRowSelected(s, e) {
    if (e.addedKeys.count > 0) {
        let clickedRow = e.addedKeys.item(0);
        let dataItem = clickedRow.value[0];

        // Find the detailed data for the selected row
        let selectedData = this.state.detailedData.find(item => item.facilityInvCollectorKey === dataItem.facilityInvCollectorKey);
        
        if (selectedData) {
          // console.log(selectedData)
            this.setState({
                facilityInvCollectorKey: selectedData.facilityInvCollectorKey,
                itemTypeKey: selectedData.itemTypeKey,
                inventoryData: selectedData,
                facilityKey: selectedData.facilityKey,
                isTyping: false
            }, () => {
                if (selectedData.facilityKey === 0 || selectedData.facilityKey === "") {
                    this.setState({ facilityKey: null });
                }
            });
        }
    }
}
  mapProperties = (dataItem) => {
    let mappedData = { ...dataItem };

    switch (dataItem.itemTypeID) {
        case 'Attac':
            mappedData.prop1 = dataItem.prop4;
            mappedData.prop2 = dataItem.prop3;
            mappedData.prop3 = dataItem.prop1;
            mappedData.prop4 = '';
            mappedData.prop5 = '';
            mappedData.prop6 = dataItem.prop2;
            mappedData.prop7 = dataItem.prop5;
            break;
        case 'Trail':
            mappedData.prop1 = dataItem.prop1; // Original value
            mappedData.prop2 = dataItem.prop4;
            mappedData.prop3 = dataItem.prop6;
            mappedData.prop4 = dataItem.prop4; // Original value
            mappedData.prop5 = dataItem.prop6;
            mappedData.prop6 = dataItem.prop3;
            mappedData.prop7 = dataItem.prop20;
            mappedData.prop8 = '';
            mappedData.prop20 = dataItem.prop7;
            mappedData.prop21 = dataItem.prop8;
            break;
        case 'Skid':
        case 'Forkl':
        case 'Water':
        case 'Excav':
        case 'Long':
        case 'Haul':
        case 'Grader':
        case 'Dump':
        case 'Loade':
        case 'Dozer':
        case 'Boom':
        case 'Artic':
        case 'FKLift':
            mappedData.prop1 = dataItem.prop1; // Original value
            mappedData.prop2 = dataItem.prop2; // Original value
            mappedData.prop3 = dataItem.prop3; // Original value
            mappedData.prop4 = '';
            mappedData.prop5 = '';
            mappedData.prop6 = dataItem.prop4;
            mappedData.prop7 = dataItem.prop5;
            break;
        case 'Truck':
            mappedData.prop1 = dataItem.prop1; // Original value
            mappedData.prop2 = dataItem.prop2; // Original value
            mappedData.prop3 = dataItem.prop3; // Original value
            mappedData.prop4 = dataItem.prop4; // Original value
            mappedData.prop5 = dataItem.prop5; // Original value
            mappedData.prop6 = dataItem.prop6; // Original value
            mappedData.prop7 = dataItem.prop7; // Original value
            mappedData.prop8 = dataItem.prop8; // Original value
            mappedData.prop16 = dataItem.vEMP?.EmpName || dataItem.prop16;
            break;
        default:
            break;
    }

    return mappedData;
};


  // saveGridLayout = () => {
  //   const { currentView, user } = this.state;
  
  //   if (this.grid !== undefined) {
  //     let layoutData = this.grid.saveLayout();
  //     console.log(layoutData)

  //     let parsedLayoutData = JSON.parse(layoutData);
  //     parsedLayoutData.columns.forEach(column => {
  //       column.Filtered = false; // Set Filtered to false
  //       column.FilterConditions = []; // Clear any existing filter conditions
  //     });
  //     parsedLayoutData.filterColumns = []; // Clearing filter information
        
  //     layoutData = JSON.stringify(parsedLayoutData);
  //     console.log(layoutData)
  //     const addLayoutDTO = {
  //       "applicationKey": currentView === 'fleet' ? 34 : 14,
  //       "userName": user.account.userName,
  //       "layoutName": currentView === 'fleet' ? "Fleet" : "ItemMaster",
  //       "layoutData": layoutData,
  //       "createdBy": user.account.userName
  //     };
  //     // console.log(addLayoutDTO)
  
  //     let myURI = apiURL + 'AppLayout/AddLayout';
  //     fetch(myURI, apiPOSTHeader(addLayoutDTO))
  //       .then(async response => {
  //         if (!response.ok) {
  //           // Handle error response
  //         } else {
  //           // Logic for successful layout save
  //         }
  //       }).catch(error => {
  //         // Handle error
  //       });
  //   }
  // }

  saveGridLayout = () => {
    const { currentView, user } = this.state;
  
    if (this.grid !== undefined) {
      let layoutData = this.grid.saveLayout();
      // console.log(layoutData);
  
      let parsedLayoutData = JSON.parse(layoutData);
  
      // Reset Filtered to false and clear FilterConditions for each column
      parsedLayoutData.columns.forEach(column => {
        column.Filtered = false;
        column.FilterConditions = [];
      });
  
      // Clearing filter information
      parsedLayoutData.filterColumns = [];
  
      // If currentView is not "fleet", filter out columns with "prop" in the ColumnName
      if (currentView !== 'fleet') {
        parsedLayoutData.columns = parsedLayoutData.columns.filter(column => !column.ColumnName.includes("prop"));
      }
  
      layoutData = JSON.stringify(parsedLayoutData);
      // console.log(layoutData);
      const addLayoutDTO = {
        "applicationKey": currentView === 'fleet' ? 34 : 14,
        "userName": user.account.userName,
        "layoutName": currentView === 'fleet' ? "Fleet" : "ItemMaster",
        "layoutData": layoutData,
        "createdBy": user.account.userName
      };
  
      let myURI = apiURL + 'AppLayout/AddLayout';
      fetch(myURI, apiPOSTHeader(addLayoutDTO))
        .then(async response => {
          if (!response.ok) {
            // Handle error response
          } else {
            // Logic for successful layout save
          }
        }).catch(error => {
          // Handle error
        });
    }
  };


  loadGridLayout = () => {

    const {currentView} = this.state
    if (this.grid !== undefined && currentView === 'fleet') {

        const { user } = this.state
        var myURI = apiURL + 'AppLayout/Grid/34/' + user.account.userName + '/Fleet'
        //var myURI = apiURL + 'api/AppLayout/Grid'
        //myURI = apiURL + 'WOTracker/WO'
        fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                if (json.length > 0) {
                    this.grid.loadLayout(json[0].layoutData)
                    // console.log(json[0].layoutData)
                }
                //if (json.length() > 0) {

                //}

            })
           
   

        this.setState({ isGridLoaded: true })
        // this.openDetailsFromKey()
    }
    else if (this.grid !== undefined && currentView !== 'fleet'){
      const { user } = this.state
      var myURI = apiURL + 'AppLayout/Grid/14/' + user.account.userName + '/ItemMaster'
      //var myURI = apiURL + 'api/AppLayout/Grid'
      //myURI = apiURL + 'WOTracker/WO'
      fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
          .then(res => res.json())
          .then(json => {
              if (json.length > 0) {
                  this.grid.loadLayout(json[0].layoutData)

              }
              //if (json.length() > 0) {

              //}

          })

          this.setState({ isGridLoaded: true })
 
    }
}


    itemTypeDesc = () => {
      const { itemType, itemTypeKey } = this.state;
      const test = itemType.filter(item => item.itemTypeKey === itemTypeKey);
  
      // Check if there is at least one element in the test array
      if (test.length > 0) {
          return test[0].itemTypeDesc;
      } else {
          // Handle the case where no matching item type is found
          // This could be returning a default value or null
          return null; // or some default value
      }
  }

  
    addInventory2 = (key)=>{
        
         this.setState({itemTypeKey: key}, ()=>{                          
            const test = this.itemTypeDesc()
            this.setState({itemTypeTitle: test}, ()=>{
            
                const newInventory = this.createInventoryForm()
                // console.log(newInventory)
                 this.setState({
                                    
                     inventoryData:newInventory,
                                    
                     modal:!this.state.modal
                    })
                            
         
          })

       })

    }


    addInventory = (e)=>{
 
                    this.setState({facilityInvCollectorKey: -1 },()=>{
                     
                        this.setState({itemTypeKey: e.target.value}, ()=>{
   
                            const test = this.itemTypeDesc()
                            this.setState({itemTypeTitle: test}, ()=>{
                               
                             
                                    const newInventory = this.createInventoryForm()
                                    this.setState({
                                              
                                        inventoryData:newInventory,
                                    })
                            
                               
                                }
                               )
                            
                        
                        })
                   
                    })
                
                
    }

  
    onGridRef(grid) {
        if (!grid) {
            return;
        }
        this.grid = grid;
        this.grid.dataSource = this.state.facInvCollector;

        if (this.toolbar !== null) {
          this.toolbar.targetGrid = this.grid

      }

    }

    onToolbarRef(toolbar) {
      this.toolbar = toolbar;
      if (this.toolbar) {
          this.toolbar.targetGrid = this.grid;
      }
  }


    getSecurity=()=>{
        let myURI = apiURL + 'EmployeeDetails/Checkpoint/'+ this.props.user.account.userName   
        const {security, appSecurityNo} = this.state
        fetch(myURI, apiHeader('GET'), {modes: 'no-cors'})
        .then(res => res.json())
        .then(json=>{
            this.setState({
                
                isSecurityLoaded: true,
                security: json,
                isSecurityAdminLogin: (json.filter((list)=> list.appSecurityNo === 108).length > 0 ? false: true),
                isFleetSupervisors: (json.filter((list)=> list.appSecurityNo === 139).length > 0 ? false: true)
            })
          
        })

        } 

        getFacInvCollector = (view, statusType) => {
          // console.log("Fetching data for view:", view, "with statusType:", statusType);
      
          if (view === "fleet") {
              let myURL = apiURL + 'ItemMaster/FacilityInventoryCollectorFleet';
              fetch(myURL, apiHeader('GET'), { mode: 'no-cors' })
                  .then(res => res.json())
                  .then(data => {
                      // console.log("Fetched data:", data);
      
                      let filteredData;
                      switch (statusType) {
                          case 'Active':
                              filteredData = data.filter(item => item.inService === 1);
                              break;
                          case 'Inactive':
                              filteredData = data.filter(item => item.inService === 0);
                              break;
                          case 'All':
                          default:
                              filteredData = data; // No filter applied
                              break;
                      }
      
                    // console.log("Filtered data:", filteredData);
                      this.setState({
                          facInvCollector: filteredData,
                          facInvCollectorDefault: filteredData,
                          isLoaded: true,
                          isLoaded2: true
                      });
                  });
          } else {
              let myURL = apiURL + 'ItemMaster/FacilityAsset';
              fetch(myURL, apiHeader('GET'), { mode: 'no-cors' })
                  .then(res => res.json())
                  .then(json => {
                      // console.log("Fetched facility asset data:", json);
                      this.setState({
                          isLoaded: true,
                          isLoaded2: true,
                          facInvCollector: json,
                          facInvCollectorDefault: json
                      });
                  });
          }
      };


      fetchDetailData = () => {
        let myURL = apiURL + 'ItemMaster/FacilityInventoryCollector';
        fetch(myURL, apiHeader('GET'), { mode: 'no-cors' })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    detailedData: data,
                    isDetailLoaded: true
                });
            });
    }


    getItemType=(view) =>{

        let myURI = apiURL + 'ItemMaster/ItemTypeFacilityAsset'
        
   
        fetch(myURI, apiHeader('GET') , { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                this.setState({
                  isLoaded: true,
                  itemType: json
                });

            });
      


    }

    getItemPropertyMap=() =>{

        let myURI = apiURL + 'ItemMaster/ItemPropertyMap'
        

        fetch(myURI, apiHeader('GET') , { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {
                
                this.setState({
                    isItemMapLoaded: true,
                    itemPropertyMap:json         
                })
               

            });
           
    }


    createInventoryForm(){

        const inventoryCreate={
            
            "facilityInvCollectorKey": -1,
            "itemTypeKey": this.state.itemTypeKey,
            "itemMasterKey": 0,
            "facilityKey": null,
            "itemDesc": "",
            "itemTypeDesc": this.state.itemTypeTitle,
            "qty": 1,
            "uomKey": 0,
            "qrCodeValue": "XXXXXX",
            "comments": "",
            "createdDatetime": new Date().toISOString(),
            "createdby": this.props.user.account.name,
            "lat": 0,
            "long": 0,
            "offLineLinkKey": 0,
            "inService":0,
            "locationKey": null,
            "prop1": "",
            "prop2": "",
            "prop3": "",
            "prop4": "",
            "prop5": "",
            "prop6": "",
            "prop7": "",
            "prop8": "",
            "prop9": "",
            "prop10":  "",
            "prop11":  "",
            "prop12":  "",
            "prop13":  "",
            "prop14":  "",
            "prop15":  "",
            "prop16":  "",
            "prop17":  "",
            "prop18":  "",
            "prop19":  "",
            "prop20":  "",
            "prop21":  "",
            "prop22":  "",
            "prop23":  "",
            "prop24":  "",
            "prop25":  "",
            "prop26":  "",
            "prop27":  "",
            "prop28":  "",
            "prop29":  "",
            "prop30":  "",
            "prop31":  "",
            "prop32":  "",
            "prop33":  "",
            "prop34":  "",
            "prop35":  "",
            "prop36":  "",
            "prop37":  "",
            "prop38":  "",
            "prop39":  "",
            "prop40": "",
            

        }
        this.setState({facilityKey: null})
        return inventoryCreate
        
    }

  

    toggleItemType=()=>{
        this.setState({modalItemType: !this.state.modalItemType, isTyping: false})
        
        // console.log(this.state.modalItemType)
    }
    
    
    addItemTypeModal=()=>{
        this.setState({facilityInvCollectorKey: -1})
        this.toggleItemType()
    }


    handleResize = (e) => {
        this.setState({ windowHeight: window.innerHeight });
    };


    componentDidMount() {
        // Initial setup when the component mounts
        window.addEventListener("resize", this.handleResize);
        this.fetchInitialData();
        this.updateViewFromURL();
        document.addEventListener('click', this.handleClickOutside);

        this.fetchDetailData();
      }

    

      componentDidUpdate(prevProps, prevState) {
        const {currentView} = this.state
        
        // Respond to changes in props, like a change in the URL
        if (this.props.location.search !== prevProps.location.search) {
          this.fetchInitialData();
          this.updateViewFromURL();
        }

        if (currentView !== prevState.currentView) {
          this.setState({ shouldReloadGrid: true });
        }

          if (!this.state.isGridLoaded) {
          this.loadGridLayout();
        }
      


      }

      updateViewFromURL() {
        const queryParams = new URLSearchParams(this.props.location.search);
        const view = queryParams.get('view');
        // console.log(view)
        this.setState({ currentView: view });
      }
      
      fetchInitialData() {
        // Get the 'view' parameter from the URL
        const queryParams = new URLSearchParams(this.props.location.search);
        const view = queryParams.get('view');
        // Fetch data based on the 'view'
        this.getFacInvCollector(view, this.state.statusType);

        // Fetch other necessary data for the component
        this.getItemType();
        this.getItemPropertyMap();
        this.getSecurity();
      }
      
      // Make sure to clean up listeners when the component unmounts
      componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
        document.removeEventListener('click', this.handleClickOutside);
      }

      handleClickOutside = (event) => {
        // Ensure that this.searchInput.current is not null
        if (this.searchInput.current && !this.searchInput.current.contains(event.target)) {
          // Clicked outside the search input, so set isTyping to false
          this.setState({ isTyping: false });
        }
      };

    
    onReportDateUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("a");
            let cell = document.createElement("div");

       
            //content.style.display = "inline-block";
            content.style.display = "inline-grid";
            content.style.fontFamily = "Verdana";
            content.style.fontSize = "12px";
            // content.style.color = "#4286f4";
             content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];
        }

        //link.href = "tel:" + item.inspectionNo;
        // link.href = "/facility-insp-dtl/" + item.facilityInspectionHdrKey;
        // link.target = "_blank"
        link.textContent = new Date(item.createdDateTime).toLocaleDateString("es-PA");
    }

    onStatusCellUpdating(s, e) {
      const content = e.content;
      const info = e.cellInfo;
      const item = info.rowItem;

      let link;
      if (content.childElementCount === 0) {
          link = document.createElement("label");
          let cell = document.createElement("div");

          content.style.fontFamily = "Verdana";
          content.style.fontSize = "13px";

          content.style.margin = "0px -20px -10px -12px";
          content.style.padding = "0px 10px 10px 10px";

          content.appendChild(link);

      }
      else {
          link = content.children[0];

          content.style.background = "transparent";

      }




      switch (s.field) {
          case 'modifiedBy':
            if (item.modifiedBy) { // If not null or undefined, then do the conversion
              link.textContent = item.modifiedBy.split('.')
                .map(part => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
                .join(' ');
            } else { // If it is null or undefined, then don't convert and set a default text or leave it empty
              link.textContent = item.modifiedBy || ''; // or some default text like 'N/A'
            }
              break;

          case 'createdDatetime':
            link.textContent = item.createdDatetime !== null ? moment(item.createdDatetime).format("YYYY-MM-DD HH:mm:ss") : ''
            break;

          case 'modifiedDatetime':
              link.textContent = item.modifiedDatetime !== null ? moment(item.modifiedDatetime).format("YYYY-MM-DD HH:mm:ss") : ''
            break;            


          default:

      }

  }

   
  onFilter = (e) => {
    const filterValue = e.target.value;
    let newDisplayData = [];

    if (filterValue === "test") {
        this.setState({
            filterBool: true,
            filterType: filterValue,
            filterColumns: this.state.filterColumns, // Keeping the existing filterColumns or set to a default value if needed
            facInvCollector: this.state.facInvCollectorDefault // reset to default data if "test" is selected
        });
    } else {
        newDisplayData = this.state.facInvCollectorDefault.filter(a => a.itemTypeKey === Number(filterValue));
        this.setState({
            filterBool: false,
            filterType: filterValue,
            filterColumns: filterValue, // Set filterColumns to the current filter value
            facInvCollector: newDisplayData.length > 0 ? newDisplayData : this.state.facInvCollectorDefault // if no items match, reset to default
        });
    }
};

           


            prop1Header = () => {
                const { itemPropertyMap, filterColumns, currentView } = this.state;
              
                // Determine the itemTypeKey based on the currentView
                const itemTypeKey = currentView === "fleet" ? 1171 : filterColumns;
                const test = itemPropertyMap.filter(entry => (entry.itemTypeKey === parseInt(itemTypeKey) && entry.propField === "Prop1"));
              
                try {
                  if (test.length > 0) {
                    return test[0].itemField;
                  } else {
                    return "";
                  }
                } catch {
                  return "";
                }
              }

            
            prop2Header = () => {
                const { itemPropertyMap, filterColumns, currentView } = this.state;
              
                // Determine the itemTypeKey based on the currentView
                const itemTypeKey = currentView === "fleet" ? 1171 : filterColumns;
              
                // Filter based on the determined itemTypeKey and propField
                const test = itemPropertyMap.filter(entry => (entry.itemTypeKey === parseInt(itemTypeKey) && entry.propField === "Prop2"));
              
                try {
                  if (test.length > 0) {
                    return test[0].itemField;
                  } else {
                    return "";
                  }
                } catch {
                  return "";
                }
              }



            prop3Header = () => {
                const { itemPropertyMap, filterColumns, currentView } = this.state;
              
                // Determine the itemTypeKey based on the currentView
                const itemTypeKey = currentView === "fleet" ? 1171 : filterColumns;
              
                // Filter based on the determined itemTypeKey and propField
                const test = itemPropertyMap.filter(entry => (entry.itemTypeKey === parseInt(itemTypeKey) && entry.propField === "Prop3"));
              
                try {
                  if (test.length > 0) {
                    return test[0].itemField;
                  } else {
                    return "";
                  }
                } catch {
                  return "";
                }
              }

            prop4Header=()=>{
                const { itemPropertyMap, filterColumns, currentView } = this.state;
              
                // Determine the itemTypeKey based on the currentView
                const itemTypeKey = currentView === "fleet" ? 1171 : filterColumns;
              
                // Filter based on the determined itemTypeKey and propField
                const test = itemPropertyMap.filter(entry => (entry.itemTypeKey === parseInt(itemTypeKey) && entry.propField === "Prop4"));
              
                try {
                  if (test.length > 0) {
                    return test[0].itemField;
                  } else {
                    return "";
                  }
                } catch {
                  return "";
                }
               
            }

            prop5Header=()=>{
                const { itemPropertyMap, filterColumns, currentView } = this.state;
              
                // Determine the itemTypeKey based on the currentView
                const itemTypeKey = currentView === "fleet" ? 1171 : filterColumns;
              
                // Filter based on the determined itemTypeKey and propField
                const test = itemPropertyMap.filter(entry => (entry.itemTypeKey === parseInt(itemTypeKey) && entry.propField === "Prop5"));
              
                try {
                  if (test.length > 0) {
                    return test[0].itemField;
                  } else {
                    return "";
                  }
                } catch {
                  return "";
                }
               
            }

            prop6Header=()=>{
                const { itemPropertyMap, filterColumns, currentView } = this.state;
              
                // Determine the itemTypeKey based on the currentView
                const itemTypeKey = currentView === "fleet" ? 1171 : filterColumns;
              
                // Filter based on the determined itemTypeKey and propField
                const test = itemPropertyMap.filter(entry => (entry.itemTypeKey === parseInt(itemTypeKey) && entry.propField === "Prop6"));
              
                try {
                  if (test.length > 0) {
                    return test[0].itemField;
                  } else {
                    return "";
                  }
                } catch {
                  return "";
                }
               
            }

            prop7Header=()=>{
                const { itemPropertyMap, filterColumns, currentView } = this.state;
              
                // Determine the itemTypeKey based on the currentView
                const itemTypeKey = currentView === "fleet" ? 1171 : filterColumns;
              
                // Filter based on the determined itemTypeKey and propField
                const test = itemPropertyMap.filter(entry => (entry.itemTypeKey === parseInt(itemTypeKey) && entry.propField === "Prop7"));
              
                try {
                  if (test.length > 0) {
                    return test[0].itemField;
                  } else {
                    return "";
                  }
                } catch {
                  return "";
                }
               
            }

            prop8Header=()=>{
                const { itemPropertyMap, filterColumns, currentView } = this.state;
              
                // Determine the itemTypeKey based on the currentView
                const itemTypeKey = currentView === "fleet" ? 1171 : filterColumns;
              
                // Filter based on the determined itemTypeKey and propField
                const test = itemPropertyMap.filter(entry => (entry.itemTypeKey === parseInt(itemTypeKey) && entry.propField === "Prop8"));
              
                try {
                  if (test.length > 0) {
                    return test[0].itemField;
                  } else {
                    return "";
                  }
                } catch {
                  return "";
                }
               
            }

            prop9Header=()=>{
                const { itemPropertyMap, filterColumns, currentView } = this.state;
              
                // Determine the itemTypeKey based on the currentView
                const itemTypeKey = currentView === "fleet" ? 1171 : filterColumns;
              
                // Filter based on the determined itemTypeKey and propField
                const test = itemPropertyMap.filter(entry => (entry.itemTypeKey === parseInt(itemTypeKey) && entry.propField === "Prop9"));
              
                try {
                  if (test.length > 0) {
                    return test[0].itemField;
                  } else {
                    return "";
                  }
                } catch {
                  return "";
                }
               
            }
            prop10Header=()=>{
                const { itemPropertyMap, filterColumns, currentView } = this.state;
              
                // Determine the itemTypeKey based on the currentView
                const itemTypeKey = currentView === "fleet" ? 1171 : filterColumns;
              
                // Filter based on the determined itemTypeKey and propField
                const test = itemPropertyMap.filter(entry => (entry.itemTypeKey === parseInt(itemTypeKey) && entry.propField === "Prop10"));
              
                try {
                  if (test.length > 0) {
                    return test[0].itemField;
                  } else {
                    return "";
                  }
                } catch {
                  return "";
                }
               
            }

            prop11Header=()=>{
                const { itemPropertyMap, filterColumns, currentView } = this.state;
              
                // Determine the itemTypeKey based on the currentView
                const itemTypeKey = currentView === "fleet" ? 1171 : filterColumns;
              
                // Filter based on the determined itemTypeKey and propField
                const test = itemPropertyMap.filter(entry => (entry.itemTypeKey === parseInt(itemTypeKey) && entry.propField === "Prop11"));
              
                try {
                  if (test.length > 0) {
                    return test[0].itemField;
                  } else {
                    return "";
                  }
                } catch {
                  return "";
                }
               
            }

            prop12Header=()=>{
                const { itemPropertyMap, filterColumns, currentView } = this.state;
              
                // Determine the itemTypeKey based on the currentView
                const itemTypeKey = currentView === "fleet" ? 1171 : filterColumns;
              
                // Filter based on the determined itemTypeKey and propField
                const test = itemPropertyMap.filter(entry => (entry.itemTypeKey === parseInt(itemTypeKey) && entry.propField === "Prop12"));
              
                try {
                  if (test.length > 0) {
                    return test[0].itemField;
                  } else {
                    return "";
                  }
                } catch {
                  return "";
                }
               
            }

            prop13Header=()=>{
                const { itemPropertyMap, filterColumns, currentView } = this.state;
              
                // Determine the itemTypeKey based on the currentView
                const itemTypeKey = currentView === "fleet" ? 1171 : filterColumns;
              
                // Filter based on the determined itemTypeKey and propField
                const test = itemPropertyMap.filter(entry => (entry.itemTypeKey === parseInt(itemTypeKey) && entry.propField === "Prop13"));
              
                try {
                  if (test.length > 0) {
                    return test[0].itemField;
                  } else {
                    return "";
                  }
                } catch {
                  return "";
                }
               
            }

            prop14Header=()=>{
                const { itemPropertyMap, filterColumns, currentView } = this.state;
              
                // Determine the itemTypeKey based on the currentView
                const itemTypeKey = currentView === "fleet" ? 1171 : filterColumns;
              
                // Filter based on the determined itemTypeKey and propField
                const test = itemPropertyMap.filter(entry => (entry.itemTypeKey === parseInt(itemTypeKey) && entry.propField === "Prop14"));
              
                try {
                  if (test.length > 0) {
                    return test[0].itemField;
                  } else {
                    return "";
                  }
                } catch {
                  return "";
                }
               
            }

            prop15Header=()=>{
                const { itemPropertyMap, filterColumns, currentView } = this.state;
              
                // Determine the itemTypeKey based on the currentView
                const itemTypeKey = currentView === "fleet" ? 1171 : filterColumns;
              
                // Filter based on the determined itemTypeKey and propField
                const test = itemPropertyMap.filter(entry => (entry.itemTypeKey === parseInt(itemTypeKey) && entry.propField === "Prop15"));
              
                try {
                  if (test.length > 0) {
                    return test[0].itemField;
                  } else {
                    return "";
                  }
                } catch {
                  return "";
                }
               
            }

            prop16Header=()=>{
                const { itemPropertyMap, filterColumns, currentView } = this.state;
              
                // Determine the itemTypeKey based on the currentView
                const itemTypeKey = currentView === "fleet" ? 1171 : filterColumns;
              
                // Filter based on the determined itemTypeKey and propField
                const test = itemPropertyMap.filter(entry => (entry.itemTypeKey === parseInt(itemTypeKey) && entry.propField === "Prop16"));
              
                try {
                  if (test.length > 0) {
                    return test[0].itemField;
                  } else {
                    return "";
                  }
                } catch {
                  return "";
                }
               
            }

            prop17Header=()=>{
                const { itemPropertyMap, filterColumns, currentView } = this.state;
              
                // Determine the itemTypeKey based on the currentView
                const itemTypeKey = currentView === "fleet" ? 1171 : filterColumns;
              
                // Filter based on the determined itemTypeKey and propField
                const test = itemPropertyMap.filter(entry => (entry.itemTypeKey === parseInt(itemTypeKey) && entry.propField === "Prop17"));
              
                try {
                  if (test.length > 0) {
                    return test[0].itemField;
                  } else {
                    return "";
                  }
                } catch {
                  return "";
                }
               
            }

            prop18Header=()=>{
                const { itemPropertyMap, filterColumns, currentView } = this.state;
              
                // Determine the itemTypeKey based on the currentView
                const itemTypeKey = currentView === "fleet" ? 1171 : filterColumns;
              
                // Filter based on the determined itemTypeKey and propField
                const test = itemPropertyMap.filter(entry => (entry.itemTypeKey === parseInt(itemTypeKey) && entry.propField === "Prop18"));
              
                try {
                  if (test.length > 0) {
                    return test[0].itemField;
                  } else {
                    return "";
                  }
                } catch {
                  return "";
                }
               
            }

            prop19Header=()=>{
                const { itemPropertyMap, filterColumns, currentView } = this.state;
              
                // Determine the itemTypeKey based on the currentView
                const itemTypeKey = currentView === "fleet" ? 1171 : filterColumns;
              
                // Filter based on the determined itemTypeKey and propField
                const test = itemPropertyMap.filter(entry => (entry.itemTypeKey === parseInt(itemTypeKey) && entry.propField === "Prop19"));
              
                try {
                  if (test.length > 0) {
                    return test[0].itemField;
                  } else {
                    return "";
                  }
                } catch {
                  return "";
                }
               
            }

            prop20Header=()=>{
                const { itemPropertyMap, filterColumns, currentView } = this.state;
              
                // Determine the itemTypeKey based on the currentView
                const itemTypeKey = currentView === "fleet" ? 1171 : filterColumns;
              
                // Filter based on the determined itemTypeKey and propField
                const test = itemPropertyMap.filter(entry => (entry.itemTypeKey === parseInt(itemTypeKey) && entry.propField === "Prop20"));
              
                try {
                  if (test.length > 0) {
                    return test[0].itemField;
                  } else {
                    return "";
                  }
                } catch {
                  return "";
                }
               
            }

            prop21Header=()=>{
                const { itemPropertyMap, filterColumns, currentView } = this.state;
              
                // Determine the itemTypeKey based on the currentView
                const itemTypeKey = currentView === "fleet" ? 1171 : filterColumns;
              
                // Filter based on the determined itemTypeKey and propField
                const test = itemPropertyMap.filter(entry => (entry.itemTypeKey === parseInt(itemTypeKey) && entry.propField === "Prop21"));
              
                try {
                  if (test.length > 0) {
                    return test[0].itemField;
                  } else {
                    return "";
                  }
                } catch {
                  return "";
                }
               
            }

            prop22Header=()=>{
                const { itemPropertyMap, filterColumns, currentView } = this.state;
              
                // Determine the itemTypeKey based on the currentView
                const itemTypeKey = currentView === "fleet" ? 1171 : filterColumns;
              
                // Filter based on the determined itemTypeKey and propField
                const test = itemPropertyMap.filter(entry => (entry.itemTypeKey === parseInt(itemTypeKey) && entry.propField === "Prop22"));
              
                try {
                  if (test.length > 0) {
                    return test[0].itemField;
                  } else {
                    return "";
                  }
                } catch {
                  return "";
                }
               
            }
            prop23Header=()=>{
                const { itemPropertyMap, filterColumns, currentView } = this.state;
              
                // Determine the itemTypeKey based on the currentView
                const itemTypeKey = currentView === "fleet" ? 1171 : filterColumns;
              
                // Filter based on the determined itemTypeKey and propField
                const test = itemPropertyMap.filter(entry => (entry.itemTypeKey === parseInt(itemTypeKey) && entry.propField === "Prop23"));
              
                try {
                  if (test.length > 0) {
                    return test[0].itemField;
                  } else {
                    return "";
                  }
                } catch {
                  return "";
                }
               
            }
            prop24Header=()=>{
                const { itemPropertyMap, filterColumns, currentView } = this.state;
              
                // Determine the itemTypeKey based on the currentView
                const itemTypeKey = currentView === "fleet" ? 1171 : filterColumns;
              
                // Filter based on the determined itemTypeKey and propField
                const test = itemPropertyMap.filter(entry => (entry.itemTypeKey === parseInt(itemTypeKey) && entry.propField === "Prop24"));
              
                try {
                  if (test.length > 0) {
                    return test[0].itemField;
                  } else {
                    return "";
                  }
                } catch {
                  return "";
                }
               
            }

            prop25Header=()=>{
                const { itemPropertyMap, filterColumns, currentView } = this.state;
              
                // Determine the itemTypeKey based on the currentView
                const itemTypeKey = currentView === "fleet" ? 1171 : filterColumns;
              
                // Filter based on the determined itemTypeKey and propField
                const test = itemPropertyMap.filter(entry => (entry.itemTypeKey === parseInt(itemTypeKey) && entry.propField === "Prop25"));
              
                try {
                  if (test.length > 0) {
                    return test[0].itemField;
                  } else {
                    return "";
                  }
                } catch {
                  return "";
                }
               
            }

            prop26Header=()=>{
                const { itemPropertyMap, filterColumns, currentView } = this.state;
              
                // Determine the itemTypeKey based on the currentView
                const itemTypeKey = currentView === "fleet" ? 1171 : filterColumns;
              
                // Filter based on the determined itemTypeKey and propField
                const test = itemPropertyMap.filter(entry => (entry.itemTypeKey === parseInt(itemTypeKey) && entry.propField === "Prop26"));
              
                try {
                  if (test.length > 0) {
                    return test[0].itemField;
                  } else {
                    return "";
                  }
                } catch {
                  return "";
                }
               
            }

            prop27Header=()=>{
                const { itemPropertyMap, filterColumns, currentView } = this.state;
              
                // Determine the itemTypeKey based on the currentView
                const itemTypeKey = currentView === "fleet" ? 1171 : filterColumns;
              
                // Filter based on the determined itemTypeKey and propField
                const test = itemPropertyMap.filter(entry => (entry.itemTypeKey === parseInt(itemTypeKey) && entry.propField === "Prop27"));
              
                try {
                  if (test.length > 0) {
                    return test[0].itemField;
                  } else {
                    return "";
                  }
                } catch {
                  return "";
                }
               
            }

            prop28Header=()=>{
                const { itemPropertyMap, filterColumns, currentView } = this.state;
              
                // Determine the itemTypeKey based on the currentView
                const itemTypeKey = currentView === "fleet" ? 1171 : filterColumns;
              
                // Filter based on the determined itemTypeKey and propField
                const test = itemPropertyMap.filter(entry => (entry.itemTypeKey === parseInt(itemTypeKey) && entry.propField === "Prop28"));
              
                try {
                  if (test.length > 0) {
                    return test[0].itemField;
                  } else {
                    return "";
                  }
                } catch {
                  return "";
                }
               
            }

            prop29Header=()=>{
                const { itemPropertyMap, filterColumns, currentView } = this.state;
              
                // Determine the itemTypeKey based on the currentView
                const itemTypeKey = currentView === "fleet" ? 1171 : filterColumns;
              
                // Filter based on the determined itemTypeKey and propField
                const test = itemPropertyMap.filter(entry => (entry.itemTypeKey === parseInt(itemTypeKey) && entry.propField === "Prop29"));
              
                try {
                  if (test.length > 0) {
                    return test[0].itemField;
                  } else {
                    return "";
                  }
                } catch {
                  return "";
                }
               
            }

            prop30Header=()=>{
                const { itemPropertyMap, filterColumns, currentView } = this.state;
              
                // Determine the itemTypeKey based on the currentView
                const itemTypeKey = currentView === "fleet" ? 1171 : filterColumns;
              
                // Filter based on the determined itemTypeKey and propField
                const test = itemPropertyMap.filter(entry => (entry.itemTypeKey === parseInt(itemTypeKey) && entry.propField === "Prop30"));
              
                try {
                  if (test.length > 0) {
                    return test[0].itemField;
                  } else {
                    return "";
                  }
                } catch {
                  return "";
                }
               
            }

            prop31Header=()=>{
                const { itemPropertyMap, filterColumns, currentView } = this.state;
              
                // Determine the itemTypeKey based on the currentView
                const itemTypeKey = currentView === "fleet" ? 1171 : filterColumns;
              
                // Filter based on the determined itemTypeKey and propField
                const test = itemPropertyMap.filter(entry => (entry.itemTypeKey === parseInt(itemTypeKey) && entry.propField === "Prop31"));
              
                try {
                  if (test.length > 0) {
                    return test[0].itemField;
                  } else {
                    return "";
                  }
                } catch {
                  return "";
                }
               
            }

            prop32Header=()=>{
                const { itemPropertyMap, filterColumns, currentView } = this.state;
              
                // Determine the itemTypeKey based on the currentView
                const itemTypeKey = currentView === "fleet" ? 1171 : filterColumns;
              
                // Filter based on the determined itemTypeKey and propField
                const test = itemPropertyMap.filter(entry => (entry.itemTypeKey === parseInt(itemTypeKey) && entry.propField === "Prop32"));
              
                try {
                  if (test.length > 0) {
                    return test[0].itemField;
                  } else {
                    return "";
                  }
                } catch {
                  return "";
                }
               
            }

            prop33Header=()=>{
                const { itemPropertyMap, filterColumns, currentView } = this.state;
              
                // Determine the itemTypeKey based on the currentView
                const itemTypeKey = currentView === "fleet" ? 1171 : filterColumns;
              
                // Filter based on the determined itemTypeKey and propField
                const test = itemPropertyMap.filter(entry => (entry.itemTypeKey === parseInt(itemTypeKey) && entry.propField === "Prop33"));
              
                try {
                  if (test.length > 0) {
                    return test[0].itemField;
                  } else {
                    return "";
                  }
                } catch {
                  return "";
                }
               
            }

            prop34Header=()=>{
                const { itemPropertyMap, filterColumns, currentView } = this.state;
              
                // Determine the itemTypeKey based on the currentView
                const itemTypeKey = currentView === "fleet" ? 1171 : filterColumns;
              
                // Filter based on the determined itemTypeKey and propField
                const test = itemPropertyMap.filter(entry => (entry.itemTypeKey === parseInt(itemTypeKey) && entry.propField === "Prop34"));
              
                try {
                  if (test.length > 0) {
                    return test[0].itemField;
                  } else {
                    return "";
                  }
                } catch {
                  return "";
                }
               
            }

            prop35Header=()=>{
                const { itemPropertyMap, filterColumns, currentView } = this.state;
              
                // Determine the itemTypeKey based on the currentView
                const itemTypeKey = currentView === "fleet" ? 1171 : filterColumns;
              
                // Filter based on the determined itemTypeKey and propField
                const test = itemPropertyMap.filter(entry => (entry.itemTypeKey === parseInt(itemTypeKey) && entry.propField === "Prop35"));
              
                try {
                  if (test.length > 0) {
                    return test[0].itemField;
                  } else {
                    return "";
                  }
                } catch {
                  return "";
                }
               
            }

            prop36Header=()=>{
                const { itemPropertyMap, filterColumns, currentView } = this.state;
              
                // Determine the itemTypeKey based on the currentView
                const itemTypeKey = currentView === "fleet" ? 1171 : filterColumns;
              
                // Filter based on the determined itemTypeKey and propField
                const test = itemPropertyMap.filter(entry => (entry.itemTypeKey === parseInt(itemTypeKey) && entry.propField === "Prop36"));
              
                try {
                  if (test.length > 0) {
                    return test[0].itemField;
                  } else {
                    return "";
                  }
                } catch {
                  return "";
                }
               
            }

            prop37Header=()=>{
                const { itemPropertyMap, filterColumns, currentView } = this.state;
              
                // Determine the itemTypeKey based on the currentView
                const itemTypeKey = currentView === "fleet" ? 1171 : filterColumns;
              
                // Filter based on the determined itemTypeKey and propField
                const test = itemPropertyMap.filter(entry => (entry.itemTypeKey === parseInt(itemTypeKey) && entry.propField === "Prop37"));
              
                try {
                  if (test.length > 0) {
                    return test[0].itemField;
                  } else {
                    return "";
                  }
                } catch {
                  return "";
                }
               
            }

            prop38Header=()=>{
                const { itemPropertyMap, filterColumns, currentView } = this.state;
              
                // Determine the itemTypeKey based on the currentView
                const itemTypeKey = currentView === "fleet" ? 1171 : filterColumns;
              
                // Filter based on the determined itemTypeKey and propField
                const test = itemPropertyMap.filter(entry => (entry.itemTypeKey === parseInt(itemTypeKey) && entry.propField === "Prop38"));
              
                try {
                  if (test.length > 0) {
                    return test[0].itemField;
                  } else {
                    return "";
                  }
                } catch {
                  return "";
                }
               
            }

            prop39Header=()=>{
                const { itemPropertyMap, filterColumns, currentView } = this.state;
              
                // Determine the itemTypeKey based on the currentView
                const itemTypeKey = currentView === "fleet" ? 1171 : filterColumns;
              
                // Filter based on the determined itemTypeKey and propField
                const test = itemPropertyMap.filter(entry => (entry.itemTypeKey === parseInt(itemTypeKey) && entry.propField === "Prop39"));
              
                try {
                  if (test.length > 0) {
                    return test[0].itemField;
                  } else {
                    return "";
                  }
                } catch {
                  return "";
                }
               
            }

            prop40Header=()=>{
                const { itemPropertyMap, filterColumns, currentView } = this.state;
              
                // Determine the itemTypeKey based on the currentView
                const itemTypeKey = currentView === "fleet" ? 1171 : filterColumns;
              
                // Filter based on the determined itemTypeKey and propField
                const test = itemPropertyMap.filter(entry => (entry.itemTypeKey === parseInt(itemTypeKey) && entry.propField === "Prop40"));
              
                try {
                  if (test.length > 0) {
                    return test[0].itemField;
                  } else {
                    return "";
                  }
                } catch {
                  return "";
                }
               
            }

                                  
           
       toggleEdit=()=>{
        this.toggle()
       }


       showProps = () => {
        const { currentView, filterBool } = this.state;
        const columns = [];
    //   console.log(currentView)
        if (currentView !== 'fleet') {
            columns.push(
                <IgrTextColumn field="itemDesc" headerText="Item Description" width="*>200" 
             />,

            <IgrTextColumn field="facilityName" headerText="Facility Name" width="*>200" 
              />,
   
                            
            
            <IgrTextColumn field="itemTypeDesc" headerText="Item Type Description" width="*>200"
              />,


               
                 <IgrTextColumn field="qrCodeValue" headerText="QR Code" width="*>200"
              /> ,
   

            <IgrTextColumn field="inService" headerText="In Service" width="150"
            /> ,

            <IgrTextColumn field="assetID" headerText="Asset ID" width="*>200"
              />, 

             <IgrTextColumn field="locationID" headerText="Location ID" width="*>200"
              />,  

            <IgrTextColumn field="locationName" headerText="Location Name" width="*>200"
              />,                           

              <IgrTextColumn field="workdaySiteID" headerText="Workday Site ID" width="*>200"
            />,

            <IgrTextColumn field="lat" headerText="Lat" width="*>200"
            />,

            <IgrTextColumn field="long" headerText="Long" width="*>200"
            />,

            <IgrTextColumn field="createdby" headerText="Created by" width="150"
            />, 

            <IgrTemplateColumn field="createdDatetime" headerText="Created Date" width="200" cellUpdating={this.onStatusCellUpdating}
            />, 

            <IgrTemplateColumn field="modifiedBy" headerText="Modified by" width="150" cellUpdating={this.onStatusCellUpdating}
            />, 

            <IgrTemplateColumn field="modifiedDatetime" headerText="Modified Date" width="200" cellUpdating={this.onStatusCellUpdating}
            /> 
            )
            for (let i = 1; i <= 40; i++) {
                const field = `prop${i}`;
                const headerText = this[`prop${i}Header`]?.(); // Calling the dynamic header method
            
                columns.push(
                  <IgrTextColumn
                    key={field}
                    field={field}
                    headerText={headerText}
                    width="*>200"
                    isHidden={filterBool || headerText === ""}
                  />
                );
              }
        //   columns.push(
        //     <IgrTextColumn
        //       field="prop1"
        //       headerText={this.prop1Header()}
        //       width="*>200"
        //       isHidden={filterBool || this.prop1Header() === ""}
        //     />
        //   );
        } else {
          columns.push(
            <IgrTextColumn
              field="prop16" //Assigned Driver
              headerText={this.prop16Header()}
              width="200"
            />,
            <IgrTextColumn
            field="prop31" //Assigned Driver TFS
            headerText={this.prop31Header()}
            width="200"
          />,
            <IgrTextColumn
              field="prop7"
              headerText={this.prop7Header()} //WaterBridge Truck #W
              width="220"
            />,
            <IgrTextColumn
              field="prop18"
              headerText={this.prop18Header()} //Status
              width="200"
            />,
            <IgrTextColumn
              field="prop1"
              headerText={this.prop1Header()} //Year
              width="200"
            />,
            <IgrTextColumn
              field="prop2"
              headerText={this.prop2Header()} //Make
              width="200"
            />,
            <IgrTextColumn
              field="prop3"
              headerText={this.prop3Header()} //Model
              width="200"
            />,
            <IgrTextColumn
              field="prop6"
              // headerText={this.prop6Header()} //VIN
              headerText="VIN / Serial Number"
              width="200"
            />,
            <IgrTextColumn
              field="prop14"
              headerText={this.prop14Header()} //Samsara Mileage
              width="200"
            />,
            <IgrTextColumn
              field="prop8"
              headerText={this.prop8Header()} //Samsara block #
              width="200"
            />,
            <IgrTextColumn
              field="prop23"
              headerText={this.prop23Header()} //Geotab #
              width="200"
            />,
            <IgrTextColumn
              field="qrCodeValue"
              headerText="QR Code"
              width="*>200"
            />,
            <IgrTextColumn
              field="prop11"
              headerText={this.prop11Header()} //Lease Unit Number
              width="220"
            />,
            <IgrTextColumn
              field="prop9"
              headerText={this.prop9Header()} //Registration State
              width="200"
            />,
            <IgrTextColumn
              field="prop5"
              headerText={this.prop5Header()} //License Plate #
              width="200"
            />,
            <IgrTextColumn //Reg EXP
              field="prop20"
              headerText={this.prop20Header()}
              width="220"
            />,
            <IgrTextColumn //AREA
              field="locationID"
              headerText="Location ID (Area)"
              width="200"
            />,
            <IgrTextColumn //Ownership
              field="prop10"
              headerText={this.prop10Header()}
              width="200"
            />,
            <IgrTextColumn
              field="prop12"
              headerText={this.prop12Header()} //Leasing Company
              width="250"
            />,

            <IgrTextColumn //Received Date
              field="prop19"
              headerText={this.prop19Header()}
              width="200"
            />,

            <IgrTextColumn //Diposed Date
              field="prop24"
              headerText={this.prop24Header()}
              width="200"
            />,

            <IgrTextColumn //Comments
              field="prop21"
              headerText={this.prop21Header()}
              width="200"
            />,

            <IgrTextColumn
              field="itemDesc"
              headerText="Item Description"
              width="*>200"
            />,
            <IgrTextColumn
              field="itemTypeDesc"
              headerText="Item Type"
              width="*>200"
            />,

            <IgrTextColumn
              field="locationName"
              headerText="Location"
              width="*>200"
            />,
            <IgrTextColumn
              field="inService"
              headerText="In Service"
              width="150"
            />,
            <IgrTextColumn
              field="createdby"
              headerText="Created By"
              width="150"
            />,
            <IgrTemplateColumn
              field="createdDatetime"
              headerText="Created Date"
              width="200"
              cellUpdating={this.onStatusCellUpdating}
            />,
            <IgrTemplateColumn
              field="modifiedBy"
              headerText="Modified By"
              width="150"
              cellUpdating={this.onStatusCellUpdating}
            />,
            <IgrTemplateColumn
              field="modifiedDatetime"
              headerText="Modified Date"
              width="200"
              cellUpdating={this.onStatusCellUpdating}
            />,
            <IgrTextColumn
            field="wbrEmpID"
            headerText="WBR Emp ID"
            width="200"
           />,
           <IgrTextColumn
           field="costCenter"
           headerText="Cost Center"
           width="200"
           />,
           <IgrTextColumn
           field="assetID"
           headerText="Asset ID"
           width="200"
           />,
           <IgrTextColumn //Business Asset
           field="prop33"
           headerText={this.prop33Header()}
           width="200"
         />,
          //  <IgrTextColumn //Type
          //  field="prop4"
          //  headerText={this.prop4Header()}
          //  width="200"
          //  />,
          //  <IgrTextColumn //ParentQR
          //  field="prop13"
          //  headerText={this.prop13Header()}
          //  width="200"
          //  />,
          //  <IgrTextColumn //Assigned Date
          //  field="prop17"
          //  headerText={this.prop17Header()}
          //  width="200"
          //  />,
          //  <IgrTextColumn //Last DVIR Date
          //  field="prop22"
          //  headerText={this.prop22Header()}
          //  width="200"
          //  />,
          //  <IgrTextColumn //Least Return Damange Cost
          //  field="prop25"
          //  headerText={this.prop25Header()}
          //  width="200"
          //  />,
          //  <IgrTextColumn //Assigned/SoldTo
          //  field="prop26"
          //  headerText={this.prop26Header()}
          //  width="200"
          //  />,
          //  <IgrTextColumn //Auction Proceeds/Sold For
          //  field="prop27"
          //  headerText={this.prop27Header()}
          //  width="200"
          //  />,
          //  <IgrTextColumn //Funds Received
          //  field="prop28"
          //  headerText={this.prop28Header()}
          //  width="200"
          //  />,
          //  <IgrTextColumn //Signed Title Issued Date
          //  field="prop29"
          //  headerText={this.prop29Header()}
          //  width="200"
          //  />,
          //  <IgrTextColumn //Signed Title Issue By
          //  field="prop30"
          //  headerText={this.prop30Header()}
          //  width="200"
          //  />,
            
          );
          
        }
      
        return columns;
      }

      handleDropdownChange = (event) => {
        const statusType = event.target.value;
        // console.log("Dropdown filter changed to:", statusType);
        this.setState({ statusType }, () => {
          this.getFacInvCollector(this.state.currentView, statusType);
        });
      };

      refreshDataFilter = () => {
        const {facInvCollectorDefault} = this.state
        // console.log("Refreshing data with filter type:", this.state.statusType);
        this.getFacInvCollector(this.state.currentView, this.state.statusType);
        this.saveGridLayout()
        if (this.searchInput.current) {
          // console.log("Ref found, current element:", this.searchInput.current);
          this.searchInput.current.value = '';
        } else {
          // console.log("Ref not found");
        }
        this.setState({ searchText: '', facInvCollector: facInvCollectorDefault, filterType: "test"})
      };

      refreshToggle = () =>{
        this.getFacInvCollector(this.state.currentView, this.state.statusType, );
        this.setState({isLoaded2: false, filterType: "test"})
      }
  


//This somewhat works 1/24/24
// onTextChange = (fieldName, fieldValue, e) => { 
//   const {facInvCollector, facInvCollectorDefault, currentView } = this.state;

//   if (fieldName === "searchText") {
//     let searchText = fieldValue.toLowerCase();
//     if (searchText === "") {
//       this.setState({ facInvCollector: facInvCollectorDefault, searchText: "" });
//       return;
//     }

//     // Define columns to search based on the current view
//     let columnsToSearch = currentView === "fleet"
//         ? ["qrCodeValue", "assetID" , "itemTypeDesc", "facilityName", "createdby", "modifiedBy", "createdDatetime", "modifiedDatetime", "itemDesc", "prop1", "prop2", "prop3", "prop4", "prop5", "prop6", "prop7", "prop8", "prop9", "prop10", "prop11", "prop12", "prop13", "prop16", "prop17", "prop18", "prop19", "prop20", "prop21", "prop22", "prop23", "prop24"]
//         : ["qrCodeValue", "assetID", "itemDesc", "itemTypeDesc", "facilityName"];

//     // Filter logic
//     let filteredData = facInvCollectorDefault.filter(item => {
//       return columnsToSearch.some(column => {
//         if (item[column] && item[column].toString().toLowerCase().includes(searchText)) {
//           return true;
//         }
//         return false;
//       });
//     });

//     this.setState({ facInvCollector: filteredData, searchText: searchText });
//   }
// }


onTextChange = (fieldName, fieldValue, e) => {
  const { filterType, facInvCollectorDefault } = this.state;

  if (fieldName === "searchText") {
    let searchText = fieldValue.toLowerCase();

    if (searchText !== this.state.searchText) {
      this.setState({ searchText: searchText });
      // Additional logic for filtering...
    }
    // this.grid.selectedItems.clear();
    // this.grid.selectedCells.clear();
    // this.grid.deselectAll()

    // Apply filter based on filterType
    let filteredData = facInvCollectorDefault.filter(item => {
      // If a specific filterType is selected, filter by it
      if (filterType !== "test" && item.itemTypeKey !== Number(filterType)) {
        return false;
      }
      // Then apply search text filter
      return this.isItemMatchingSearchText(item, searchText);
    });

    this.setState({ facInvCollector: filteredData, searchText, facilityInvCollectorKey: null, isTyping: true });
  }
};

isItemMatchingSearchText = (item, searchText) => {
  const {currentView} = this.state
  // Define columns to search based on the current view
    let columnsToSearch = currentView === "fleet"
        ? ["qrCodeValue", "assetID" , "itemTypeDesc", "facilityName", "createdby", "modifiedBy", "createdDatetime", "modifiedDatetime", "itemDesc", "prop1", "prop2", "prop3", "prop4", "prop5", "prop6", "prop7", "prop8", "prop9", "prop10", "prop11", "prop12", "prop13", "prop16", "prop17", "prop18", "prop19", "prop20", "prop21", "prop22", "prop23", "prop24"]
        : ["qrCodeValue", "assetID", "itemDesc", "itemTypeDesc", "facilityName"];

  return columnsToSearch.some(column => {
    if (item[column] && item[column].toString().toLowerCase().includes(searchText)) {
      return true;
    }
    return false;
  });
};




resetFilter = () => {
  const {facInvCollectorDefault} = this.state
  // console.log("Reset filter called");

  // Clear filters on the grid
  for (var i = 0; i < this.grid.actualColumns.count; i++) {
      this.grid.actualColumns.item(i).filter = null;
  }
  // console.log(this.grid.filterExpressions)
  this.grid.selectedItems.clear();
  this.grid.filterExpressions.clear();

  if (this.searchInput.current) {
    // console.log("Ref found, current element:", this.searchInput.current);
    this.searchInput.current.value = '';
  } else {
    // console.log("Ref not found");
  }


  // Update the searchText state
  this.setState({ searchText: '', facInvCollector: facInvCollectorDefault, filterType: "test" });
}

// setFocusToSearchInput = () => {
//   const {isTyping} = this.state
//   if(isTyping === false)
//   {
//     return false
//   }
//   else{
//         if (this.searchInput.current) {
//           this.searchInput.current.focus();
//         }
//   }

// }
setFocusToSearchInput = () => {
  if (this.state.isTyping) {
    if (this.searchInput.current) {
      this.searchInput.current.focus();
    }
  }
};

setStateOfParent = (newTitle) => {
  this.setState({ isTyping: newTitle }, () => {
  });
}

    render(){

        var {isLoaded, isItemMapLoaded, windowHeight, data ,isSecurityLoaded} = this.state;
        const {facilityInvCollectorKey, toggle, itemTypeTitle, facInvCollector, 
            itemType, filterType, inventoryData, itemTypeKey, filterBool, itemPropertyMap, isSecurityAdminLogin, facilityKey, currentView, isFleetSupervisors, isLoaded2, isDetailLoaded} = this.state;
        const gridHeight=(windowHeight * 0.7) + "px"
        // const searchText = window.$searchText.toLowerCase()
        if (this.state.shouldReloadGrid) {
          this.loadGridLayout();
          this.setState({ shouldReloadGrid: false }); // Reset the flag
        }
      
        
        if (!isLoaded || !isItemMapLoaded || !isSecurityLoaded || !isDetailLoaded){
            return (<div>
                <h3>Loading Assets .....</h3>
            </div>)
        }else  {
        return (
          <div>
            <FormGroup>
              <Row form className="align-items-center">
                <h2> {currentView === "fleet" ? "Fleet" : "Facility Asset"}</h2>
                <Button
                  outline
                  // hidden={currentView === "fleet"}
                  color="secondary"
                  className="btn-no-border"
                  onClick={this.refreshDataFilter}
                >
                  <img
                    src={RefreshIcon}
                    alt="Refresh"
                    style={{ width: "30px", margin: "0 0 0 0" }}
                  />
                  Refresh
                </Button>

                <Button
                  outline
                  color="secondary"
                  className="btn-no-border"
                  onClick={this.addItemTypeModal}
                  disabled={currentView === "fleet" && isFleetSupervisors}
                >
                  <img
                    src={PlusIcon}
                    alt="Add"
                    style={{
                      width: "25px",
                      height: "25px",
                      margin: "0 0 0 150",
                    }}
                  />
                  Add
                </Button>{" "}
                <Button
                  outline
                  color="secondary"
                  className="btn-no-border"
                  onClick={this.toggleEdit}
                  disabled={
                    facilityInvCollectorKey === null ||
                    facilityInvCollectorKey === -1
                  }
                >
                  <img
                    src={MenuIcon}
                    alt="Revise"
                    style={{ width: "30px", margin: "0 5px 0 0" }}
                  />
                  Edit
                </Button>
                <Button
                  outline
                  color="secondary"
                  className="btn-no-border"
                  onClick={this.saveWorkbook}
                  // onClick={this.saveWorkbook}
                >
                  <img
                    src={ExcelIcon}
                    alt="excel"
                    style={{ width: "30px", height: "30px", margin: "0 0 0 0" }}
                  />
                  Export To Excel
                </Button>{" "}
                <label style={{ margin: "0px 0 0 0" }}  hidden={currentView !== "fleet"} className="mr-2">Truck Status:</label>
                {currentView === "fleet" && (
                  <div className="d-flex align-items-center flex-nowrap">
                    <CustomInput
                      type="select"
                      className="custom-select" // If you have custom styling for select inputs
                      onChange={this.handleDropdownChange}
                    >
                      <option value="Active">In Service</option>
                      <option value="Inactive">Out of Service</option>
                      <option value="All">All</option>
                    </CustomInput>
                  </div>
                )}
                <Button
                  outline
                  color="secondary"
                  hidden={isSecurityAdminLogin || currentView === "fleet"}
                  className="btn-no-border"
                  onClick={this.toggleItemTypeCreator}
                >
                  <img
                    src={EditorIcon}
                    alt="excel"
                    style={{ width: "30px", height: "25px", margin: "0 0 0 0" }}
                  />
                  Item Type Editor
                </Button>{" "}
                <label
                  hidden={currentView === "fleet"}
                  style={{ margin: "0px 0 0 0" }}
                >
                  Filter by:{" "}
                </label>
                <CustomInput
                  hidden={currentView === "fleet"}
                  disabled={!isLoaded2}
                  type="select"
                  value={this.state.filterType}
                  id="test"
                  label="test"
                  onChange={this.onFilter}
                  style={{
                    width: "150px",
                    height: "35px",
                    margin: "5px 0 0 0",
                  }}
                >
                  <option value={"test"}>No Filter</option>
                  {itemType.map((v) => (
                    <option value={v.itemTypeKey}>{v.itemTypeDesc}</option>
                  ))} 
                </CustomInput>
                <Col md className="ml-auto"></Col>
                {/* Column for the "Clear Filter" button */}
                <Col md="auto">
                  <Button
                    outline
                    color="secondary"
                    className="btn-no-border"
                    onClick={this.resetFilter}
                  >
                    <img
                      src={clearfilter}
                      alt="Clear Filter"
                      style={{ width: "30px" }}
                    />
                    Clear Filter
                  </Button>
                </Col>
                {/* Column for the search bar */}
                <Col md className="ml-auto">
                  <input
                    className="my-custom-reactstrap-input"
                    // className='floatRight'
                    ref={this.searchInput}
                    style={{ width: "100%" }}
                    placeholder="Type here to search......."
                    onBlur={this.setFocusToSearchInput}
                    // onBlur={this.handleInputBlur}
                    onChange={(e) =>
                      this.onTextChange("searchText", e.target.value, e)
                    }
                  />
                </Col>

                {/* <Col md="auto">

                  <Button
                    outline
                    color="secondary"
                    className="btn-no-border"
                   hidden={currentView !== "fleet"}
                    onClick={this.saveGridLayout}
                  >
                    <img
                      src={saveLayout}
                      alt="Refresh"
                      style={{ width: "30px", margin: "0 5px 0 0" }}
                    />
                    Save Grid Layout
                  </Button>
                </Col> */}
              </Row>
              <ColoredLine color="grey" />
            </FormGroup>

            <FormGroup>
              <IgrDataGridToolbar
                ref={this.onToolbarRef}
                toolbarTitle=""
                columnChooser="true"
              />
              <IgrDataGrid
                ref={this.onGridRef}
                // height={gridHeight}
                height="75vh"
                cellTextStyle="14px Calibri"
                headerTextStyle="14px Calibri"
                rowHeight={"25"}
                width="100%"
                key={this.state.currentView}
                dataSource={facInvCollector}
                scrollable={true}
                editMode={0}
                autoGenerateColumns="false"
                selectionMode="SingleRow"
                isColumnOptionsEnabled={true}
                selectedKeysChanged={this.onGridRowSelected}
              >
                {this.showProps()}
              </IgrDataGrid>
            </FormGroup>

            <Modal
              isOpen={this.state.modal}
              toggle={() => this.toggle()}
              size="xl"
              scrollable={true}
            >
              <ModalHeader toggle={() => this.toggle()}>
                {" "}
                Asset Details{" "}
              </ModalHeader>
              <ModalBody>
                <InventoryDetails
                  user={this.props.user}
                  toggle={this.toggle}
                  toggleFirstForm={this.toggleItemType}
                  facilityInvCollectorKey={facilityInvCollectorKey}
                  facilityKey={facilityKey}
                  inventoryData={inventoryData}
                  itemTypeKey={itemTypeKey}
                  refreshDataFilter={this.refreshToggle}
                  isSecurityAdminLogin={isSecurityAdminLogin}
                  currentView={currentView}
                  setStateOfParent={this.setStateOfParent}
                  // refreshDataFleet={this.getFacInvCollectorFleet}
                  isFleetSupervisors={isFleetSupervisors}
                  statusType={this.state.statusType}
                ></InventoryDetails>
              </ModalBody>
            </Modal>

            <Modal
              isOpen={this.state.modalItemType}
              toggle={() => this.toggleItemType()}
              size="sm"
            >
              <ModalHeader toggle={() => this.toggleItemType()}>
                Item Type{" "}
              </ModalHeader>
              <ModalBody>
                <InventoryItemType
                  toggle={this.toggleItemType}
                  currentView={currentView}
                  itemTypeTitle={itemTypeTitle}
                  itemTypeKey={itemTypeKey}
                  refreshData={this.refreshToggle}
                  // refreshDataFleet={this.getFacInvCollectorFleet}
                  onClickYes={this.toggleYes}
                  onChangeValue={this.addInventory}
                ></InventoryItemType>
              </ModalBody>
            </Modal>

            <Modal
              isOpen={this.state.modalItemTypeCreator}
              toggle={() => this.toggleItemTypeCreator()}
              size="xl"
              scrollable={true}
            >
              <ModalHeader toggle={() => this.toggleItemTypeCreator()}>
                {" "}
                Inventory Item Type Creator{" "}
              </ModalHeader>
              <ModalBody>
                <InventoryItemTypeCreator
                  user={this.props.user}
                  toggle={this.toggleItemTypeCreator}
                ></InventoryItemTypeCreator>
              </ModalBody>
            </Modal>
          </div>
        );



    }
    
   
}

    
    

    onReportDateUpdating(s, e) {
        const content = e.content;
        const info = e.cellInfo;
        const item = info.rowItem;
        let link;
        if (content.childElementCount === 0) {
            link = document.createElement("a");
            let cell = document.createElement("div");

       

            content.style.display = "inline-grid";
            content.style.fontFamily = "Verdana";
            content.style.fontSize = "12px";
            content.style.color = "#4286f4";
             content.style.width = "100%";

            content.appendChild(link);
        }
        else {
            link = content.children[0];
        }


        link.textContent = new Date(item.validationDate).toLocaleDateString("es-PA");
    }





  saveWorkbook() {
  const columns = this.grid.actualColumns;
  
  let visibleColumns = [];
  for (let i = 0; i < columns.count; i++) {
    let col = columns.item(i);
    if (!col.isHidden) {
      visibleColumns.push(col);
    }
  }
  
  const wb = new Workbook(WorkbookFormat.Excel2007);
  const ws = wb.worksheets().add("Sheet1");

  let excelColumnIndex = 0;
  visibleColumns.forEach(col => {
    ws.rows(0).cells(excelColumnIndex).value = col.headerText || col.field;
    this.grid.dataSource.forEach((row, rowIndex) => {
      ws.rows(rowIndex + 1).cells(excelColumnIndex).value = row[col.field];
    });
    excelColumnIndex++;
  });


  ExcelUtility.save(wb, "Inventory");
  }


  // saveWorkbook() {
  //   const actCols = this.grid.actualColumns;
  
  //   // Save the grid layout internally
  //   let layoutData = this.grid.saveLayout();
  //   let parsedLayoutData = JSON.parse(layoutData);
  
  //   // Reset Filtered to false and clear FilterConditions for each column
  //   parsedLayoutData.columns.forEach(column => {
  //     column.Filtered = false;
  //     column.FilterConditions = [];
  //   });
  
  //   // Clearing filter information
  //   parsedLayoutData.filterColumns = [];
  
  //   // Apply the layout data to the grid
  //   this.grid.loadLayout(JSON.stringify(parsedLayoutData));
  
  //   // Access the filtered data from the grid
  //   const gridData = this.grid.dataSource; // This accesses the entire data source
  //   const filteredData = this.grid.filteredSortedData ? this.grid.filteredSortedData : gridData; // This accesses the filtered data if available
  
  //   if (!filteredData || filteredData.length === 0) {
  //     console.error("No filtered data available.");
  //     return;
  //   }
  
  //   const headers = Object.keys(filteredData[0]);
  //   const wb = new Workbook(WorkbookFormat.Excel2007);
  //   const ws = wb.worksheets().add("Sheet1");
  //   let f = 0;
  
  //   // Add headers to the worksheet
  //   for (let j = 0; j < actCols.count; j++) {
  //     if (!actCols.item(j).isHidden) {
  //       for (let i = 0; i < headers.length; i++) {
  //         if (headers[i] === actCols.item(j).field) {
  //           ws.rows(0).cells(f).value = actCols.item(j).headerText || actCols.item(j).field;
  //           f++;
  //         }
  //       }
  //     }
  //   }
  
  //   // Add data rows to the worksheet
  //   for (let i = 0; i < filteredData.length; i++) {
  //     const dataRow = filteredData[i];
  //     const xlRow = ws.rows(i + 1);
  //     f = 0;
  
  //     for (let k = 0; k < actCols.count; k++) {
  //       if (!actCols.item(k).isHidden) {
  //         for (let j = 0; j < headers.length; j++) {
  //           if (headers[j] === actCols.item(k).field) {
  //             xlRow.setCellValue(f, dataRow[headers[j]]);
  //             f++;
  //           }
  //         }
  //       }
  //     }
  //   }
  
  //   ExcelUtility.save(wb, "Asset_" + this.state.assetTypeID + ".xlsx");
  // }

}
export default withRouter(Inventory);