import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiDELETEHeader } from '../../resources/apiURL';
import TrashBin from '../../resources/trash.svg'
import ConfirmModal from "../../operation/ConfirmModal";
import Datetime from 'react-datetime';
import moment from 'moment';
import Select from 'react-select';
import { IgrDataGrid } from 'igniteui-react-grids';
import { IgrTemplateColumn, IgrTextColumn, IgrNumericColumn } from 'igniteui-react-grids';
import { getUserADAccount, getRandomInt, FormatDate } from '../../resources/utility'
import DocumentBrowserDragDrop from '../../resources/DocumentBrowserDragDrop';
import NumberFormat from 'react-number-format';
const WOCompleteModal = (props) => {
  const {
    formName,
    isOpen,
    toggle,
    onClickYes,
    onClosed,
    woData,
    getSelVendors,
    // getSelEmpList,
    vendorsList,
    // invalidDataItem,
    onStatusCellUpdating,
    // woUOMData,
    // getSelWOUOM,
    onChangeCompletedDate,
    // woCostType,
    // getSelWOCostType
    isSecurityAdminLogin,
    user,
    workOrderKey,
    partsAndCostDataGrid,
    refreshPartsAndCostData,
    offLineLinkKey,
    toggleVendor,
    toggleCompleteUser,
    hideComplete
    // getWorkOrder
  } = props;

  const [modalVendorLabor, setModalVendorLabor] = useState(props.toggleVendor);
  const [modalCompletedUser, setModalCompletedUser] = useState(props.toggleCompleteUser);
  const [partsAndCostData, setPartsAndCostData] = useState({
    partsAndCostKey: -1, // or null
    workOrderKey: null,
    costTypeKey: null,
    itemKey: null,
    description: "",
    quantity: 0.00,
    unitCost: 0.00,
    uomKey: null,
    completedDate: moment(),
    createdBy: getUserADAccount(user.account.userName)
  });
  const [getWorkOrder, setGetWorkOrder] = useState(props.getWorkOrder);
  const [empList, setEmpList] = useState(props.empList)
  const [invalidDataItem, setInvalidDataItem] = useState({        
    laborItemKey: true,
    quantity: true,
    laborCompletedDate: true,
    vendorItemKey: true,
    unitCost: true,
    completedDate: true,
    vendorCompletedDate: true
  })
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [modalMsg, setModalMsg] = useState({
    msgBody: '',
    msgHeader: '',
    modalMsg: false
  });


  useEffect(() => {
    setModalVendorLabor(toggleVendor);
    setModalCompletedUser(toggleCompleteUser);

  }, [toggleVendor, toggleCompleteUser]);

  const toggleYes = () => {
    onClickYes();
  };

  const handleClosed = () => {
    if (onClosed !== undefined) {
      onClosed();
    }
  };

  const validDataHours = () => {
    let bVal = true;
  
    // Clone the current state of invalidDataItem to avoid direct mutation
    let valItem = { ...invalidDataItem };
  
    if (partsAndCostData.costTypeKey == 1) {
      if (partsAndCostData.itemKey <= 0 || partsAndCostData.itemKey == null) {
        bVal = false;
        valItem.vendorItemKey = false;
      }
  
      if (partsAndCostData.unitCost < 0 || partsAndCostData.unitCost == null) {
        bVal = false;
        valItem.unitCost = false;
      }
  
      if (partsAndCostData.completedDate == "" || partsAndCostData.completedDate == null) {
        bVal = false;
        valItem.vendorCompletedDate = false;
      }
    }
  
    if (partsAndCostData.costTypeKey == 2) {
      if (partsAndCostData.itemKey <= 0 || partsAndCostData.itemKey == null) {
        bVal = false;
        valItem.laborItemKey = false;
      }
  
      if (partsAndCostData.quantity <= 0 || partsAndCostData.quantity == null) {
        bVal = false;
        valItem.quantity = false;
      }
  
      if (partsAndCostData.completedDate == "" || partsAndCostData.completedDate == null) {
        bVal = false;
        valItem.laborCompletedDate = false;
      }
    }
  
    // Update the state with the validation results
    setInvalidDataItem(valItem);
  
    return bVal;
  };

  const addNewVendor = () => {
    setPartsAndCostData({
      partsAndCostKey: -1,
      workOrderKey: null,  // or workOrderKey if it should not be cleared
      costTypeKey: 1,
      itemKey: null,
      description: "",
      quantity: 0.00,
      unitCost: 0.00,
      uomKey: "",
      completedDate: moment(),
      createdBy: getUserADAccount(user.account.userName), // Assuming this should not be reset
      stastusKey: 0
    });
  
    toggleVendorLabor(); // Close the modal or perform any additional actions
  };
  const addNewLabor = () => {
    setPartsAndCostData({
      partsAndCostKey: -1,
      workOrderKey: null,  // or workOrderKey if it should not be cleared
      costTypeKey: 2,
      itemKey: "",
      description: "",
      quantity: 0.00,
      unitCost: 0.00,
      uomKey: "",
      completedDate: moment(),
      createdBy: getUserADAccount(user.account.userName), // Assuming this should not be reset
    });
  
    toggleCompletedUser(); // Close the modal or perform any additional actions
  };

  const toggleVendorLabor = () => {
    setModalVendorLabor(!modalVendorLabor);
  };

  const toggleCompletedUser = () => {
    setModalCompletedUser(!modalCompletedUser);
  };


  const onGridRowSelected = (s, e) => {
    const content = e.content;
    if (e.addedKeys.count > 0) {
        let clickedRow = e.addedKeys.item(0)
        let dataItem = clickedRow.value[0]
        setPartsAndCostData({
          partsAndCostKey: dataItem.partsAndCostKey,
          workOrderKey: dataItem.workOrderKey,
          costTypeKey: dataItem.costTypeKey,
          itemKey: dataItem.itemKey,
          description: dataItem.description,
          quantity: dataItem.quantity,
          unitCost: dataItem.unitCost,
          uomKey: dataItem.uomKey,
          // completedDate: dataItem.completedDate ? moment(dataItem.completedDate).format('YYYY-MM-DD HH:mm:ss') : moment().format('YYYY-MM-DD HH:mm:ss'),
          completedDate: dataItem.completedDate,
          createdBy: dataItem.createdBy,
          totalCost: dataItem.totalCost
      });
        // console.log(dataItem)
    }
}

const onGridRowSelected2 = (s, e) => {
  if (e.isDoubleClick) {

    if (partsAndCostData.costTypeKey === 1) {
      // If costTypeKey is 1, toggle the Vendor Labor modal
      toggleVendorLabor();
    } else if (partsAndCostData.costTypeKey  === 2) {
      // If costTypeKey is 2, toggle the Completed User modal
      toggleCompletedUser();
    } 
    else{
      // console.log("No valid cost type key")
    }
  }
};

// const getSelVendors = (value) => {
//   if (value !== null && value !== "") {
//     return vendorsList.filter(e => e.wdSupplierKey === value)
//   }
//   return ""
// }

const addWOPartsAndCost = () => {
  const woPartsAndCostCreateDTO = createWOPartsAndCostDTO();

  let myURI = apiURL + 'Fiix/WorkOrderPartsAndCost';
  fetch(myURI, apiPOSTHeader(woPartsAndCostCreateDTO))
    .then(async response => {
      const rspData = response.text();
      if (!response.ok) {

        const error = (rspData && rspData.message) || response.status;
        rspData.then(t => {
          setModalMsg({
            msgBody: t,
            msgHeader: "Error",
            modalMsg: true
          });
        })
        return Promise.reject(error);
      }
      // Call the function to refresh the work order data
      props.getWorkOrder();
    })
    .catch(error => {
      console.error("Error adding parts and cost:", error);
    });
};


// 

const updateWOPartsAndCost = () => {

  const woPartsAndCostUpdateDTO = updateWOPartsAndCostDTO()

  let myURI = apiURL + 'Fiix/WorkOrderPartsAndCost'
  fetch(myURI, apiPUTHeader(woPartsAndCostUpdateDTO))
    .then(async response => {
      const rspData = response.text();
      if (!response.ok) {

        const error = (rspData && rspData.message) || response.status;
        // rspData.then(t => {
        //   this.setState({
        //     msgBody: t,
        //     msgHeader: "Error",
        //     modalMsg: !this.state.modalMsg
        //   })
        // })
        return Promise.reject(error);

      }
      props.getWorkOrder();
      // this.props.refreshData();

    })
    .catch(error => {

    })

}

const deletePartsAndCost = () => {
  const partsAndCostDeleteDTO = deleteWOPartsAndCostDTO();

  let myURI = apiURL + "Fiix/WorkOrderPartsAndCost";
  fetch(myURI, apiDELETEHeader(partsAndCostDeleteDTO))
    .then(async (response) => {
      const rspData = response.text();
      if (!response.ok) {
        const error = (rspData && rspData.message) || response.status;
        return Promise.reject(error);
      }
      props.getWorkOrder();

      setPartsAndCostData((prevState) => ({
        ...prevState,
        partsAndCostKey: -1,  // Reset partsAndCostKey when Close is clicked
      }));
    })
    .catch((error) => {});
};

const createWOPartsAndCostDTO = () => {
  const woPartsAndCostCreateDTO = {
    "workOrderKey": workOrderKey,
    "costTypeKey": partsAndCostData.costTypeKey,
    "itemKey": partsAndCostData.itemKey,
    "description": partsAndCostData.description,
    "quantity": partsAndCostData.quantity,
    "unitCost": partsAndCostData.unitCost,
    "uomKey": null,
    "completedDate": partsAndCostData.completedDate,
    "createdBy": getUserADAccount(user.account.userName),
    "statusKey": partsAndCostData.statusKey
  }
  // console.log(woPartsAndCostCreateDTO)
  return woPartsAndCostCreateDTO;
}

const updateWOPartsAndCostDTO = () => {
  const woPartsAndCostUpdateDTO = {
    "partsAndCostKey": partsAndCostData.partsAndCostKey,
    "workOrderKey": partsAndCostData.workOrderKey,
    "costTypeKey": partsAndCostData.costTypeKey,
    "itemKey": partsAndCostData.itemKey,
    "description": partsAndCostData.description,
    "quantity": partsAndCostData.quantity,
    "unitCost": partsAndCostData.unitCost,
    "uomKey": null, 
    "completedDate": partsAndCostData.completedDate,
    "modifiedBy": getUserADAccount(user.account.userName),
    "statusKey": partsAndCostData.statusKey
  }
  // console.log(woPartsAndCostUpdateDTO)
  return woPartsAndCostUpdateDTO;
}

const deleteWOPartsAndCostDTO = () => {
  const woPartsAndCostDeleteDTO = {
    "partsAndCostKey": partsAndCostData.partsAndCostKey,
    "deletedBy": getUserADAccount(user.account.userName),
  }
  // console.log(woPartsAndCostDeleteDTO)
  return woPartsAndCostDeleteDTO;
}

// const saveData = () => {

//   // this.dataValidation().then((data) => {

//     // if (data.ok) {
//       toggleCompletedUser
//     if (costType == "Vendor") 
//     {
//         if (partsAndCostData.partsAndCostKey > 0) {
//           // this.updateWorkOrder()
//           // this.closeForm()
//           toggleVendorLabor()
//           props.getWorkOrder(); 

//         } else {
//           addWOPartsAndCost()  
//           toggleVendorLabor()
//           props.getWorkOrder();          
//           // this.closeForm()
//         }

//     }
//     else if(costType == "Employee")
//     {

//     }


//     // }
//     // else {
//     // }
//   // })
// }

const saveData = () => {
  // Call the validation function
  const isValid = validDataHours();

  // If validation fails, stop execution
  if (!isValid) {
    // console.log("Validation failed");
    return;
  }

  // Proceed with saving data if validation passes
  if (partsAndCostData.costTypeKey === 1) {
    // If costTypeKey is 1, toggle the Vendor Labor modal
    toggleVendorLabor();
  } else if (partsAndCostData.costTypeKey === 2) {
    // If costTypeKey is 2, toggle the Completed User modal
    toggleCompletedUser();
  }

  if (partsAndCostData.partsAndCostKey < 0) {
    // console.log("Save Data: Add");
    addWOPartsAndCost();
  } else {
    // console.log("Save Data: Update");
    updateWOPartsAndCost();
  }

  props.getWorkOrder();
};

const onTextChange = (fieldName, fieldValue, e) => {
  // console.log(`Field Name: ${fieldName}`);
  // console.log(`Field Value: ${fieldValue}`);
  // console.log(`Value: ${e?.value}`);

  setPartsAndCostData((prevState) => {
    let newValue = fieldValue;
    switch (fieldName) {
      case "itemKey":
        newValue = partsAndCostData.costTypeKey === 2 ? (e ? e.employeeKey : "") : (e ? e.value : "");
          if(e && e.value === 99999)
            {
              partsAndCostData.description = ""
            }


        break;

      case "completedDate":
        // newValue = fieldValue ? moment(fieldValue).format('YYYY-MM-DDTHH:mm:ss') : "";
        newValue = fieldValue
        break;

      case "unitCost":
        newValue = fieldValue !== undefined ? fieldValue.toFixed(2) : "0.00";
        break;

      case "quantity":
        newValue = fieldValue !== undefined ? fieldValue.toFixed(2) : "0.00";
        break;

      
      case "description":
        newValue = fieldValue 
        break;

      default:
        break;
    }

    return {
      ...prevState,
      [fieldName]: newValue,
    };
  });

  setInvalidDataItem((prevState) => {
    let updatedInvalidDataItem = { ...prevState };

    switch (fieldName) {
      case "itemKey":
        updatedInvalidDataItem = {
          ...updatedInvalidDataItem,
          laborItemKey: partsAndCostData.costTypeKey === 2 ? true : prevState.laborItemKey,
          vendorItemKey: partsAndCostData.costTypeKey === 1 ? true : prevState.vendorItemKey,
        };
        break;

      case "completedDate":
        updatedInvalidDataItem = {
          ...updatedInvalidDataItem,
          vendorCompletedDate: partsAndCostData.costTypeKey === 1 ? true : prevState.vendorCompletedDate,
          laborCompletedDate: partsAndCostData.costTypeKey === 2 ? true : prevState.laborCompletedDate,
        };
        break;

      case "unitCost":
        updatedInvalidDataItem.unitCost = true;
        break;

      case "quantity":
        updatedInvalidDataItem.quantity = true;
        break;

      default:
        break;
    }

    return updatedInvalidDataItem;
  });
}


const getSelEmpList = (value) => {
  if (value !== null && value !== "" && value !== undefined) {
    return empList.filter(e => e.employeeKey === value)

  }

}

const toggleModalMsg = () => {
  setModalMsg(prevState => ({
    ...prevState,
    modalMsg: !prevState.modalMsg,
  }));
};

  // New function specifically for handling completedDate
  const onCompletedDateUpdating = (s, e) => {
    const content = e.content;
    const info = e.cellInfo;
    const item = info.rowItem;
  
    // Ensure it's not a header cell
    if (info.isHeaderCell) {
      if (content.children[0]) {
        content.children[0].style.textAlign = "center";
      } else {
        content.style.textAlign = "center";
      }
      return;
    }
  
    let link;
    if (content.childElementCount === 0) {
      link = document.createElement("label");
      content.appendChild(link);
    } else {
      link = content.children[0];
    }
  
    if (s.field === 'completedDate') {
      // Format the date using moment.js
      const formattedDate = moment(item[s.field]).format('MM/DD/YYYY h:mm A'); // Format the date without any conversion
  
      link.textContent = formattedDate; // Update the cell content with formatted date
    }
  };
  
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: state.data.value === 99999 ? '1px solid #000000' : 'none',
      paddingBottom: state.data.value === 99999 ? '10px' : provided.paddingBottom, 
      marginBottom: state.data.value === 99999 ? '5px' : '0px', 
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }), 
  };
  


  return (
    <div>
      <Modal size="xl" isOpen={isOpen} toggle={toggle} onClosed={handleClosed}>
        <ModalHeader toggle={toggle}>{formName}</ModalHeader>
        <ModalBody>
          <FormGroup>
            {/* <Label style={{ margin: "0px 0 0 0" }}>
              Please enter the Completion Date:
            </Label>
            <Datetime
              inputProps={{ placeholder: "MM/DD/YYYY hh:mm" }}
              className={!invalidDataItem.fiixWODateCompleted ? "form-error" : ""}
              value={moment(woData[0].fiixWODateCompleted)}
              onChange={(v) => onChangeCompletedDate(moment(v))}
            /> */}
            {/* <hr style={{ width: "100%", margin: "10px 0" }} /> */}

            <Modal
              isOpen={modalVendorLabor}
              toggle={toggleVendorLabor}
              size="med"
              backdrop={"static"}
            >
              <ModalHeader toggle={toggleVendorLabor}>
                Add Vendor Labor
              </ModalHeader>
              <ModalBody>
                <FormGroup>
                  <Row>
                    <Col md={12}>
                      <Label>Please enter Completed Date</Label>
                      <Datetime
                        inputProps={{ 
                          placeholder: "MM/DD/YYYY hh:mm",
                          disabled: props.isSecurityAdminLogin && // Admins can always edit
                                    partsAndCostData.partsAndCostKey > 0 &&
                                    partsAndCostData.createdBy !== getUserADAccount(user.account.userName)
                        }}
                        className={
                          !invalidDataItem.vendorCompletedDate
                            ? "form-error"
                            : ""
                        }
                      value={moment(partsAndCostData.completedDate).format(
                          "MM/DD/YYYY h:mm A"
                        )}
                        onChange={(v) => onTextChange("completedDate", v, null)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Label>Please select Completed by Vendor:</Label>
                      <Select
                        options={vendorsList}
                        styles={customStyles} // Apply the custom styles
                        isClearable={true} // Optional: allows clearing the selection
                        menuPortalTarget={document.body} // Optional: ensures the menu is rendered in the body with high z-index
                        // isDisabled={console.log(partsAndCostData.itemKey)}
                        isDisabled={props.isSecurityAdminLogin && // Admins can always edit
                          partsAndCostData.partsAndCostKey > 0 &&
                          partsAndCostData.createdBy !== getUserADAccount(user.account.userName)}
                        value={getSelVendors(partsAndCostData.itemKey)}
                        onChange={(e) => onTextChange("itemKey", e, e)}
                      />
                    </Col>
                  </Row>
                  <Row
                  // hidden= {partsAndCostData.itemKey != 99999}
                  >
                    <Col md={12}>
                      <Label style={{ color: "red" }}>
                        Type a vendor when "Other" is selected
                      </Label>
                      <Input
                        type="text"
                        name="Field3"
                        id="Field3"
                        disabled={partsAndCostData.itemKey != 99999}
                        // defaultValue={Field3}
                        value={
                          partsAndCostData.itemKey == 99999
                            ? partsAndCostData.description
                            : ""
                        }
                        onChange={(e) =>
                          onTextChange("description", e.target.value, e)
                        }
                      ></Input>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Label>Please enter the Total Cost:</Label>
                      <NumberFormat
                        // className="form-control text-md-right"
                        value={partsAndCostData.unitCost}
                        className={
                          !invalidDataItem.unitCost
                            ? "form-error form-control text-md-right"
                            : " form-control text-md-right"
                        }
                        onValueChange={(values) =>
                          onTextChange("unitCost", values.floatValue, values)
                        }
                        disabled={props.isSecurityAdminLogin && // Admins can always edit
                          partsAndCostData.partsAndCostKey > 0 &&
                          partsAndCostData.createdBy !== getUserADAccount(user.account.userName)}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                        decimalScale={2}
                        prefix={"$"}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={() => saveData()} 
                hidden={props.isSecurityAdminLogin && // Admins can always edit
                partsAndCostData.partsAndCostKey > 0 &&
                partsAndCostData.createdBy !== getUserADAccount(user.account.userName)}>
                  Save
                </Button>
                <Button color="secondary" onClick={toggleVendorLabor}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>

            <Modal
              isOpen={modalCompletedUser}
              toggle={toggleCompletedUser}
              size="med"
              backdrop={"static"}
            >
              <ModalHeader toggle={toggleCompletedUser}>Add Labor</ModalHeader>{" "}
              {/* Updated header text */}
              <ModalBody>
                <FormGroup>
                  <Row>
                    <Col md={12}>
                      <Label>Please enter Completed Date</Label>
                      <Datetime
                        inputProps={{ placeholder: "MM/DD/YYYY hh:mm",
                          disabled: props.isSecurityAdminLogin && // Admins can always edit
                          partsAndCostData.partsAndCostKey > 0 &&
                          partsAndCostData.createdBy !== getUserADAccount(user.account.userName)
                        }}
                        className={
                          !invalidDataItem.laborCompletedDate
                            ? "form-error"
                            : ""
                        }
                        value={moment(partsAndCostData.completedDate).format(
                          "MM/DD/YYYY h:mm A"
                        )}
                        disabled = {partsAndCostData.partsAndCostKey > 0 && (partsAndCostData.createdBy !==
                          getUserADAccount(user.account.userName)) ? true : false}
                        onChange={(v) => onTextChange("completedDate", v, null)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Label>Please select Completed User:</Label>
                      <Select
                        options={empList}
                        className={
                          !invalidDataItem.laborItemKey ? "form-error" : ""
                        }
                        isDisabled={props.isSecurityAdminLogin && // Admins can always edit
                          partsAndCostData.partsAndCostKey > 0 &&
                          partsAndCostData.createdBy !== getUserADAccount(user.account.userName)}
                        id="empList"
                        isClearable={true}
                        value={getSelEmpList(partsAndCostData.itemKey)}
                        onChange={(e) => onTextChange("itemKey", e, e)}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Label>Please enter total Labor Hours:</Label>
                      {/* <Input
                        type="number"
                        value={partsAndCostData.quantity}
                        onChange={(e) => onTextChange('quantity', e.target.value, e)}
                        // invalid={!invalidDataItem.vendorCost}
                      /> */}
                      <NumberFormat
                        // className="form-control text-md-right"
                        className={
                          !invalidDataItem.quantity
                            ? "form-error form-control text-md-right"
                            : "form-control text-md-right"
                        }
                        disabled={props.isSecurityAdminLogin && // Admins can always edit
                          partsAndCostData.partsAndCostKey > 0 &&
                          partsAndCostData.createdBy !== getUserADAccount(user.account.userName)}
                        value={partsAndCostData.quantity}
                        onValueChange={(values) =>
                          onTextChange("quantity", values.floatValue, values)
                        }
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                        decimalScale={2}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={() => saveData()}                 
                hidden={props.isSecurityAdminLogin && // Admins can always edit
                partsAndCostData.partsAndCostKey > 0 &&
                partsAndCostData.createdBy !== getUserADAccount(user.account.userName)}>
                  Save
                </Button>
                <Button color="secondary" onClick={toggleCompletedUser}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>

            <FormGroup>
              <Row
                form
                style={{ zIndex: 0, position: "relative", marginLeft: "0" }}
              >
                <Button
                  style={{ marginRight: "10px", padding: "10px 20px" }}
                  color="success"
                  onClick={addNewVendor}
                >
                  Add Vendor
                </Button>
                <Button
                  onClick={addNewLabor}
                  style={{
                    padding: "10px 20px",
                    backgroundColor: "#1e7e34",
                    borderColor: "#1e7e34",
                    transition:
                      "background-color 0.3s ease, transform 0.3s ease",
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = "#155724";
                    e.target.style.transform = "scale(1.05)";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = "#1e7e34";
                    e.target.style.transform = "scale(1)";
                  }}
                >
                  Add User
                </Button>
              </Row>
              <Row
                form
                style={{ zIndex: 0, position: "relative", marginTop: "10px" }}
              >
                <IgrDataGrid
                  height={"250px"}
                  width={"100%"}
                  className="scrollable-grid"
                  isColumnOptionsEnabled="true"
                  editMode={0}
                  dataSource={partsAndCostDataGrid}
                  autoGenerateColumns="false"
                  selectionMode="SingleRow"
                  summaryScope="Both"
                  selectedKeysChanged={onGridRowSelected}
                  cellClicked={onGridRowSelected2}
                >
                  {/* <IgrTextColumn
                    field="woCostTypeDesc"
                    headerText="Cost Type"
                    width="*>150"
                    cellUpdating={onStatusCellUpdating}
                  /> */}
                  <IgrTemplateColumn
                    field="description"
                    headerText="Description"
                    width="*>250"
                    cellUpdating={onStatusCellUpdating}
                  />
                  {/* <IgrTemplateColumn
                    field="instructions"
                    headerText="Work Instructions"
                    width="*>250"
                    cellUpdating={onStatusCellUpdating}
                  /> */}
                  <IgrTemplateColumn
                    field="completedDate"
                    headerText="Completed Date"
                    width="*>200"
                    cellUpdating={onCompletedDateUpdating}
                  />
                  <IgrNumericColumn
                    field="quantity"
                    headerText="Quantity"
                    width="*>100"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    cellUpdating={onStatusCellUpdating}
                  />
                  <IgrNumericColumn
                    field="unitCost"
                    headerText="Unit Cost"
                    positivePrefix="$"
                    maxFractionDigits={2}
                    minFractionDigits={2}
                    width="*>100"
                    cellUpdating={onStatusCellUpdating}
                  />
                  <IgrNumericColumn
                    field="totalCost"
                    headerText="Total Cost"
                    positivePrefix="$"
                    maxFractionDigits={2}
                    minFractionDigits={2}
                    showGroupingSeparator="true"
                    width="*>200"
                    cellUpdating={onStatusCellUpdating}
                  />
                </IgrDataGrid>
              </Row>
              <Row form style={{ marginTop: "20px" }}>
                <Col>
                  <DocumentBrowserDragDrop
                    buttonLabel="View Image"
                    buttonColor="primary"
                    formName="Workorder Images"
                    linkTableName={"topWorkOrder"}
                    linkPrimaryKey={
                      workOrderKey < 0 ? offLineLinkKey : workOrderKey
                    }
                    apiURL={`${apiURL}Fiix/Workorder/Documents/${
                      workOrderKey < 0 ? offLineLinkKey : workOrderKey
                    }`}
                  />
                </Col>
              </Row>
            </FormGroup>
          </FormGroup>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <div className="d-flex">
            <ConfirmModal
              outline={true}
              color="danger"
              disabled={
                partsAndCostData.partsAndCostKey < 0 || // Always disable if no valid record is selected
                (props.isSecurityAdminLogin &&
                  partsAndCostData.createdBy !==
                    getUserADAccount(user.account.userName))
              }
              onClickYes={(e) => deletePartsAndCost()}
              buttonLabel="Delete"
              formName="Delete"
              message="Are you sure you want to delete selected parts and cost?"
              image={
                <img
                  src={TrashBin}
                  alt="Delete"
                  style={{ width: "20px", margin: "0 5px 0 0" }}
                />
              }
            />
          </div>
          <div className="d-flex">
            <Button
              color="primary"
              // disabled={console.log(hideComplete)}
              hidden={hideComplete}
              onClick={() => setConfirmationModal(true)}
              style={{ marginRight: "10px" }}
            >
              Complete
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                setPartsAndCostData((prevState) => ({
                  ...prevState,
                  partsAndCostKey: -1,  // Reset partsAndCostKey when Close is clicked
                }));
                props.refreshPartsAndCostData(); // Call the existing function
              }}
            >
              Close
            </Button>

          </div>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={confirmationModal}
        toggle={() => setConfirmationModal(!confirmationModal)}
        backdrop={"static"}
      >
        <ModalHeader toggle={() => setConfirmationModal(!confirmationModal)}>
          Confirm Completion
        </ModalHeader>
        <ModalBody>
          <p>Are you sure you want to Complete the WO?</p>
          <p>
            Labor and Vendor data cannot be added or modified once Complete.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              toggleYes(); // Proceed with the completion
              setConfirmationModal(false); // Close the confirmation modal
            }}
          >
            Yes
          </Button>
          <Button color="secondary" onClick={() => setConfirmationModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={modalMsg.modalMsg}
        toggle={() => toggleModalMsg()}
        size="lg"
      >
        <ModalHeader toggle={() => toggleModalMsg()}>
          {modalMsg.msgHeader} {/* Access msgHeader from modalMsg */}
        </ModalHeader>
        <ModalBody>{modalMsg.msgBody}</ModalBody>{" "}
        {/* Access msgBody from modalMsg */}
        <ModalFooter>
          <Button color="primary" onClick={() => toggleModalMsg()}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default WOCompleteModal;
