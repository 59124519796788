// import { TimelineComponent, ItemsDirective,ItemDirective } from '@syncfusion/ej2-react-layouts';

import React, { useState, useEffect } from 'react';
import {
    Input, FormGroup, Label, Row, Col
} from 'reactstrap';
import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiDELETEHeader } from '../../resources/apiURL';


const TimeLineItem = (props) => {
    const { upperText, lowerText, lastItem, value } = props
    return (<Col style={{ paddingRight: "0px", marginRight: (lastItem ? "0px" : "-30px") }}>
        <Label for="exampleRange">
            {upperText}
        </Label>
        <Input style={{ accentColor: "green", backgroundColor: "green" }}
            id="exampleRange"
            name="range"
            type="range"
            value={(value === undefined || value === null ? 0 : value)}
        />
        <Label for="exampleRange" >
            {lowerText}
        </Label>
    </Col>)
}

const AssetTimeline = (props) => {
    const { assetKey, companyID, assetTypeID, assetCatID } = props

    const [isAssetTimelineLoad, setIsAssetTimelineLoaded] = useState(false)
    const [assetTimeline, setAssetTimeline] = useState([])

    const getFormTimelineData = (assetKey) => {


        if (assetKey !== undefined) {
            var myURI = apiURL + 'Asset/' + companyID + '/' +
                assetCatID + '/' + assetTypeID + '/Timelinedata/' + assetKey

            console.log('---------------> Asset Time Line Data: ' + myURI)

            fetch(myURI, { modes: 'no-cors' })
                .then(async (res) => {
                    if (res.ok){
                      let json = await res.json()
                      console.log('---------------> Asset Time Line Data')
                      console.log(json)
                      setIsAssetTimelineLoaded(true)
                      setAssetTimeline(json)
                    }
                }).catch(e => console.log('Error:',e))
        }
    }

    useEffect(() => {
        if (assetKey !== undefined && assetKey !== null && assetKey > 0) {
            getFormTimelineData(assetKey)
        }
    }, [])


    if (!isAssetTimelineLoad) {
        return (<div>Loadding asset timeline ......</div>)
    }
    return (

        <FormGroup>
            <Row>
                {
                    assetTimeline.map((e ,i) => {
                        return (

                            <TimeLineItem upperText={e.upperText}
                                lowerText={e.lowerText}
                                lastItem={((assetTimeline.length - 1) === i)}
                                value={e.value}></TimeLineItem>

                        )
                    })}
            </Row>
        </FormGroup>
       
    );
}

export default AssetTimeline;