import React, { Component, createRef, useState, forwardRef, useRef, useImperativeHandle } from 'react';
import { Form, Col, Row, Button, FormGroup, Label, Input, InputGroupText, InputGroup, CardGroup } from 'reactstrap';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import { Card, CardHeader, CardBody, CardTitle, CardFooter } from 'reactstrap'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'; 
import { TabContent, TabPane, Nav, NavItem, NavLink, FormFeedback, Alert } from 'reactstrap'; 
import '../../../src/custom.css' 
import { apiURL,apiHeader, apiPOSTHeader, apiPUTHeader, apiDELETEHeader } from '../../resources/apiURL'; 
import ConfirmModal from '../ConfirmModal';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Plus from '../../../src/resources/plus.svg'
import Refresh from '../../../src/resources/refresh.svg'
import TrashBin from '../../../src/resources/trash.svg'
import Excel from '../../../src/resources/excel.svg'
import RevisionPng from '../../../src/resources/revise.png'
import Select from 'react-select';
import { getUserADAccount, getRandomInt, NumFormat } from '../../resources/utility'
import classnames from 'classnames'; 
import NumberFormat from 'react-number-format'; 
import { ColoredLine, FormatDate, getFirstDayofMonth, USDCurrencyFormat, IsDate } from '../../resources/utility';
import { ThisMonthExpression, ThisYearExpressionDescription } from 'igniteui-react-core'; 
//import { IgrGrid, IgrColumn } from 'igniteui-react-grids';
import { IgrDataGrid, IgrTextColumn ,IgrGridModule, IgrRowDragEventArgs, IgrRowDragEndEventArgs  } from 'igniteui-react-grids';
//import 'igniteui-webcomponents-grids/grids/themes/light/bootstrap.css';
//import 'igniteui-react-grids/grids/themes/light/bootstrap.css';
//import 'igniteui-react-grids/grids/themes/light/bootstrap.css';
import { ArrowRight,ArrowLeft } from 'react-bootstrap-icons';


var DatePicker = require("reactstrap-date-picker");
  
export default class ScheduledMaintenanceTrigger extends Component {
  static displayName =  'Alex testing' //AFEDetail.name

  constructor(props) {
      super(props)
     
      this.getSelTriggerValueOperator = this.getSelTriggerValueOperator.bind(this);

      this.state = {
          user: props.user, 
          pmScheduleKey: props.pmScheduleKey,
          pmScheduleTriggerKey: props.pmScheduleTriggerKey,
          triggerType: props.triggerType,
          condition1: props.condition1,
          condition2: props.condition2,
          condition3: props.condition3,
          condition4: props.condition4,
          
          isLoaded: false,
          isTriggerFieldLoaded: false,
          activeTab:      1, 
          addedAsset:     [],
          removeAssets:   [],
          triggerByTime: false,
          triggerByValue: false,
          triggerNumberValue: null,
          recurrencePattern: false,
          recurrenceFrequency: null,
          daysOfWeek: null,
          monday: 0,
          tuesday: 0,
          wednesday: 0,
          thursday: 0,
          friday: 0,
          saturday: 0,
          sunday: 0,
          triggerModel: null,
          monthlyRecurrenceFrequency: [],
          weeklyRecurrenceFrequency: [],
          locationKey: props.locationKey,
          facilityKey: props.facilityKey,
          statusKey: props.statusKey,
          itemTypeKey: props.itemTypeKey, 
          triggerValueOperators: [],
          isTriggerValueOperatorsLoaded: false,
          isSecurityAdminLogin:props.isSecurityAdminLogin,
      } 
       
  }
 
  async componentDidUpdate(prevProps) {
   
    if (this.state.triggerModel !== null && prevProps.itemTypeKey !== this.state.triggerModel.itemTypeKey) {
      this.state.triggerModel.itemTypeKey = prevProps.itemTypeKey;
      await this.getTriggerField();
    }
    else if (prevProps.statusKey !== this.state.statusKey){
      this.state.statusKey = prevProps.statusKey;
      await this.getTriggerField();
    } 
  }

  async componentDidMount() { 
    let {pmScheduleKey,monthlyRecurrenceFrequency,weeklyRecurrenceFrequency,itemTypeKey,
      pmScheduleTriggerKey,triggerType,condition1,condition2,condition3,condition4
    ,statusKey} = this.state;
    
    let triggerModel = 
    { 
      pmScheduleTriggerKey: pmScheduleTriggerKey,
      pmScheduleKey: pmScheduleKey,
      triggerType: triggerType,
      triggerByTime: (triggerType != undefined && triggerType != null && triggerType === 1 ? 1 : 0),
      triggerByValue: (triggerType === undefined || triggerType === null || triggerType === 2 ? 2 : 0),
      triggerNumberValue: null, 
      condition1: condition1,
      condition2: condition2,
      condition3: condition3,
      condition4: condition4,
      itemTypeKey: itemTypeKey,
      statusKey: statusKey,
    }

    //await this.getScheduleTriggers();

    for (let i = 0; i < 24; i++){
      let x = i + 1
      let monthLabel = x <=1 ? " Month" : " Months"
      monthlyRecurrenceFrequency.push({value: x, label:  x + monthLabel})
    } 
 
    for (let i = 0; i < 24; i++){
        let x = i + 1
        let weekLabel = x <=1 ? " Week" : " Weeks"
        weeklyRecurrenceFrequency.push({value: x, label:  x + weekLabel})
    }
    
    await this.getTriggerField();
    await this.getPMScheduleValueOperators();
    


    this.setState({
                  triggerModel: triggerModel ,
                  monthlyRecurrenceFrequency: monthlyRecurrenceFrequency
                  ,weeklyRecurrenceFrequency: weeklyRecurrenceFrequency
                  ,isLoaded: true });
    
    this.sendDataToParent(this.state.triggerModel);

  }
  
  async getScheduleTriggers(){
    let {pmScheduleKey,itemTypeKey} = this.state;

    let triggerModel = null;

    if (pmScheduleKey !== undefined && pmScheduleKey !== null && pmScheduleKey > 0){
      let myURI = apiURL + 'ScheduleMaintenance/PMScheduleTrigger/' + pmScheduleKey;
      await fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
          .then(res => res.json())
          .then(json => { 
            triggerModel= json 
      });
    }
    else
    {
      triggerModel = 
      { 
        pmScheduleTriggerKey: 0,
        pmScheduleKey: pmScheduleKey,
        triggerType: null,
        triggerByTime: null,
        triggerByValue: null,
        triggerNumberValue: null, 
        condition1: 0,
        condition2: 0,
        condition3: 0,
        condition4: 0,
        itemTypeKey: itemTypeKey,
      }
      
    } 
    this.setState({triggerModel: triggerModel}); 
  }

  async getTriggerField(){
    const {itemTypeKey ,triggerModel} = this.state;

    let tempItemTypeKey =  0

    if (itemTypeKey != undefined && itemTypeKey != null && itemTypeKey > 0){
      tempItemTypeKey = itemTypeKey; 
    }
    else if (triggerModel != undefined && triggerModel != null && triggerModel.itemTypeKey != null && triggerModel.itemTypeKey > 0 ){
      tempItemTypeKey = triggerModel.itemTypeKey
    }

    let myURI = apiURL + 'ScheduleMaintenance/ItemPropertyMap/' + tempItemTypeKey;
        await fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

            this.setState({
                isTriggerFieldLoaded: true,
                triggerFieldList: this.createTriggerFieldList(json)
            }) 
        }); 
  }
  
  async getPMScheduleValueOperators(){
    
    let myURI = apiURL + 'ScheduleMaintenance/PMScheduleValueOperators';
        await fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

            this.setState({
              isTriggerValueOperatorsLoaded: true,
              triggerValueOperators: this.createPMScheduleValueOperatorsList(json)
            }) 
        }); 
  }

  createTriggerFieldList = (value) => {
    var tempList = []
    value.map(e => {
      e.value = e.itemPropertyMapKey
      e.label = e.itemField
      tempList.push(e)
    })

    return tempList
  }   

  createPMScheduleValueOperatorsList = (value) => {
    var tempList = []
    value.map(e => {
      e.value = e.pmScheduleValueOperatorKey
      e.label = e.pmScheduleValueOperatorDesc
      tempList.push(e)
    })

    return tempList
  }



  onTextChange = (fieldName,fieldValue,e)=>{
     
    const {triggerModel, daysOfWeek}=this.state;
  
    // let triggerByTime = 0;      
    // let triggerByValue = 0;

    


    switch(fieldName){
        case "triggerByTime":
            // triggerByTime = (e === 1 ? 1 : 0);
            // this.setState({triggerByTime: triggerByTime,
            //               triggerByValue: (e === 1 ? 0 : 1),
            //  })
             triggerModel.triggerByTime = (e === 1 ? 1 : 0);
             triggerModel.triggerByValue = (e === 1 ? 0 : 1)
             
             if (triggerModel.triggerByTime == 1){
              triggerModel.triggerType = 1;
             } else if (triggerModel.triggerByValue == 1){
              triggerModel.triggerType = 2;
             } else{
              triggerModel.triggerType = null;
             }

             triggerModel.condition1 = null;
             triggerModel.condition2 = null;
             triggerModel.condition3 = null;
             triggerModel.condition4 = null;

            break;
        case "triggerByValue":
           
             triggerModel.triggerByValue = (e === 1 ? 1 : 0); 
             triggerModel.triggerByTime = (e === 1 ? 0 : 1);
             triggerModel.daysOfWeek = null; 
             
             if (triggerModel.triggerByTime == 1){
              triggerModel.triggerType = 1;
             } else if (triggerModel.triggerByValue == 1){
              triggerModel.triggerType = 2;
             } else{
              triggerModel.triggerType = null;
             }

             triggerModel.condition1 = null;
             triggerModel.condition2 = null;
             
             if (triggerModel.triggerType == 2 && triggerModel.pmScheduleKey <= 0){
              triggerModel.condition3 = 1;  //New record  set default to "Equal or Greater Than
             }
             else{
              triggerModel.condition3 = null;
             }
             
             //triggerModel.condition3 = null;
             triggerModel.condition4 = null;
            break;
        case "triggerNumberValue": 
            this.setState({triggerNumberValue: fieldValue < 0 ? 0 : fieldValue})
            break;
        case 'recurrencePattern': 
            // this.setState({recurrencePattern: e,
            //               daysOfWeek: e > 1 ? 0 : this.state.daysOfWeek, 
            // })
            
            // triggerModel.recurrencePattern = e;
            // triggerModel.daysOfWeek = e > 1 ? 0 : this.state.triggerModel.daysOfWeek;
            triggerModel.condition1 = e;
            //triggerModel.condition2 = e > 1 ? 0 : this.state.triggerModel.condition2;
            triggerModel.condition2 = 0;
            break;
        case 'recurrenceFrequency':  
          triggerModel.condition2 = +e.target.value;
          break;
        case 'starterValue':
          triggerModel.condition1 = e === null ? 0 : e.itemPropertyMapKey;
          break;
        case 'triggerField':
          triggerModel.condition2 = e === null ? 0 : e.itemPropertyMapKey;
          break;
        case 'triggerValueOperator':
          triggerModel.condition3 = e === null ? null : e.value;
          break;
        case 'triggerValue':
          triggerModel.condition4 = e.target.value;
          break;
        case 'monday': 
          // this.setState({monday: e.target.checked ? 1 : 0,
          //   daysOfWeek: e.target.checked ?  daysOfWeek + 1 :  daysOfWeek - 1,
          //   recurrencePattern: 1, 
          // });
          // triggerModel.daysOfWeek = e.target.checked ?  triggerModel.daysOfWeek + 1 :  triggerModel.daysOfWeek - 1;
          // triggerModel.recurrencePattern = 1;
          triggerModel.condition2 = e.target.checked ?  triggerModel.condition2 + 1 :  triggerModel.condition2 - 1;
          triggerModel.condition1 = 1;
          
          break;
        case 'tuesday':
              
            // this.setState({tuesday: e.target.checked ? 1 : 0,
            //   daysOfWeek: e.target.checked ?  daysOfWeek + 2 :  daysOfWeek - 2 ,
            //   recurrencePattern: 1, 
            // });

            // triggerModel.daysOfWeek = e.target.checked ?  triggerModel.daysOfWeek + 2 :  triggerModel.daysOfWeek - 2; 
            // triggerModel.recurrencePattern = 1;
            triggerModel.condition2 = e.target.checked ?  triggerModel.condition2 + 2 :  triggerModel.condition2 - 2;
            triggerModel.condition1 = 1;
            break;

        case 'wednesday':  
            // this.setState({wednesday: e.target.checked ? 1 : 0,
            //   daysOfWeek: e.target.checked ?  daysOfWeek + 4 :  daysOfWeek - 4 ,
            //   recurrencePattern: 1, 
            // });

            // triggerModel.daysOfWeek = e.target.checked ?  triggerModel.daysOfWeek + 4 :  triggerModel.daysOfWeek - 4;
            // triggerModel.recurrencePattern = 1;
            triggerModel.condition2 = e.target.checked ?  triggerModel.condition2 + 4 :  triggerModel.condition2 - 4;
            triggerModel.condition1 = 1;
            
            break;
        case 'thursday': 
            // this.setState({thursday: e.target.checked ? 1 : 0,
            //   daysOfWeek: e.target.checked ?  daysOfWeek + 8 :  daysOfWeek - 8,
            //   recurrencePattern: 1, 
            // });

            // triggerModel.daysOfWeek = e.target.checked ?  triggerModel.daysOfWeek + 8 :  triggerModel.daysOfWeek - 8;
            // triggerModel.recurrencePattern = 1;
            triggerModel.condition2 =  e.target.checked ? triggerModel.condition2 + 8 :  triggerModel.condition2 - 8;
            triggerModel.condition1 = 1;
            
            break; 
        case 'friday':  
            // this.setState({friday: e.target.checked ? 1 : 0,
            //   daysOfWeek: e.target.checked ? daysOfWeek + 16 : daysOfWeek - 16,
            //   recurrencePattern: 1, 
            // });
 
            // triggerModel.daysOfWeek = e.target.checked ? triggerModel.daysOfWeek + 16 : triggerModel.daysOfWeek - 16;
            // triggerModel.recurrencePattern = 1;
            triggerModel.condition2 = e.target.checked ? triggerModel.condition2 + 16 : triggerModel.condition2 - 16;
            triggerModel.condition1 = 1;
            
            break;
        case 'saturday': 
            // this.setState({saturday: e.target.checked ? 1 : 0,
            //   daysOfWeek: e.target.checked ? daysOfWeek + 32 : daysOfWeek - 32,
            //   recurrencePattern: 1, 
            // });

            // triggerModel.daysOfWeek = e.target.checked ? triggerModel.daysOfWeek + 32 : triggerModel.daysOfWeek - 32;
            // triggerModel.recurrencePattern = 1;
            triggerModel.condition2 = e.target.checked ? triggerModel.condition2 + 32 : triggerModel.condition2 - 32;
            triggerModel.condition1 = 1;
            
            break;
        case 'sunday':  
            // this.setState({sunday: e.target.checked ? 1 : 0,
            //   daysOfWeek: e.target.checked ? daysOfWeek + 64 : daysOfWeek - 64,
            //   recurrencePattern: 1, 
            // });

            // triggerModel.daysOfWeek = e.target.checked ? triggerModel.daysOfWeek + 64 : triggerModel.daysOfWeek - 64;
            // triggerModel.recurrencePattern = 1;
            triggerModel.condition2 = e.target.checked ? triggerModel.condition2 + 64 : triggerModel.condition2 - 64;
            triggerModel.condition1 = 1;
            
            break;
        
           
        default: 
    }
    
    this.setState({triggerModel: triggerModel});
    this.sendDataToParent(triggerModel);
  }


sendDataToParent(triggerModel){  
  this.props.sendDataToParentFromTrigger(triggerModel); 
}

getSelTriggerField = (value) => {
  const { triggerFieldList } = this.state

  if (value !== null && value !== "") {
    let test = triggerFieldList.filter(e => e.value === +value);
    return triggerFieldList.filter(e => e.value === +value)
  }
  return ""
}

getSelTriggerValueOperator = (value) => {
  const { triggerValueOperators,triggerModel } = this.state

  if (value !== null && value !== "") { 
    return triggerValueOperators.filter(e => e.value === +value)
  } 
  return ""
}
 
render() {
      const { isLoaded 
      ,triggerByTime 
      ,triggerModel,monthlyRecurrenceFrequency,weeklyRecurrenceFrequency
      ,triggerFieldList,triggerValueOperators,isTriggerFieldLoaded,isSecurityAdminLogin,statusKey} = this.state


    let triggerValueLabel = ''
    if (!isLoaded || !isTriggerFieldLoaded) {
        return (<div>Loading Triggers .....</div>)
    } 
    else{
          
        if (triggerModel !== null && 
              triggerModel.condition1 !== null && triggerModel.condition1 > 0 &&
              triggerModel.condition2 !== null && triggerModel.condition2 > 0 && 
              triggerModel.condition3 !== null && triggerModel.condition3 > 0 &&
              triggerModel.condition4 !== null && triggerModel.condition4 > 0){
              
              let tempStartValue =  this.getSelTriggerField(triggerModel.condition1);
              let tempTriggerField =  this.getSelTriggerField( triggerModel.condition2);
              let tempTriggerValueOperator = this.getSelTriggerValueOperator( triggerModel.condition3);
               
              if (tempStartValue !== null && tempStartValue.length > 0 && tempTriggerField !== null && tempTriggerField.length > 0 && tempTriggerValueOperator !== null && tempTriggerValueOperator.length > 0) {
                triggerValueLabel =  '(' + tempTriggerField[0].label + ') -  (' + tempStartValue[0].label + ') Is' +  tempTriggerValueOperator[0].label + ' ' + triggerModel.condition4 + ' then a work order will be created.'
              }        
        }

        const styles = {
          border: '1px solid #ccc',
          borderRadius: '5px',
          padding: '20px',
          marginBottom: '20px',
          width: triggerByTime ? '1100px' : '1100px',
        };

        const titleStyles = {
          fontWeight: 'bold',
          marginBottom: '10px',
          backgroundColor: '#f2f2f2',
          padding: '10px',
          borderRadius: '5px 5px 0 0',
          borderBottom: '1px solid #ccc',
        };

        return (
          <div>
            {/* triggerModel.itemTypeKey: {triggerModel.itemTypeKey} */}
            <Form style={{marginLeft:"40px" }}>
                <Row Form style={{marginTop:"20px" }}> 
                  <Col md={1} style={{ marginBottom: "10px", marginRight: "-81px" }}>  
                      <Input
                          //invalid={!validateDataItem.recurrencePattern}
                          disabled={!isSecurityAdminLogin || statusKey <= 0} 
                          type="checkbox"  
                          checked={triggerModel !==undefined  && triggerModel !== null &&   triggerModel.triggerType === 1 ? true : false}
                          onChange={(e) => this.onTextChange("triggerByTime", e.target.value, 1)}
                      />
                  </Col>
                  <Col md={3} style={{ marginBottom: "10px" }}> 
                      <Label style={{ marginBottom: "-10px",
                                        fontWeight: (triggerModel !==undefined  && triggerModel !== null &&   triggerModel.triggerType === 1 ? 'bold' : '')                        
                        }} for="meterStatus">Trigger By Time Schedule</Label>  
                  </Col>
                </Row> 
                <Row Form> 
                  <Col md={1} style={{ marginBottom: "10px", marginRight: "-81px" }}> 
                    <Input
                          //invalid={!validateDataItem.recurrencePattern}
                          disabled={!isSecurityAdminLogin || statusKey <= 0} 
                          type="checkbox"  
                          checked={triggerModel !==undefined  && triggerModel !== null &&   triggerModel.triggerType === 2 ? true : false}
                          onChange={(e) => this.onTextChange("triggerByValue", e.target.value, 1)}
                    />
                  </Col>
                  <Col md={3} style={{ marginBottom: "10px" }}> 
                      <Label style={{ marginBottom: "-10px",
                                      fontWeight: (triggerModel !==undefined  && triggerModel !== null &&   triggerModel.triggerType === 2 ? 'bold' : '')
                        }} for="meterStatus">Trigger By Value</Label>  
                  </Col>
                </Row>
                <Row Form hidden={triggerModel !==undefined && triggerModel !== null &&   triggerModel.triggerType !== 1}> 
                    <div style={styles}>
                      {/* {<div style={titleStyles}>Show trigger by time</div>} */}
                      <Label style={{fontWeight: 'bold'}}>Generate Work Order by time schedule.</Label>  
                      <div>
                        <Row Form>
                            <Col md={2} style={{ marginBottom: "10px" }}> 
                                <Label style={{ marginBottom: "-10px" }} for="meterStatus">Type of Frequency</Label>  
                            </Col> 
                            <Col md={2} style={{ marginBottom: "10px" }}> 
                                <Label style={{ marginBottom: "-10px", marginRight: "22px" }} for="monday">Daily</Label> 
                                <Input
                                    //invalid={!validateDataItem.recurrencePattern}
                                    disabled={!isSecurityAdminLogin || statusKey <= 0} 
                                    type="checkbox"  
                                    checked={ triggerModel != undefined && triggerModel != null && 
                                          triggerModel.condition1 !==undefined && triggerModel.condition1 !== null && triggerModel.condition1 === 1 ? 1 : 0}
                                    onChange={(e) => this.onTextChange("recurrencePattern", e.target.value, 1)}
                                />
                            </Col>
                            <Col md={2} style={{ marginBottom: "10px" }}> 
                                <Label style={{ marginBottom: "-10px" , marginRight: "22px"}} for="weekly">Weekly</Label> 
                                <Input
                                        label="M"
                                        //invalid={!validateDataItem.recurrencePattern}
                                        disabled={!isSecurityAdminLogin || statusKey <= 0} 
                                        type="checkbox"  
                                        checked={ triggerModel != undefined && triggerModel != null &&  triggerModel.condition1 !==undefined &&  triggerModel.condition1 !== null &&  triggerModel.condition1 === 2 ? 1 : 0}
                                        onChange={(e) => this.onTextChange("recurrencePattern", e.target.value,2)}
                                    /> 
                            </Col>
                            <Col md={2} style={{ marginBottom: "10px" }}> 
                                <Label style={{ marginBottom: "-10px" , marginRight: "22px"}} for="monthly">Monthly</Label> 
                                <Input
                                        label="M"
                                        //invalid={!validateDataItem.recurrencePattern}
                                        disabled={!isSecurityAdminLogin || statusKey <= 0} 
                                        type="checkbox"  
                                        checked={triggerModel !== undefined && triggerModel !== null && triggerModel.condition1 !== undefined &&  triggerModel.condition1 !== null &&  triggerModel.condition1 === 3 ? 1 : 0}
                                        onChange={(e) => this.onTextChange("recurrencePattern", e.target.value, 3)}
                                    /> 
                            </Col>
                        </Row>
                        <Row Form hidden={triggerModel === undefined || triggerModel === null ||  triggerModel.condition1 == null || triggerModel.condition1 !=1} >
                              <Col md={2} style={{ marginBottom: "10px" }}> 
                                  <Label style={{ marginBottom: "-10px" }} for="meterStatus">Days To Run</Label>  
                              </Col> 
                              <Col md={1} style={{ marginBottom: "10px" }}> 
                                  <Label style={{ marginBottom: "-10px", marginRight: "21px" }} for="monday">Mon</Label> 
                                  <Input
                                      //invalid={!validateDataItem.recurrenceFrequency}
                                      disabled={!isSecurityAdminLogin || statusKey <= 0}  
                                      type="checkbox"  
                                          checked={ triggerModel.condition2 !==undefined &&  triggerModel.condition2 !== null && ( triggerModel.condition2 & 1) === 1 ? 1 : 0}
                                          onChange={(e) => this.onTextChange("monday", e.target.value, e)}
                                      />
                              </Col>
                              <Col md={1} style={{ marginBottom: "10px" }}> 
                                  <Label style={{ marginBottom: "-10px" , marginRight: "21px"}} for="tuesday">Tues</Label> 
                                  <Input
                                          label="M"
                                          //invalid={!validateDataItem.recurrenceFrequency}
                                          disabled={!isSecurityAdminLogin || statusKey <= 0} 
                                          type="checkbox"  
                                          checked={ triggerModel.condition2 !==undefined &&  triggerModel.condition2 !== null && triggerModel.condition2 !== null && ( triggerModel.condition2 & 2) === 2 ? 2 : 0}
                                          onChange={(e) => this.onTextChange("tuesday", e.target.value, e)}
                                      /> 
                              </Col>
                              <Col md={1} style={{ marginBottom: "10px" }}> 
                                  <Label style={{ marginBottom: "-10px" , marginRight: "21px"}} for="wednesday">Wed</Label> 
                                  <Input
                                          //invalid={!validateDataItem.recurrenceFrequency}
                                          disabled={!isSecurityAdminLogin || statusKey <= 0} 
                                          type="checkbox"  
                                          checked={ triggerModel.condition2 !==undefined && triggerModel.condition2 !== null && triggerModel.condition2 !== null && ( triggerModel.condition2 & 4) === 4 ? 4 : 0}
                                          onChange={(e) => this.onTextChange("wednesday", e.target.value, e)}
                                      />
                              </Col>
                              <Col md={1} style={{ marginBottom: "10px" }}> 
                                  <Label style={{ marginBottom: "-10px" , marginRight: "21px"}} for="thursday">Thur</Label> 
                                  <Input
                                          label="M"
                                          //invalid={!validateDataItem.recurrenceFrequency}
                                          disabled={!isSecurityAdminLogin || statusKey <= 0} 
                                          type="checkbox"  
                                          checked={ triggerModel.condition2 !==undefined && triggerModel.condition2 !== null && triggerModel.condition2 !== null && ( triggerModel.condition2 & 8) === 8 ? 8 : 0}
                                          onChange={(e) => this.onTextChange("thursday", e.target.value, e)}
                                      />
                              </Col>
                              <Col md={1} style={{ marginBottom: "10px" }}> 
                                  <Label style={{ marginBottom: "-10px" , marginRight: "21px"}} for="friday">Fri</Label> 
                                  <Input
                                          label="M"
                                          //invalid={!validateDataItem.recurrenceFrequency}
                                          disabled={!isSecurityAdminLogin || statusKey <= 0} 
                                          type="checkbox"  
                                          checked={ triggerModel.condition2 !==undefined && triggerModel.condition2 !== null && triggerModel.condition2 !== null && ( triggerModel.condition2 & 16) === 16 ? 16 : 0}
                                          onChange={(e) => this.onTextChange("friday", e.target.value, e)}
                                      />
                              </Col>
                              <Col md={1} style={{ marginBottom: "10px" }}> 
                                  <Label style={{ marginBottom: "-10px" , marginRight: "21px"}} for="saturday">Sat</Label> 
                                  <Input
                                          label="M"
                                          //invalid={!validateDataItem.recurrenceFrequency}
                                          disabled={!isSecurityAdminLogin || statusKey <= 0} 
                                          type="checkbox"  
                                          checked={ triggerModel.condition2 !==undefined &&  triggerModel.condition2 !== null && triggerModel.condition2 !== null && ( triggerModel.condition2 & 32) === 32 ? 32 : 0}
                                          onChange={(e) => this.onTextChange("saturday", e.target.value, e)}
                                      />
                              </Col>
                              <Col md={1} style={{ marginBottom: "10px" }}> 
                                  <Label style={{ marginBottom: "-10px" , marginRight: "21px"}} for="sunday">Sun</Label> 
                                  <Input
                                          label="M"
                                          //invalid={!validateDataItem.recurrenceFrequency}
                                          disabled={!isSecurityAdminLogin || statusKey <= 0} 
                                          type="checkbox"  
                                          checked={ triggerModel.condition2 !==undefined &&  triggerModel.condition2 !== null && (triggerModel.condition2 & 64) === 64 ? 64 : 0} 
                                          onChange={(e) => this.onTextChange("sunday", e.target.value, e)}
                                      />
                              </Col>
                        </Row>
                        <Col md={4} style={{ marginBottom: "10px" }} hidden={triggerModel.condition1 == null || triggerModel.condition1 != 2 }> 
                              <FormGroup>
                                  <Label style={{ marginBottom: "-10px" }} for="recurrenceFrequency">Recurrence Frequency</Label>
                                  <Input 
                                      //invalid={!validateDataItem.recurrenceFrequency} 
                                      type="select" 
                                      disabled={!isSecurityAdminLogin || statusKey <= 0} 
                                      value={triggerModel !== undefined && triggerModel !== null && triggerModel.condition2 !== undefined && triggerModel.condition2 !== null ? triggerModel.condition2: null}
                                      name="recurrenceFrequency" 
                                      id="recurrenceFrequency"
                                      onChange={(e) => this.onTextChange("recurrenceFrequency", e , e)} 
                                      >
                                      <option value=''></option>
                                      {weeklyRecurrenceFrequency.map((asp) => (
                                              <option value={asp.value}>{asp.label}</option>
                                              ))} 
                                  </Input>
                                  {/* <FormFeedback invalid>Please select a Recurrence Frequency.</FormFeedback>  */}
                              </FormGroup>
                        </Col>
                        <Col md={4} style={{ marginBottom: "10px" }} hidden={triggerModel.condition1 != 3}> 
                              <FormGroup>
                                  <Label style={{ marginBottom: "-10px" }} for="recurrenceFrequency">Recurrence Frequency</Label>
                                  <Input 
                                      //invalid={!validateDataItem.recurrenceFrequency} 
                                      type="select" 
                                      disabled={!isSecurityAdminLogin || statusKey <= 0} 
                                      value={ triggerModel !== undefined && triggerModel !== null && triggerModel.condition2 !== undefined &&  triggerModel.condition2 !== null ?  triggerModel.condition2: null}
                                      name="recurrenceFrequency" 
                                      id="recurrenceFrequency"
                                      onChange={(e) => this.onTextChange("recurrenceFrequency", e , e)} 
                                      >
                                      <option value=''></option>
                                      {monthlyRecurrenceFrequency.map((asp) => (
                                              <option value={asp.value}>{asp.label}</option>
                                              ))} 
                                  </Input>
                                  {/* <FormFeedback invalid>Please select a Recurrence Frequency.</FormFeedback>  */}
                              </FormGroup>
                          </Col>
                      </div>
                    </div>

                </Row>
                <div style={{border: '1px solid #ccc' , padding: '20px', display: triggerModel.triggerType !== 2 ? "none" : ""}}>
                  <Label style={{fontWeight: 'bold'}}>Generate Work Order by a value</Label> 
                  <Row Form hidden={triggerModel.triggerType !== 2} style={{paddingBottom: "10px"}}> 
                      {/* <div style={styles}> */}
                        <Col md={3}>
                          <Label>Starter Value</Label>  
                        </Col>
                        <Col md={4}>
                          <Select
                              options={triggerFieldList.filter(x => x.pmScheduleField == 'StarterValue')}
                              disabled={!isSecurityAdminLogin || statusKey <= 0}
                              value={this.getSelTriggerField( triggerModel.condition1)}
                              onChange={(e) => this.onTextChange("starterValue", e, e)}
                              placeholder={""}
                              isClearable={true}
                              >
                            </Select>  
                        </Col> 
                  </Row>
                  <Row Form hidden={triggerModel.triggerType !== 2} style={{paddingBottom: "10px"}}>  
                        <Col md={3}>
                          <Label>Trigger Field</Label>  
                        </Col>
                        <Col md={4}>
                          <Select
                            options={triggerFieldList.filter(x => x.pmScheduleField == 'TriggerField')}
                            disabled={!isSecurityAdminLogin || statusKey <= 0}  
                            value={this.getSelTriggerField( triggerModel.condition2)}
                            onChange={(e) => this.onTextChange("triggerField", e, e)}
                            placeholder={""}
                            isClearable={true}
                            >
                          </Select> 
                        </Col> 
                  </Row>
                  <Row Form  >  
                        <Col md={3}>
                          {/* <Label>Trigger Value</Label>   */}
                          <Select
                            options={triggerValueOperators}
                            disabled={!isSecurityAdminLogin || statusKey <= 0} 
                            // className={
                            //     !validDataItem.locationKey
                            //     ? "alway-on-top-more form-error"
                            //     : ""
                            // }
                            value={this.getSelTriggerValueOperator( triggerModel.condition3)}
                            onChange={(e) => this.onTextChange("triggerValueOperator", e, e)}
                            placeholder={"Select Logic Operator"}
                            isClearable={true}
                            >
                          </Select>
                        </Col>
                        <Col md={4}>
                          <Input 
                              disabled={!isSecurityAdminLogin || statusKey <= 0} 
                              name="triggerValue" 
                              id="meterSize" 
                              type="number"
                              min="0"
                              value={triggerModel.condition4}
                              onChange={(e) => this.onTextChange("triggerValue", e.target.value, e)}
                          />  
                        </Col> 
                  </Row>
                  <Row Form  >
                    <Col md={12} style={{marginTop: "20px"}}>
                      <Label style={{fontWeight: 'bold' }}>{triggerValueLabel}</Label>
                    </Col>
                  </Row>
                </div> 
                
            </Form>
          </div>

    
        )
    }
        
}

}

 