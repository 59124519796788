import React, { Component, createRef, useState, forwardRef, useRef, useImperativeHandle } from 'react';
import { Form, Col, Row, Button, FormGroup, Label, Input, InputGroupText, InputGroup, CardGroup } from 'reactstrap';
  
import { TabContent, TabPane, Nav, NavItem, NavLink, FormFeedback, Alert } from 'reactstrap'; 
import '../../../src/custom.css' 
import { apiURL,apiHeader, apiPOSTHeader, apiPUTHeader, apiDELETEHeader } from '../../resources/apiURL'; 
import classnames from 'classnames';  
import ScheduledMaintenanceDetails from './ScheduledMaintenanceDetails' 
import ScheduledMaintenanceAssets from './ScheduledMaintenanceAssets';
import ScheduledMaintenanceTrigger from './ScheduledMaintenanceTrigger'
import WOHome from './WOHome';

var DatePicker = require("reactstrap-date-picker");
 
class ScheduledMaintenanceMetaData extends React.Component {
    static displayName =  'Alex testing' //AFEDetail.name

    constructor(props) {
        super(props)

        this.receivedFromParentFromDetails = this.receivedFromParentFromDetails.bind(this);
        this.receivedFromParentFromTrigger = this.receivedFromParentFromTrigger.bind(this);
        this.receivedFromScheduledAssets = this.receivedFromScheduledAssets.bind(this);
        this.getSchedule = this.getSchedule.bind(this);
        this.runSchedule = this.runSchedule.bind(this);
        this.dataValidation = this.dataValidation.bind(this);
        this.closeForm = this.closeForm.bind(this);

        this.state = {
            user: props.user, 
            isLoaded: false,
            activeTab: '1',
            user:this.props.user,
            isSecurityAdminLogin:props.isSecurityAdminLogin,
            pmScheduleKey: this.props.pmScheduleKey,
            newSchedule: this.props.newSchedule,
            mainTypeList: [],
            isMainTypeListLoaded: false,
            issueTypeList: [],
            isIssueTypeListLoaded: false,
            isBasinListLoaded: false,
            basinList: [],
            isFacilityListLoaded: false,
            facilityList: [],
            isPriorityListLoaded: false,
            priorityList: [],
            isItemTypeListLoaded: false,
            itemTypeList: [],
            isStatusListLoaded: false,
            statusList: [],
            scheduleModel: null,
            triggerModel: null,
            alertMessage: "",
            showAlert: false,
            showSaveAlert: false, 
            alertColor: "danger",
            scheduleAsset: [],
            validateDataItem: {
                scheduleName: true,
                itemTypeKey: true,
                priority: true,
                maintenanceTypeKey: true,
                issueTypeKey: true,
                locationKey: true,
                startDate: true,
                endDate: true, 
            },
            newScheduleCreated: false,
            WOData: this.props.WOData,
            isAssetMaintenanceIssueTypeMappingLoaded: false,
            assetMaintenanceIssueTypeMapping: [], 
        }   
    }

    toggleTab = tab => {
        const { activeTab } = this.state

        if (activeTab !== tab) {
            this.setState({ activeTab: tab })
        }
    }
    
    async componentDidMount() {
        await this.getAssetMaintenanceIssueTypeMapping(); 
        await this.getMainType();
        await this.getIssueType();
        await this.getBasinList();
        await this.getFacilityList();
        await this.getPriority();
        await this.getItemType();
        await this.getStatus();

        const { pmScheduleKey,user} = this.state;
        
        let scheduleModel = null;
        
        if (pmScheduleKey <= 0){
            let todaysDate = new Date();
            scheduleModel = { 
                pmScheduleKey: 0,
                pmScheduleType: 1,
                scheduleID: 'XXXXX',
                scheduleName:   '',
                scheduleDescription: '',
                itemTypeKey: 0,
                maintenanceTypeKey:  0,
                issueTypeKey:  0,
                locationKey:  0,
                facilityKey:  0,
                startDate:  null,
                endDate:  null,
                priority:  0,
                instructions: '',
                pmScheduleTriggerKey: 0,
                triggerType: null, 
                triggerNumberValue: null, 
                condition1: null,
                condition2: null,
                condition3: null,
                condition4: null,
                createdByKey: 0,
                createdBy: user.account.userName,
                createdDate: todaysDate,
                createdByAzureAD: user.account.email,
                lastModifiedBy: 0,
                lastModifiedDate: todaysDate,
            }
            this.setState({ scheduleModel: scheduleModel,scheduleAsset: [] ,isLoaded: true})
        }
        else{ 
            await this.getSchedule();
            await this.getScheduleTriggers();
            console.log('tesat: ' + this.state.scheduleModel);
        }
 
        this.setState({isLoaded: true }); 
        //this.state.isLoaded = true;
    }
   
    async getSchedule(parm_PMScheduleKey){
        const { pmScheduleKey} = this.state;

        let tempPMScheduleKey = 0;

        if (parm_PMScheduleKey != undefined && parm_PMScheduleKey != null && parm_PMScheduleKey > 0){
            tempPMScheduleKey = parm_PMScheduleKey;
        }
        else{
            tempPMScheduleKey = pmScheduleKey;
        }

        let myURI = apiURL + 'ScheduleMaintenance/PMScheduleDetails/' + tempPMScheduleKey;
        await fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
          .then(res => res.json())
          .then(json => { 
            this.setState({ scheduleModel: json});
          });
    }

    async getScheduleTriggers(){
        let {scheduleModel,pmScheduleKey,itemTypeKey} = this.state;
    
        let triggerModel = null;
    
        if (scheduleModel !== undefined && scheduleModel !== null && scheduleModel.pmScheduleKey > 0){
          let myURI = apiURL + 'ScheduleMaintenance/PMScheduleTrigger/' + scheduleModel.pmScheduleKey;
          await fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
              .then(res => res.json())
              .then(json => { 
                triggerModel= json 
                scheduleModel.pmScheduleTriggerKey = json[0].pmScheduleTriggerKey;
                scheduleModel.triggerType = json[0].triggerType;
                scheduleModel.condition1 = +json[0].condition1;
                scheduleModel.condition2 = +json[0].condition2;
                scheduleModel.condition3 = +json[0].condition3;
                scheduleModel.condition4 = +json[0].condition4;
                this.setState({
                    scheduleModel: scheduleModel,
                })
          });
        }
        else
        {
          triggerModel = 
          { 
            pmScheduleTriggerKey: 0,
            pmScheduleKey: pmScheduleKey,
            triggerType: null,
            triggerByTime: null,
            triggerByValue: null,
            triggerNumberValue: null, 
            condition1: 0,
            condition2: 0,
            condition3: 0,
            condition4: 0,
            itemTypeKey: itemTypeKey,
          }
          
        } 
        this.setState({triggerModel: triggerModel}); 
      }

    componentDidUpdate(prevProps, prevState) {
        //this.state.isLoaded = false;
        if (prevState.pmScheduleKey !== this.state.pmScheduleKey) {
          this.setState({pmScheduleKey: this.props.pmScheduleKey,isLoaded: true });
        } 
        //this.setState({isLoaded: true});
    }
     
    closeForm(){ 
        this.props.closeScheduleDetails();
    }  


    getAssetMaintenanceIssueTypeMapping = async() =>{
        let myURI = apiURL + 'ScheduleMaintenance/AssetMaintenanceIssueTypeMapping'
        await fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
          .then(res => res.json())
          .then(json => {
    
            this.setState({
                isAssetMaintenanceIssueTypeMappingLoaded: true,
                assetMaintenanceIssueTypeMapping: json 
            }) 
          }); 
    }

    getMainType = async() => {

        let myURI = apiURL + 'ScheduleMaintenance/MainType'
        await fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
          .then(res => res.json())
          .then(json => {
    
            this.setState({
              isMainTypeListLoaded: true,
              mainTypeList: this.createMainTypeList(json)//.filter(x => x.fixxMainTypeKey == 28)
            })
            // console.log(json)
          });
    
    }

    createMainTypeList = (value) => {
        var tempList = []
        value.map(e => {
            e.value = e.fixxMainTypeKey
            e.label = e.fixxMainTypeDesc
            tempList.push(e)
        })

        return tempList
    }  

    getIssueType = async() => {
        const { woData, maintenanceTypeID } = this.state
    
        let myURI = apiURL + 'ScheduleMaintenance/FiixIssueType'
        await fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
          .then(res => res.json())
          .then(json => {
            let issueTypeList = this.createIssueTypeList(maintenanceTypeID == null || maintenanceTypeID == 0 ? json : json.filter(a => a.codeDefinitionParentKey == maintenanceTypeID))
            //issueTypeList = issueTypeList.filter(x => x.value == 2424);
            this.setState({
              isIssueTypeListLoaded: true,
              issueTypeList:  issueTypeList,
            })
            
    
          });
    
    }

    createIssueTypeList = (value) => {
        const { woData } = this.state
        var tempList = []
    
    
        value.map(e => {
          e.value = e.codeDefinitionKey
          e.label = e.fiixMainTypeDesc
          tempList.push(e)
        })
    
        return tempList
      }

    getBasinList = async() => {

        let myURI = apiURL + 'Fiix/GetBasin'
        await fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

            this.setState({
                isBasinListLoaded: true,
                basinList: this.createBasinList(json)
            })

            });

    }

    createBasinList = (value) => {
        var tempList = []
        value.map(e => {
          e.value = e.locationKey
          e.label = e.strName
          tempList.push(e)
        })
    
        return tempList
    }

    getFacilityList = async() => {

        let myURI = apiURL + 'FacilityDetails'
        await fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

            this.setState({
                isFacilityListLoaded: true,
                facilityList: this.createFacilityList(json), 
            })

        });

    }

    createFacilityList = (value) => {
        var tempList = []
        value.map(e => {
          e.value = e.facilityKey
          e.label = e.facilityName
          tempList.push(e)
        })
    
        return tempList
    }

    getPriority = async() => {

        let myURI = apiURL + 'ScheduleMaintenance/Priority'
        await fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

            this.setState({
                isPriorityListLoaded: true,
                priorityList: this.createPriorityList(json)
            })

            });

    }

    createPriorityList = (value) => {
        var tempList = []
        value.map(e => {
          e.value = e.fiixPriorityKey
          e.label = e.fiixPriorityFullDesc
          tempList.push(e)
        })
    
        return tempList
    }

    getItemType = async() => {

        let myURI = apiURL + 'ScheduleMaintenance/ItemType'
        await fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

            this.setState({
                isItemTypeListLoaded: true,
                itemTypeList: this.createItemTypeList(json)
            }) 
        }); 
    }

    createItemTypeList = (value) => {
        var tempList = []
        value.map(e => {
          e.value = e.itemTypeKey
          e.label = e.itemTypeDesc
          tempList.push(e)
        })
    
        return tempList
    }

    getStatus = async() => {

        let myURI = apiURL + 'ScheduleMaintenance/PMScheduleStatus'
        await fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
            .then(res => res.json())
            .then(json => {

            this.setState({
                isStatusListLoaded: true,
                statusList: this.createStatusList(json)
            }) 
        }); 
    }

    createStatusList = (value) => {
        var tempList = []
        value.map(e => {
          e.value = e.pmScheduleStatusKey
          e.label = e.pmScheduleStatusDesc
          tempList.push(e)
        })
    
        return tempList
    }

    saveData = async()=>{
        let {scheduleModel} = this.state

        this.dataValidation().then((data) => {
            if (data.ok) {

                //this.childRef.current.saveLineData()

                if (scheduleModel.pmScheduleKey > 0) {
                     this.editSchedule(); 
                } else {
                    this.addSchedule();
                }
  
            } else {
                this.setState({
                    alertMessage: data.message,
                    validateDataItem: data.validateDataItem,
                    showAlert: true
                })
            }
        })
    }

    dataValidation = (statusKey) => {
        return new Promise((resolve, reject) => {
            const { scheduleModel, validateDataItem ,WOData} = this.state

            const returnVal = {
                "ok": true,
                "message": "",
                "validationDataItem": []
            }

            if (scheduleModel.scheduleName === null || scheduleModel.scheduleName.trim() === '' ) {
                validateDataItem.scheduleName = false;
                returnVal.ok = false;
                returnVal.message = returnVal.message + 'Please provide a Schedule Name\n'
            }
            else if(scheduleModel.pmScheduleKey <= 0){
                var temp = WOData.filter(x => x.scheduleName == scheduleModel.scheduleName);
                if (temp.length > 0){
                    validateDataItem.scheduleName = false;
                    returnVal.ok = false;
                    returnVal.message = returnVal.message + 'There is already a schedule with this name.  Plese select a different schedule name.\n'
                }
            }    

            if (scheduleModel.itemTypeKey === undefined || scheduleModel.itemTypeKey === null || scheduleModel.itemTypeKey <= 0) {
                validateDataItem.itemTypeKey = false;
                returnVal.ok = false
                returnVal.message = returnVal.message + 'Please select an Asset Type\n'
            }
            if (scheduleModel.priority === undefined || scheduleModel.priority === null || scheduleModel.priority <= 0) {
                validateDataItem.priority = false;
                returnVal.ok = false
                returnVal.message = returnVal.message + 'Please select an Priority\n'
            }
            if (scheduleModel.maintenanceTypeKey === undefined || scheduleModel.maintenanceTypeKey === null || scheduleModel.maintenanceTypeKey <= 0) {
                validateDataItem.maintenanceTypeKey = false
                returnVal.ok = false
                returnVal.message = returnVal.message + 'Please select an Maintenance Type\n'
            }
            if (scheduleModel.issueTypeKey === undefined || scheduleModel.issueTypeKey === null || scheduleModel.issueTypeKey <= 0) {
                validateDataItem.issueTypeKey = false;
                returnVal.ok = false
                returnVal.message = returnVal.message + 'Please select an Issue Type\n'
            }
            if (scheduleModel.locationKey === undefined || scheduleModel.locationKey === null || scheduleModel.locationKey <= 0) {
                validateDataItem.locationKey = false;
                returnVal.ok = false;
                returnVal.message = returnVal.message + 'Please select an Basin\n'
            }
            if (scheduleModel.startDate === undefined ||  scheduleModel.startDate === null ||  scheduleModel.startDate === ''){
                returnVal.ok = false;
                returnVal.message = returnVal.message + 'Please enter Start Date.\n';
                validateDataItem.startDate = false; 
            }
            if (scheduleModel.startDate !== undefined && scheduleModel.startDate !== null && scheduleModel.startDate !== '' &&
                scheduleModel.endDate !== undefined && scheduleModel.endDate !== null && scheduleModel.endDate !== '' &&
                scheduleModel.startDate  > scheduleModel.endDate){
                returnVal.ok = false;
                returnVal.message = returnVal.message + 'Start date cannot be before end date.\n';
                validateDataItem.startDate = false;
                validateDataItem.endDate = false 
            }

            if (scheduleModel.triggerType === undefined || scheduleModel.triggerType === null || scheduleModel.triggerType <= 0) {
                //validateDataItem.triggerType = false
                returnVal.ok = false
                returnVal.message = returnVal.message + 'Please select an Trigger by Time or Trigger by Value\n'
            }
            if (scheduleModel.triggerType != undefined && scheduleModel.triggerType != null && scheduleModel.triggerType === 1) {
                if (scheduleModel.condition1 == null || scheduleModel.condition1 <=0){
                    //validateDataItem.triggerType = false
                    returnVal.ok = false
                    returnVal.message = returnVal.message + 'Please choose a Daily,Weekly or Monthly\n'
                }
                if (scheduleModel.condition1 === 1 && (scheduleModel.condition2 == null || scheduleModel.condition2 <=0)){
                    //validateDataItem.triggerType = false
                    returnVal.ok = false
                    returnVal.message = returnVal.message + 'Please choose a Days to Run.\n'
                }
                if (scheduleModel.condition1 === 2 && (scheduleModel.condition2 == null || scheduleModel.condition2 <=0)){
                    //validateDataItem.triggerType = false
                    returnVal.ok = false
                    returnVal.message = returnVal.message + 'Please select a Recurrence Frequency.\n'
                }
                if (scheduleModel.condition1 === 3 && (scheduleModel.condition2 == null || scheduleModel.condition2 <=0)){
                    //validateDataItem.triggerType = false
                    returnVal.ok = false
                    returnVal.message = returnVal.message + 'Please select a Recurrence Frequency.\n'
                }
            }
            if (scheduleModel.triggerType != undefined && scheduleModel.triggerType != null && scheduleModel.triggerType === 2){
                if (scheduleModel.condition1 == null || scheduleModel.condition1 <=0){
                    //validateDataItem.triggerType = false
                    returnVal.ok = false
                    returnVal.message = returnVal.message + 'Please select a Starter Value.\n'
                }
                if (scheduleModel.condition2 == null || scheduleModel.condition2 <=0){
                    //validateDataItem.triggerType = false
                    returnVal.ok = false
                    returnVal.message = returnVal.message + 'Please select a Trigger Field.\n'

                }

                if (scheduleModel.condition1 !== null && scheduleModel.condition1 > 0 && scheduleModel.condition2 !== null && scheduleModel.condition2 > 0 &&
                    scheduleModel.condition1 === scheduleModel.condition2){
                        returnVal.ok = false
                        returnVal.message = returnVal.message + 'Starter value cannot be the same as Trigger Field.\n' 
                }

                if (scheduleModel.condition3 == null || scheduleModel.condition3 <=0){
                    //validateDataItem.triggerType = false
                    returnVal.ok = false
                    returnVal.message = returnVal.message + 'Please select a Trigger Value Operator.\n'
                    
                }
                if (scheduleModel.condition4 == null || scheduleModel.condition4 <=0){
                    //validateDataItem.triggerType = false
                    returnVal.ok = false
                    returnVal.message = returnVal.message + 'Please provide a Trigger Value.\n'
                    
                } 
            }


            returnVal.validateDataItem = validateDataItem
            if (returnVal.message !== "") {
                returnVal.message = 'Please enter missing data: \n' + returnVal.message
            }
            resolve(returnVal)
        })
    }

    async editSchedule(){
        const  {scheduleModel,user,scheduleAsset} = this.state;
        let objFlag = false;
        let tempPMScheduleKey = 0;
        scheduleModel.pmScheduleTrigger =  JSON.stringify([{   pmScheduleKey: scheduleModel.pmScheduleKey,
                                                                pmScheduleTriggerKey: scheduleModel.pmScheduleTriggerKey,
                                                                triggerType: scheduleModel.triggerType,
                                                                operatorLogic: null,
                                                                condition1: scheduleModel.condition1,
                                                                condition2: scheduleModel.condition2,
                                                                condition3: scheduleModel.condition3,
                                                                condition4: scheduleModel.condition4,
                                                            }]);

        scheduleModel.pmScheduleAsset =  JSON.stringify(scheduleAsset);

        let myURI = apiURL + 'ScheduleMaintenance/PMSchedule/Edit/' + user.account.userName;
        console.log('myURI: ' + myURI);
        await fetch(myURI, apiPOSTHeader(scheduleModel))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    rspData.then(t => {
                        // this.setState({
                        //     msgBody: t,
                        //     msgHeader: "Error",
                        //     modalMsg: !this.state.modalMsg
                        // });

                        this.setState({
                            alertMessage: t,
                            //validateDataItem: data.validateDataItem,
                            showAlert: true
                        })
                    })

                    return Promise.reject(error)
                } else{
                    // this.setState({edited: true });
                    objFlag = true;
                    return response.json() 
                    
                }
                    
            }).then(json => { 
                
            }).catch(error => {
                console.log("PUT error: " + error)
            })

            if (objFlag){ 
                await this.setState({isLoaded: false });
                await this.getSchedule(scheduleModel.pmScheduleKey);
                await this.getScheduleTriggers();
                await this.setState({isLoaded: true,newScheduleCreated: true }); 
                this.toggleSaveAlsert();
                //this.props.dataSentFromMeta(true);
            }
        
    }

    async addSchedule(){
        const  {scheduleModel,scheduleAsset,user} = this.state;
        scheduleModel.pmScheduleTrigger =  JSON.stringify([{   pmScheduleKey: scheduleModel.pmScheduleKey,
                                        pmScheduleTriggerKey: 0,
                                        triggerType: scheduleModel.triggerType,
                                        operatorLogic: null,
                                        condition1: scheduleModel.condition1,
                                        condition2: scheduleModel.condition2,
                                        condition3: scheduleModel.condition3,
                                        condition4: scheduleModel.condition4,
                                    }]);
        
        scheduleModel.pmScheduleAsset =  JSON.stringify(scheduleAsset);
                                    
        let objFlag = false;
        let tempPMScheduleKey = 0;
        let myURI = apiURL + 'ScheduleMaintenance/PMSchedule/Add/' + user.account.userName;
        console.log('myURI: ' + myURI);
        fetch(myURI, apiPOSTHeader(scheduleModel))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    rspData.then(t => {
                        this.setState({
                            // msgBody: t,
                            // msgHeader: "Error",
                            // modalMsg: !this.state.modalMsg
                            alertMessage: t,
                            //validateDataItem: data.validateDataItem,
                            showAlert: true
                        })
                    })

                    return Promise.reject(error)
                } else{
                      
                    objFlag = true;
                    
                    return response.json()
                }
                    
            }).then(json => { 
                if (objFlag){ 
                    this.setState({isLoaded: false,pmScheduleKey: json.pmScheduleKey});
                    this.getSchedule(json.pmScheduleKey);
                    this.getScheduleTriggers();
                    this.setState({isLoaded: true,newScheduleCreated: true }); 
                    this.toggleSaveAlsert();
                    this.props.dataSentFromMeta(true);
                } 
            }).catch(error => {
                console.log("PUT error: " + error)
            }) 

    }

    async runSchedule(){
        const  {scheduleModel,scheduleAsset,user} = this.state;
         

        if (scheduleModel.statusKey <= 0){
            this.setState({
                alertMessage: "The schedule status has to be Active to run schedule.",
                //validateDataItem: data.validateDataItem,
                showAlert: true
            })
            return; 
        }

                                    
        let objFlag = false;
        let tempPMScheduleKey = 0;
        let myURI = apiURL + 'ScheduleMaintenance/' + scheduleModel.pmScheduleKey + '/RunSchedule/' + user.account.userName;
        console.log('myURI: ' + myURI);
        fetch(myURI, apiPOSTHeader(scheduleModel))
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })

                    return Promise.reject(error)
                } else{
                      
                    objFlag = true;
                    
                    return response.json()
                }
                    
            }).then(json => { 
                if (objFlag){ 
                    this.setState({isLoaded: false,
                                    pmScheduleKey: json.pmScheduleKey  
                                });
                    this.getSchedule(json.pmScheduleKey);
                    this.getScheduleTriggers();
                    this.setState({isLoaded: true }); 
                    this.toggleSaveAlsert();
                } 
            }).catch(error => {
                console.log("PUT error: " + error)
            }) 
    }

    async deleteSchedule(){
        const  {scheduleModel,user} = this.state;
         
                                    
        let objFlag = false;
        let tempPMScheduleKey = 0;
        let myURI = apiURL + 'ScheduleMaintenance/PMSchedule/' + scheduleModel.pmScheduleKey + '/Delete/' + user.account.userName;
        console.log('myURI: ' + myURI);
        await fetch(myURI, apiPOSTHeader())
            .then(async response => {
                if (!response.ok) {
                    const rspData = response.text();
                    var error = (rspData && rspData.message) || response.status;

                    rspData.then(t => {
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg
                        })
                    })

                    return Promise.reject(error)
                } else{
                      
                    objFlag = true;
                    
                    return response.json()
                }
                    
            }).then(json => { 
                if (objFlag){ 
                    this.setState({isLoaded: false,
                                    pmScheduleKey: 0 
                                });
                    this.props.detetePMSchedule();
                } 
            }).catch(error => {
                console.log("PUT error: " + error)
            }) 
    }

    receivedFromParentFromDetails(data,validateDataItem){
        let {scheduleModel} = this.state;
        scheduleModel.scheduleName =  data.scheduleName;
        scheduleModel.scheduleDescription = data.scheduleDescription;
        scheduleModel.itemTypeKey = data.itemTypeKey;
        scheduleModel.maintenanceTypeKey = data.maintenanceTypeKey;
        scheduleModel.issueTypeKey = data.issueTypeKey;
        scheduleModel.locationKey = data.locationKey;
        scheduleModel.facilityKey = data.facilityKey;
        scheduleModel.startDate = data.startDate;
        scheduleModel.endDate = data.endDate;
        scheduleModel.priority = data.priority;
        scheduleModel.instructions = data.instructions;
        scheduleModel.statusKey = data.statusKey;
        
        let tempValidateDataItem = this.state.validateDataItem;

        tempValidateDataItem.scheduleName = validateDataItem.scheduleName;
        tempValidateDataItem.itemTypeKey = validateDataItem.itemTypeKey; 
        tempValidateDataItem.priority = validateDataItem.priority;
        tempValidateDataItem.maintenanceTypeKey =  validateDataItem.maintenanceTypeKey; 
        tempValidateDataItem.issueTypeKey =  validateDataItem.issueTypeKey;
        tempValidateDataItem.locationKey =  validateDataItem.locationKey; 
        tempValidateDataItem.startDate =  validateDataItem.startDate; 
        tempValidateDataItem.endDate =  validateDataItem.endDate; 
 
        this.setState({scheduleModel: scheduleModel,validateDataItem: tempValidateDataItem,showAlert: false});
    } 

    receivedFromParentFromTrigger(data){
        
        if (data !== undefined && data !== null){
            let {scheduleModel} = this.state;
            scheduleModel.pmScheduleTriggerKey = data.pmScheduleTriggerKey; 
            scheduleModel.triggerType = data.triggerType;
            scheduleModel.triggerNumberValue = data.triggerNumberValue; 
            scheduleModel.condition1 = data.condition1;
            scheduleModel.condition2 = data.condition2;
            scheduleModel.condition3 = data.condition3;
            scheduleModel.condition4 = data.condition4; 
            this.setState({scheduleModel: scheduleModel});
        }
          
    } 

    receivedFromScheduledAssets(data){ 
        this.setState({scheduleAsset: data});
    }

    toggleAlert = () => { 
        this.setState({
            showAlert: !this.state.showAlert
        })
    }

    toggleSaveAlsert = () => {
        this.setState({showAlert: false, showSaveAlert: !this.state.showSaveAlert },
            () => { window.setTimeout(() => { this.setState({ showSaveAlert: false }) }, 3000) })
    }

    render() {
         const {user, alertColor,alertMessage,showSaveAlert,showAlert,isLoaded,activeTab,pmScheduleKey
                ,newSchedule,mainTypeList ,isMainTypeListLoaded,issueTypeList,isIssueTypeListLoaded
                ,basinList,isBasinListLoaded,facilityList,isFacilityListLoaded,isPriorityListLoaded
                ,priorityList,scheduleModel,scheduleAsset,isItemTypeListLoaded,itemTypeList
                ,statusList,validateDataItem,isSecurityAdminLogin,assetMaintenanceIssueTypeMapping
                ,isAssetMaintenanceIssueTypeMappingLoaded} = this.state
 
        if (!isLoaded || !isMainTypeListLoaded || !isIssueTypeListLoaded || !isBasinListLoaded 
                        || !isFacilityListLoaded || !isPriorityListLoaded || !isItemTypeListLoaded || !isAssetMaintenanceIssueTypeMappingLoaded) {
            return (<div>Loading Work Order Schedule Data .....</div>)
        } 
        else{
            
            
          

            return (
                <div>
                    {/* scheduleModel.scheduleName:   {scheduleModel == null || scheduleModel.scheduleName == null ? '' : scheduleModel.scheduleName}
                    <br/> 
                    scheduleModel.pmScheduleKey: {scheduleModel.pmScheduleKey == null ? 0 : scheduleModel.pmScheduleKey}
                    <br/>
                    scheduleModel.scheduleID: {scheduleModel.scheduleID == null ? 0 : scheduleModel.scheduleID}
                    <br/> 
                    scheduleModel.scheduleDescription:  {scheduleModel.scheduleDescription == null ? '' : scheduleModel.scheduleDescription}
                    <br/>
                    scheduleModel.itemTypeKey: {scheduleModel.itemTypeKey == null ? 0 : scheduleModel.itemTypeKey}
                    <br/> 
                    scheduleModel.maintenanceTypeKey: {scheduleModel.maintenanceTypeKey == null ? 0 : scheduleModel.maintenanceTypeKey}
                    <br/>
                    scheduleModel.issueTypeKey: {scheduleModel.issueTypeKey == null ? 0 : scheduleModel.issueTypeKey}
                    <br/>
                    scheduleModel.locationKey: {scheduleModel.locationKey == null ? 0 : scheduleModel.locationKey}
                    <br/>
                    scheduleModel.facilityKey: {scheduleModel.facilityKey == null ? 0 : scheduleModel.facilityKey}
                    <br/>
                    scheduleModel.startDate: {scheduleModel.startDate == null ? '' : scheduleModel.startDate}
                    <br/>
                    scheduleModel.endDate: {scheduleModel.endDate == null ? '' : scheduleModel.endDate}
                    <br/>
                    scheduleModel.priority: {scheduleModel.priority == null ? 0 : scheduleModel.priority}
                    <br/>
                    scheduleModel.instructions: {scheduleModel.instructions == null ? '' : scheduleModel.instructions} 
                    <br/>
                    scheduleModel.triggerType: {scheduleModel.triggerType == null ? 0 : scheduleModel.triggerType}
                    <br/>
                    scheduleModel.condition1: {scheduleModel.condition1 == null ? 0 : scheduleModel.condition1}
                    <br/>
                    scheduleModel.condition2: {scheduleModel.condition2 == null ? 0 : scheduleModel.condition2}
                    <br/>
                    scheduleModel.condition3: {scheduleModel.condition3 == null ? 0 : scheduleModel.condition3}
                    <br/>
                    scheduleModel.condition4: {scheduleModel.condition4 == null ? 0 : scheduleModel.condition4}
                    <br/>
                    scheduleModel.statusKey: {scheduleModel.statusKey == null ? 0 : scheduleModel.statusKey} 
                    <br/> */}
                    <Form >
                        <Row form style={{ marginLeft: "5px", marginBottom: "10px" }}>
                            <Col sm={12}>
                                <Button 
                                    color="secondary" 
                                    className="float-left" 
                                    hidden={newSchedule ? false : true }
                                    onClick={() => this.closeForm()}>Close
                                </Button>  
                                <Button 
                                    color="primary" 
                                    className="float-left"
                                    style={{ marginLeft: "5px" }}
                                    //ref={ref}
                                    disabled={!isSecurityAdminLogin}
                                    onClick={() => this.saveData()}>Save
                                </Button> 
                                <Button 
                                    color="success" 
                                    className="float-left"
                                    style={{ marginLeft: "5px" }}
                                    //ref={ref}
                                    hidden={(pmScheduleKey == undefined || pmScheduleKey == null || pmScheduleKey < 0 || pmScheduleKey === 0)}
                                    disabled={!isSecurityAdminLogin || scheduleModel.statusKey <= 0}
                                    onClick={() => this.runSchedule()}>Run Schedule
                                </Button>
                                <Button 
                                    color="danger" 
                                    className="float-right" 
                                    hidden={!newSchedule ? false : true }
                                    disabled={!isSecurityAdminLogin}
                                    onClick={() => this.deleteSchedule()}>Delete
                                </Button>
                            </Col>
                           
                              
                        </Row>
                        <Row form> 
                            <Alert 
                                color="success" 
                                isOpen={showSaveAlert} 
                                style={{ margin: "0px", paddingTop: "8px", height: "40px" }}>
                                {"Data Save Successfuly !!!!"}
                            </Alert>  
                            <Alert 
                                color={alertColor} 
                                toggle={() => this.toggleAlert()}
                                isOpen={showAlert} 
                                style={{ marginBottom: "5px", marginTop: "5px" }}>
                                {alertMessage}
                            </Alert >
                        </Row> 
                    </Form> 

                    {/* <ColoredLine color="grey" />  */}
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '1' })}
                                onClick={() => { this.toggleTab('1'); }}
                            >
                                {"Details"} 
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink 
                                //hidden={(afeHeaderKey < 0 || !hasDCCost)}
                                className={classnames({ active: activeTab === '2' })}
                                onClick={() => { this.toggleTab('2'); }}
                            >
                                {"Trigger"}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink 
                                hidden={(scheduleModel == undefined || scheduleModel == null || scheduleModel.pmScheduleKey <= 0)}
                                className={classnames({ active: activeTab === '3' })}
                                onClick={() => { this.toggleTab('3'); }}
                            >
                                {"Assets"}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink 
                                hidden={(pmScheduleKey == undefined || pmScheduleKey == null || pmScheduleKey < 0 || pmScheduleKey === 0)}
                                className={classnames({ active: activeTab === '4' })}
                                onClick={() => { this.toggleTab('4'); }}
                            >
                                {"Work Orders"}
                            </NavLink>
                        </NavItem>  
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <ScheduledMaintenanceDetails
                                user={user}  
                                pmScheduleKey={scheduleModel.pmScheduleKey}
                                createdBy={scheduleModel.createdBy}
                                createdDate={scheduleModel.createdDate}
                                lastModifiedBy={scheduleModel.lastModifiedBy}
                                lastModifiedDate={scheduleModel.lastModifiedDate}
                                scheduleID={scheduleModel.scheduleID}
                                statusKey={scheduleModel.statusKey}
                                scheduleName={scheduleModel.scheduleName}
                                scheduleDescription={scheduleModel.scheduleDescription}
                                itemTypeKey={scheduleModel.itemTypeKey}
                                maintenanceTypeKey={scheduleModel.maintenanceTypeKey}
                                issueTypeKey={scheduleModel.issueTypeKey}
                                locationKey={scheduleModel.locationKey}
                                facilityKey={scheduleModel.facilityKey}
                                startDate={scheduleModel.startDate}
                                endDate={scheduleModel.endDate}
                                priority={scheduleModel.priority}
                                instructions={scheduleModel.instructions}
                                mainTypeList={mainTypeList}
                                issueTypeList={issueTypeList}
                                basinList={basinList}
                                facilityList={facilityList}
                                priorityList={priorityList}
                                itemTypeList={itemTypeList}
                                statusList={statusList}
                                sendDataToParentFromDetails={this.receivedFromParentFromDetails}
                                validateDataItem={validateDataItem}
                                isSecurityAdminLogin={isSecurityAdminLogin}
                                assetMaintenanceIssueTypeMapping={assetMaintenanceIssueTypeMapping} 
                                > 
                            </ScheduledMaintenanceDetails>
                        </TabPane>
                        <TabPane tabId="2">
                            <ScheduledMaintenanceTrigger 
                                pmScheduleKey={scheduleModel.pmScheduleKey}
                                pmScheduleTriggerKey={scheduleModel.pmScheduleTriggerKey}
                                triggerType={scheduleModel.triggerType}
                                condition1={scheduleModel.condition1}
                                condition2={scheduleModel.condition2}
                                condition3={scheduleModel.condition3}
                                condition4={scheduleModel.condition4}
                                itemTypeKey={scheduleModel.itemTypeKey}
                                locationKey={scheduleModel.locationKey}
                                facilityKey={scheduleModel.facilityKey}
                                statusKey={scheduleModel.statusKey}
                                sendDataToParentFromTrigger={this.receivedFromParentFromTrigger}
                                isSecurityAdminLogin={isSecurityAdminLogin} 
                                >  
                            </ScheduledMaintenanceTrigger> 
                        </TabPane>
                        <TabPane tabId="3"> 
                             <ScheduledMaintenanceAssets
                              recordKey={0}
                              pmScheduleKey={scheduleModel.pmScheduleKey}
                              itemTypeKey={scheduleModel.itemTypeKey}
                              locationKey={scheduleModel.locationKey}
                              facilityKey={scheduleModel.facilityKey}
                              statusKey={scheduleModel.statusKey}
                              sendDataToParentFromScheduledAssets={this.receivedFromScheduledAssets}
                              isSecurityAdminLogin={isSecurityAdminLogin} 
                              >  
                             </ScheduledMaintenanceAssets>
                        </TabPane>
                        <TabPane tabId="4">
                            <div style={{  marginTop: "35px", marginBottom: "5px" }}>
                                <WOHome 
                                    user={this.props.user} 
                                    pmScheduleKey={scheduleModel.pmScheduleKey}>
                                </WOHome>
                            </div> 
                        </TabPane> 
                    </TabContent>
                </div>
            )
        }
            
    }

}

export default ScheduledMaintenanceMetaData
